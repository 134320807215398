import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import "swiper/css";
import "swiper/css/pagination"

// *Import Component
import SimpleCard from '../Cards/SimpleCard';
import CategoryCard from '../Cards/CategoryCard';

function Slider(props) {

  const { card, product, slideView, spaceBetween, speed, delay, pagination, breakpoints } = props;

  SwiperCore.use([Autoplay, Pagination]);

  return (
    <Swiper
      className="mySwiper"
      loop={true}
      slidesPerView={slideView}
      breakpoints={breakpoints}
      spaceBetween={spaceBetween}
      speed={speed}
      pagination={pagination === true ? { "dynamicBullets": true, "clickable": true, } : false}
      autoplay={{
        "delay": delay,
        "disableOnInteraction": false,
      }}
    >
      {product.map((item, index) => {

        return (
          <SwiperSlide key={index}>
            {card === 'simpleCard' ? <SimpleCard product={item} /> : ''}
            {/* {card === 'horizontalCard' ? <HorizontalCard product={item} /> : ''} */}
            {card === 'categoryCard' ? <CategoryCard category={item} /> : ''}
          </SwiperSlide>
        )

      })}
    </Swiper>
  );
}

export default Slider; 