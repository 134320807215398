import { React, useState, useEffect, Fragment } from 'react';
import { Box, Container, Divider, Grid, Toolbar, Typography, Button, TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';
import VendorSideNav from './../../../components/SideNav/VendorSideNav';
import StoreCard from './../../../components/Cards/StoreCard';
import { ProductNotFound } from './../../../components/NotFound/NotFound';
import { Service } from './../../../config/service';
import colors from '../../../style/colors';
import { SuccessToaster } from 'app/components/UI/Toaster';
import Swal from 'sweetalert2';
import { Loading } from 'app/components/UI/Loader';
import { useForm } from 'react-hook-form';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

function DeactivateProducts() {
  const { register, handleSubmit } = useForm();
  const { pathname } = useLocation;
  const token = localStorage.getItem("vendorToken")
  const [productList, setProductList] = useState([]);
  let [MainLoading, setMainLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const accountId = localStorage.getItem('vendorId')

  // Product Activate Api Call
  const activateProduct = async (item) => {

    try {
      let obj = {
        BusinessTypeID: 1,
        ItemID: item
      }
      const { Status, ResponseCode } = await Service.activateProduct(obj, token)
      if (Status === true && ResponseCode === 200) {
        SuccessToaster('Product Activate Successfully!')
      }

    } catch (error) {
      console.log("🚀 ~ file: DeactivateProducts.js:36 ~ activateProduct ~ error", error)
    }

  };

  // Handle Deactivate Product

  const handleActivateProduct = (item) => {

    activateProduct(item)
    Swal.fire(
      'Activated!',
      'Your file has been activate.',
      'success'
    )
    getDeactivateProduct()
  };

  // *Get products Api
  const getDeactivateProduct = async () => {
    try {
      const pageNumber = 1

      const { Data, Status, ResponseCode } = await Service.getDeactivateProduct(accountId, pageNumber)

      if (Status === true && ResponseCode === 200) {
        setProductList(Data[0]?.ItemArray)
      }
      else if (Status === false && ResponseCode === 404) {
        setProductList([])
      }
    } catch (error) {
      console.log('file: Products.js => line 23 => getProduct => error', error)
    }
    finally {
      setMainLoading(false);
    }
  };

  const searchProduct = async (data) => {
    try {
      const { Data } = await Service.getVendorProduct(accountId, 1, data.search);
      if (Data) {
        setProductList(Data[0]?.ItemArray);
      }
      else {
        setProductList([]);
      }
    } catch (error) {
      console.log('file: DeactivateProducts.js:86 => searchProduct => error', error);
    }
  }

  useEffect(() => {
    getDeactivateProduct()
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container sx={{ my: 3 }}>
      <Grid container columnSpacing={4}>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "100%", height: 40, display: { xs: "block", md: "none" } }}
        >
          <Toolbar />
        </Box>
        <Grid item xs={12} md={3}>
          <VendorSideNav />
        </Grid>
        <Grid item xs={12} md={9}>
          {/* <Typography variant={'h4'} sx={{ my: 2, fontWeight: "bold", color: colors.primary }}>Deactivated Products</Typography> */}
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography
              variant={"h4"}
              sx={{ my: 2, fontWeight: "bold", color: colors.primary }}
            >
              Deactivated Products
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit(searchProduct)}
              sx={{
                display: "flex",
                alignItems: "center",
                width: "300px",
                "& .MuiFormControl-root": { width: "100%" },
              }}
            >
              <TextField
                placeholder="Search Product"
                id="input-with-sx"
                variant="standard"
                name="search"

                {...register('search')}
              />
              <Button
                type="submit"
                variant="text"
                sx={{ "& .MuiInput-root": { p: "0" } }}
              >
                <SearchOutlinedIcon sx={{ color: "red" }} />
              </Button>
            </Box>
          </Box>


          <Divider sx={{ mb: 2.5, mt: 1 }} />
          {MainLoading ? (
            <Grid md="9">
              <Loading sx={{ width: "100%", textAlign: 'center' }} />
            </Grid>
          ) : (
            <Fragment>
              {productList.length ? (
                <Box sx={{ my: 2 }}>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    {productList.map((item, index) => (
                      <Grid key={index} item md={3}>
                        <StoreCard path={pathname} product={item} getProduct={getDeactivateProduct} />
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Button
                            onClick={() => handleActivateProduct(item.ItemID)}
                            variant="contained"
                            sx={{
                              width: "100%",
                              textTransform: 'capitalize',
                              bgcolor: colors.secondary,
                              '&:hover': {
                                bgcolor: "#c4364c",
                              },
                            }}
                          >
                            Activate Product
                          </Button>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <ProductNotFound error="No Product Found" />
              )}
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default DeactivateProducts