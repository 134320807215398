import React, { useState } from 'react';
import { Tab, Box, Dialog, DialogContent } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import Images from 'assets/Images';

// *Import Components
import VendorLogin from 'app/views/Vendor/Session/Login/Login';
import VendorRegister from 'app/views/Vendor/Session/Register/Register';

function BasicModal(props) {

  const { show, onClose } = props
  const [value, setValue] = useState("login");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: {
            xs: '100%',
            md: '40%'
          }, height: "auto", borderRadius: "10px"
        }
      }}
      maxWidth="md"
      open={show}
      onClose={onClose}
    >
      <DialogContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ textAlign: "center" }}>
              <img src={Images.logo} alt="Partz Buddy" />
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Login" value="login" />
                <Tab label="Register" value="register" />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0, py: 2 }} value="login">
              <VendorLogin onChange={() => setValue("register")} onClose={onClose} />
            </TabPanel>
            <TabPanel sx={{ p: 0, py: 2 }} value="register">
              <VendorRegister onChange={() => setValue("login")} onClose={onClose} />
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default BasicModal
