import Axios from "../../axios";
import { errorHandler } from "./errorHandler";

export const Apis = {
  // *Auth
  login: "token",
  register: "api/Account/Register",

  // Forget Password Apis to reset and forget password -----------------------------------------------------------------**********************************************>

  forgetPassword: "api/Account/ForgotPassword",

  // Reset Password Api

  resetPassword: "api/Account/ResetPassword",



  searchProducts: "api/Products/FilterWiseProducts",

  banner: "api/Custom/GetBanners",
  topCategory: "api/Products/GetTopCategory",
  category: "api/Products/GetCategory",
  categoryFilter: "api/Products/GetCategoryList",

  vendorList: "api/Custom/VendorLocationWithDetails",
  // venderfilter:"https://api/Custom/VendorListWithDetails?VendorName=&BusinessTypeID=1&limit=10&page=1"
  vendorfilterdata: "api/Custom/VendorListWithDetails",

  feature: "api/Products/GetFeaturedProducts",
  newArrival: "api/Products/TopProducts",
  bestSeller: "api/Products/GetTopSoldItems",
  product: "api/Products/GetProducts",
  productByProductId: "api/Products/GetProductsProductIDIDWise",
  getBrands: "api/Products/GetBrands",
  getManufacturers: "api/Products/GetManufacturers",
  getVehicles: "api/Products/GetModels",
  getProfileDetail: "api/Orders/GetCustomerDetailsAccountIDWise",
  updateProfile: "api/Custom/SaveUserDetailsWithAddresses",
  changePassword: "api/Account/ChangePassword",
  getCustomerOrder: "api/Orders/GetOrdersCustomerWise",
  getVendorOrder: "api/Orders/GetOrdersVendorWise",
  addRemoveFavouriteItem: "api/Products/AddRemoveFavouriteItem",
  getCustomerFavourite: "api/Products/GetFavouriteItems",
  getVendorProduct: "api/Products/GetProductsVendorWise",
  getDeactivateProduct: "api/Products/GetOutofStockProductsVendorWise",
  sendOtp: "https://sms.mangotech-api.com/send",
  placeOrder: "api/Orders/PunchOrder",
  getReports: "api/Reports/GetFilterWiseReports",
  getSubCategory: "api/Products/GetSubCategoryHeadCatIDWise",
  addProductImage: "api/Custom/ImageUpload",
  addProduct: "api/Products/AddProduct",
  deleteProduct: "/api/Products/DeleteProduct",
  //* Out of stock
  outOfStockProduct: "api/Products/MoveInProductToOutOfStock",
  inStockProduct: "/api/Products/MoveOutProductFromOutOfStock",
  // *
  deactivateProduct: "api/Products/MoveProductOutOfStock",
  activateProduct: "api/Products/MoveProductInStock",
  editProduct: "/api/Products/EditProduct",
  getVendor: "/api/Custom/VendorDetails",
  addProductBySalePerson: "/api/Products/AddProductBySalesPerson",
  getProductsFilterByPage: "/api/Products/GetProductsByFilter",
  getProductsCategoryIDWise: "/api/Products/GetProductsCategoryIDWise",
  // Delete order from vendor Panel

  deleteOrderByIDVendorPanel: "/api/Orders/DeleteOrder",
  orderStatus: "/api/Orders/OrderStatusesForVendor",
  changeOrderStatus: "/api/Orders/ChangeOrderStatus",
  addRemoveFavourites: 'api/Products/AddRemoveFavouriteItem',




  // AutoPartz
  // updateProfileAutoParts: '/api/Custom/SaveUserDetailsWithAddresses',
  // getProducts: 'api/Products/GetProducts',
  // getTopProducts: 'api/Products/TopProducts',
  // getSoldItem: 'api/Products/GetTopSoldItems',
  // getFilteredProducts: 'api/Products/FilterWiseProducts',
  // getBrandsAP: 'api/Products/GetBrands',
  // getTopCategory: 'api/Products/GetTopCategory',
  // getManufacturer: 'api/Products/GetManufacturers',
  // getCategory: 'api/Products/GetCategory',
  // getProductwithID: 'api/Products/GetProductsCategoryIDWise',
  // getVendorProducts: 'api/Products/GetProductsVendorWise',
  // getBanners: 'api/Custom/GetBanners',
  // verifyUser: 'api/Custom/CustomUserVerification',
  // getOtp: '/api/Custom/GetOTPCode',
  // getVendorLocations: 'api/Custom/VendorLocationWithDetails',
  // getAllCountries: 'https://restcountries.com/v3.1/alpha?codes=ae,om,pk',
  // getFavourite: 'api/Products/FilterWiseFavProducts',
  // showPopupNotification: 'api/Notifications/GetNotificationUserWise',
  // changePassword: 'api/Custom/ChangeUserLoginPassowrd',
  // userExist: 'api/Custom/UserExistInBusiness',
  // getFavouriteID: 'api/Products/GetFavouriteItems',
};

export const headers = {
  "content-type": "application/json",
};

export const get = async (endPoint, token) => {
  try {
    const result = await Axios.get(endPoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result;
  } catch (e) {
    throw errorHandler(e);
  }
};

export const post = async (endPoint, data, token) => {
  try {
    const result = await Axios.post(endPoint, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result;
  } catch (e) {
    throw errorHandler(e);
  }
};

export const put = async (endPoint, data, token) => {
  try {
    const result = await Axios.put(endPoint, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result;
  } catch (e) {
    throw errorHandler(e);
  }
};
