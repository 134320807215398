import React from 'react'
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material'

import colors from 'app/style/colors'

function DashboardDetailCard({ name, icon, path }) {
  return (
    <Link to={path} style={{ textDecoration: "none", color: colors.primary }}>
      <Box sx={{
        bgcolor: colors.white,
        borderRadius: "10px",
        p: 2,
        boxShadow: 3,
        cursor: "pointer",
        '&:hover': {
          bgcolor: colors.secondary,
          color: colors.white,
          '.MuiSvgIcon-root': {
            color: colors.white
          }
        }
      }}>
        <Typography variant='h5'  >{name}</Typography>
        <Box sx={{ textAlign: "right" }}>
          {icon}
        </Box>
      </Box>
    </Link>
  )
}

export default DashboardDetailCard