import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { AiFillStar, AiOutlineHeart, AiOutlineShoppingCart, AiOutlineStar } from 'react-icons/ai';
import { MdOutlineShoppingCart, MdOutlineRemoveShoppingCart } from 'react-icons/md';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import colors from 'app/style/colors';
import { RemoveSpace } from 'app/utils';
import { updateCartData } from 'app/redux/Actions/ActionCreator';
import { SuccessToaster } from '../UI/Toaster';
import Image from 'assets/Images'

//*For Lazy Image Loading


const mapStateToProps = (state) => {

  return {
    items: state.items
  }
}

function SimpleCard(props) {

  const { product } = props;

  const navigate = useNavigate()

  const dispatch = useDispatch();
  const addToCart = (_data) => dispatch(updateCartData(_data));

  const addToCartHandler = (item) => {
    SuccessToaster('Product Added Successfully!')

    let obj = {
      type: 'add',
      id: item.ItemID,
      name: item.ItemName,
      image: item.File,
      qty: item.Qty,
      discount: item.DiscountAmount,
      // discount: (item.SalesRate - item.DiscountAmount), Changes After 27 Feb
      delivery: item.DeliveryCharges,
      price: item.SalesRate,
    };
    addToCart(obj);
  };

  return (
    <Box
      // component={Link}
      // to={`/product/${RemoveSpace(product.CategoryName)}/${RemoveSpace(product.ItemName)}/${product.ItemID}`}
      sx={{ textDecoration: 'none' }}
    >
      <Card elevation={1}
        sx={{
          '&:hover': {
            boxShadow: '0 0 6px 0 rgb(1 1 1 / 30%)',
            transition: '0.3s ease-in-out',
          },
        }}
      >
        <CardContent sx={{ pb: '20px !important', position: "relative" }}>
          <Typography color={colors.textPrimary} sx={{ fontSize: 12, mb: 1, }}>
            {product.CategoryName}
          </Typography>
          <Typography variant="subtitle1" className="text-truncate"
            component={Link}
            to={`/product/${RemoveSpace(product.CategoryName)}/${RemoveSpace(product.ItemName)}/${product.ItemID}`}
            sx={{
              fontSize: 15,
              fontWeight: 500,
              height: 35,
              lineHeight: 1.2,
              color: colors.black,
              textDecoration: 'none'
            }}
          >
            {product.ItemName}
          </Typography>
          <CardMedia image={product.File ? product.File : Image.imagePlaceholder} sx={{ height: '200px', my: 2, cursor: 'pointer' }}
            onClick={() => navigate(`/product/${RemoveSpace(product.CategoryName)}/${RemoveSpace(product.ItemName)}/${product.ItemID}`)}
          />
          <Typography variant="body2" sx={{ fontSize: 12, textDecoration: 'line-through', height: 18 }} >
            {/* {product.DiscountAmount > 0 && `Rs ${product.SalesRate}`} after 27 feb */}
            {product.DiscountAmount > 0 ? `Rs ${product.SalesRate}` : ''}
          </Typography>
          <Grid container spacing={1} justifyContent="center" alignItems="center" >
            <Grid item flex={1}>
              <Typography variant="body1" color="secondary" sx={{ fontSize: 16 }} >
                {/* Rs {product.DiscountAmount > 0 ? ` ${product.SalesRate - product.DiscountAmount}` : ` ${product.SalesRate}`} After 27 Feb */}
                Rs {product.DiscountAmount > 0 ? ` ${product.DiscountAmount}` : ` ${product.SalesRate}`}
              </Typography>
              <Box>
                <AiFillStar fontSize="14px" color={colors.ratingYellow} />
                <AiFillStar fontSize="14px" color={colors.ratingYellow} />
                <AiFillStar fontSize="14px" color={colors.ratingYellow} />
                <AiFillStar fontSize="14px" color={colors.ratingYellow} />
                <AiOutlineStar fontSize="14px" color="#d8d8d8" />
                <Typography variant="caption" component="span" sx={{ verticalAlign: 'top', fontSize: '12px' }}>
                  &nbsp;(15)
                </Typography>
              </Box>
            </Grid>
            <Grid item >
              {/* <WishButton /> */}
              {/* <IconButton
                sx={{ mr: 0.5, p: 1, bgcolor: colors.gray, '&:hover': { bgcolor: colors.secondary, transition: '0.3s ease-in-out' }, }}
              >
                <AiOutlineHeart style={{ color: colors.white, fontSize: 18, }} />
              </IconButton> */}
              {!product.OutOfStock ? (
                <IconButton
                  className="cart-btn"
                  onClick={() => addToCartHandler(product)}
                  sx={{ p: 1, bgcolor: colors.primary, '&:hover': { bgcolor: colors.primaryLight } }}
                >
                  <MdOutlineShoppingCart style={{ color: colors.white, fontSize: 18, }} />
                </IconButton>
              )
                : (
                  <Box sx={{ border: "1px solid white", backgroundColor: colors.secondaryDark, borderRadius: "4px", px: 0.2, py: 0.2 }}>
                    <Typography color={colors.white} sx={{ fontWeight: "bold", fontSize: "10px", mx: 0.2 }}>
                      Out Of Stock
                    </Typography>
                  </Box>
                )}

              {/* <CartButton addToCart={addToCartHandler(product)} /> */}
            </Grid>
          </Grid>

          {/* {product.OutOfStock &&
            <Box
              onClick={() => navigate(`/product/${RemoveSpace(product.CategoryName)}/${RemoveSpace(product.ItemName)}/${product.ItemID}`)}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // backgroundColor: "rgba(0,0,0,0.1)",
                // backdropFilter: "saturate(150%) blur(1px)",
                position: "absolute",
                width: "100%",
                top: 0,
                left: 0,
                height: "100%",
              }}>
              <Box sx={{ border: "1px solid white", backgroundColor: colors.secondaryDark, borderRadius: "4px", p: 1, w: 1 }}>
                <Typography color={colors.white} sx={{ fontWeight: "bold", fontSize: "16px", mx: 0.2 }}>
                  Out Of Stock
                </Typography>
              </Box>

            </Box>
          } */}

        </CardContent>
      </Card>
    </Box>
  );
}

export default connect(mapStateToProps)(SimpleCard); 