import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { List, Divider, ListItemIcon, ListItemText, ListItemButton, Box, Avatar } from '@mui/material';
import { PersonOutlineOutlined, DashboardCustomizeOutlined, ExitToAppOutlined } from '@mui/icons-material';
import { BiBookmark } from 'react-icons/bi'

import useAuth from 'app/hooks/useAuth';
import colors from 'app/style/colors';
import Images from 'assets/Images';

function CustomerSideNav() {

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { customerLogout } = useAuth();

  const list = [
    { label: 'Profile', path: '/profile', icon: <PersonOutlineOutlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} /> },
    { label: 'Order', path: '/order', icon: <DashboardCustomizeOutlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} /> },
    { label: 'Favourites', path: '/favourites', icon: <BiBookmark size={22} style={{ color: pathname === '/favourites' ? colors.secondary : colors.primary, verticalAlign: 'bottom', mr: 1 }} /> },
  ];

  return (
    <Box
      variant="permanent"
      sx={{
        bgcolor: colors.white,
        position: "relative",
        justifyContent: "space-around",
        width: "100%",
        borderRadius: "10px",
        boxShadow: 2
      }}
    >

      <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
        <Avatar alt="" src={Images.person} sx={{ width: 80, height: 80 }} />
      </Box>
      <List sx={{ pl: "8px", mr: "8px" }}>
        {list.map((item, index) => (
          <ListItemButton
            key={index}
            onClick={() => { navigate(item.path) }}
            selected={pathname === item.path ? true : false}
            sx={{
              display: "flex",
              alignItems: "center",
              my: 0.4,
              borderRadius: "10px",
              color: colors.primary,
              '.MuiSvgIcon-root': {
                color: colors.primary,
              },
              '&:hover': {
                '&>.MuiTypography-root': {
                  color: colors.white,
                },
                '.MuiSvgIcon-root': {
                  color: colors.primary,
                },
                cursor: 'pointer',
              },
              '&.Mui-selected': {
                bgcolor: "white !important",
                color: colors.secondary,
                '.MuiSvgIcon-root': {
                  color: colors.secondary,
                },
              },
            }}
          >
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        ))}
      </List>

      <Divider />

      <List sx={{ pl: "8px", mr: "8px" }}>
        <ListItemButton onClick={() => { customerLogout() }}
          sx={{
            py: 1.5,
            color: colors.primary,
            borderRadius: "10px",
            '.MuiSvgIcon-root': {
              color: colors.primary,
            },
            '&:hover': {
              cursor: 'pointer',
              bgcolor: "white !important",
              color: colors.secondary,
              '.MuiSvgIcon-root': {
                color: colors.secondary,
              },
            },
          }}>
          <ListItemIcon>
            <ExitToAppOutlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} />
          </ListItemIcon>
          <ListItemText primary='Log Out' />
        </ListItemButton>
      </List>

    </Box>
  );
}

export default CustomerSideNav
