const Images = {

  // *Logo
  logo: require('./images/PartzBuddy.png'),
  logo2: require('./images/PartzBuddy2.png'),

  // *Banner
  banner1: require('./images/banner1.jpg'),
  shopBanner: require('./images/shopBanner.jpg'),
  person: require('./images/boy.png'),

  return: require('./images/return.png'),
  genuine: require('./images/genuine.png'),
  product: require('./images/product.jpg'),
  notFound: require('./images/notFound.png'),
  imagePlaceholder: require('./images/placeholderImage.png'),
  vendorBg: require('./images/vendorBg.jpg'),

  // *Loading Animation
  loader: require('./Animations/loading.gif'),

  // *Success
  success: require('./images/success.png'),

}

export default Images;