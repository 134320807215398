import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Grid, CardMedia, Box, Typography, OutlinedInput, Button, Divider } from '@mui/material';
import { AiOutlineHeart, AiFillHeart, AiOutlineShoppingCart } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import colors from 'app/style/colors';
import { Service } from 'app/config/service';
import { updateCartData } from 'app/redux/Actions/ActionCreator';
import { RemoveSpace } from 'app/utils';

// *Import Components
import Breadcrumb from 'app/components/Breadcrumb/Breadcrumb';
import Slider from 'app/components/Sliders/Slider';
import { Loading } from 'app/components/UI/Loader';
import { ErrorToaster, SuccessToaster } from 'app/components/UI/Toaster';
import Images from "assets/Images"

function ProductDetail() {

  const { id } = useParams();
  const [loader, setLoader] = useState(false)


  const customerToken = localStorage.getItem("customerToken")
  const accountID = localStorage.getItem('customerId')

  const [newArrival, setNewArrival] = useState([]);
  const [product, setProduct] = useState([]);

  // *For Product Quantity
  const [quantity, setQuantity] = useState('1');

  const dispatch = useDispatch();
  const addToCart = (_data) => dispatch(updateCartData(_data));

  // *For Add To Cart 
  const addToCartHandler = (item, qty) => {
    SuccessToaster('Product Add Successfully!')
    let obj = {
      type: 'add',
      id: item.ItemID,
      name: item.ItemName,
      image: item.File,
      qty: qty,
      discount: item.DiscountAmount,
      // discount: (item.SalesRate - item.DiscountAmount),  Changes after 27 Feb
      delivery: item.DeliveryCharges,
      price: item.SalesRate,
    };
    addToCart(obj);
  };

  // *Get Product
  const getProduct = async () => {
    setLoader(true)
    if (customerToken) {
      try {
        const { Data } = await Service.getProductByProductId(id, accountID);
        setProduct(Data)
        setLoader(false)
      } catch (error) {
        console.log('file: ProductDetail.js => line 65 => getProduct => error', error)
        setLoader(false)
      }
    }
    else {
      try {
        const { Data } = await Service.getProductByProductId(id);
        setProduct(Data)
        setLoader(false)
      } catch (error) {
        setLoader(false)
        console.log('file: ProductDetail.js => line 65 => getProduct => error', error)
      }
    }
  };

  // *Get New Arrival
  const getNewArrival = async () => {
    try {
      const { Data } = await Service.getNewArrival();
      setNewArrival(Data)
    } catch (error) {
      console.log('file: ProductDetail.js => line 23 => getNewArrival => error', error)
    }
  };

  // *For Get Product Quantity
  const getQuantity = (event) => {
    setQuantity(event.target.value === '' ? '' : Number(event.target.value));
  };

  // *For Input Blur
  const handleBlur = () => {
    if (quantity <= 0) {
      setQuantity(1);
    } else if (quantity > 50) {
      setQuantity(50);
    }
  };


  const saveToFavourites = async () => {
    if (customerToken) {
      try {
        let obj = {
          BusinessTypeID: 1,
          AccountID: accountID,
          ItemID: product[0].ItemID,
          IsDelete: false
        }
        const { Data, Status } = await Service.addRemoveFavouriteItem(obj, customerToken)
        if (Status) {
          SuccessToaster(Data)
          getProduct()
        }
        else {
          ErrorToaster(Data)
        }
      } catch (error) {
        console.log('file: ProductDetail.js:91 => saveToFavourites => error', error);
      }
    }
    else {
      ErrorToaster("Authorization Required. Login as a Buyer ")
    }
  }

  const removeFavourites = async () => {
    if (customerToken) {
      try {
        let obj = {
          BusinessTypeID: 1,
          AccountID: accountID,
          ItemID: product[0].ItemID,
          IsDelete: true
        }
        const { Data, Status } = await Service.addRemoveFavouriteItem(obj, customerToken)
        if (Status) {
          SuccessToaster(Data)
          getProduct()
        }
        else {
          ErrorToaster(Data)
        }
      } catch (error) {
        console.log('file: ProductDetail.js:91 => saveToFavourites => error', error);
      }
    }
    else {
      ErrorToaster("Authorization Required. Login as a Buyer ")
    }
  }

  useEffect(() => {
    getNewArrival()
  }, []);

  useEffect(() => {
    getProduct()
    window.scrollTo({ top: 0 })
  }, [id]);

  return (
    <Container>

      {/* ========== Breadcrumb ========== */}
      <Breadcrumb />

      {/* ========== Product ========== */}
      {product?.length ? (
        <Fragment>
          {product.map((item, index) => (
            <Box key={index} sx={{ pt: 2, pb: 5 }}>
              <Grid container spacing={3}>
                <Grid item md={5}>
                  <CardMedia component="img" image={item.File ? item.File : Images.imagePlaceholder} />
                </Grid>
                <Grid item md={5}>
                  <Typography component={Link} to="/" variant="body2" color={colors.textPrimary} sx={{ textDecoration: 'none' }}>
                    {item.CategoryName}
                  </Typography>
                  <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                    {item.ItemName}
                  </Typography>
                  <Divider />
                  {/* <Box>
                      <AiFillStar fontSize="20px" color={colors.ratingYellow} />
                      <AiFillStar fontSize="20px" color={colors.ratingYellow} />
                      <AiFillStar fontSize="20px" color={colors.ratingYellow} />
                      <AiFillStar fontSize="20px" color={colors.ratingYellow} />
                      <AiOutlineStar fontSize="20px" color="#d8d8d8" />
                      <Typography variant="body1" component="span" sx={{ verticalAlign: 'super' }}>
                        &nbsp;(15 reviews)
                      </Typography>
                    </Box> */}
                  <Box sx={{ display: 'flex', my: 1.5 }}>
                    <Typography variant="body1" color={colors.textPrimary}>Brand : </Typography>
                    <Typography component={Link} to="/" variant="body1" color={colors.textPrimary} sx={{ textDecoration: 'none', mx: 1, }}>
                      {item.QADetails.map((Qitem) => {
                        if (Qitem.QID == 12) {
                          return Qitem.SelectionData[0].Value
                        }
                      })}
                    </Typography>
                  </Box>
                  {item.VendorDetails.map((vendor, i) => (
                    <Box key={i} sx={{ display: 'flex', my: 1.5 }} >
                      <Typography variant="body1" color={colors.textPrimary}>Provided by : </Typography>
                      <Typography component={Link} to={`/shop/${RemoveSpace(vendor.Name)}/${vendor.AccountID}`} variant="body1" color={colors.secondary} sx={{ textDecoration: 'none', mx: 1 }}>
                        {vendor.Name}
                      </Typography>
                    </Box>
                  ))}
                  <Box sx={{ my: 1.5 }}>
                    {/* DiscountAmount edit here */}
                    <Typography variant="h5" component="span" color="secondary" sx={{ my: 1.5 }}>
                      {/* Rs {item.DiscountAmount > 0 ? ` ${item.DiscountAmount}` : ` ${item.SalesRate}`} */}
                      Rs {item.DiscountAmount > 0 ? ` ${item.DiscountAmount}` : ` ${item.SalesRate}`}
                      {/* Rs {item.DiscountAmount > 0 ? `${item.SalesRate - item.DiscountAmount}` : ` ${item.SalesRate}`} After 27 Feb */}
                    </Typography>
                    <Typography variant="body1" component="span" color="primary" sx={{ ml: 1.5, my: 1.5, textDecoration: 'line-through' }}>
                      {/* {item.SalesRate > item.DiscountAmount && `Rs ${item.SalesRate}`} */}
                      {item.DiscountAmount > 0 ? `Rs ${item.SalesRate}` : ''}
                    </Typography>

                  </Box>
                  {!item.OutOfStock ?
                    <Box sx={{ display: 'flex' }}>
                      <Typography variant="body1" color={colors.textPrimary} sx={{ my: 1 }}>Quantity :</Typography>
                      <OutlinedInput type="number" size="small"
                        value={quantity}
                        onChange={getQuantity}
                        onBlur={handleBlur}
                        sx={{ borderRadius: '40px', ml: 1, width: 80 }}
                      />
                    </Box> :
                    <Box sx={{ display: 'flex', my: 1.5 }} >
                      <Typography color={colors.textPrimary} sx={{ my: 1 }}>Quantity :</Typography>

                      <Typography color={colors.secondary} variant="body2" sx={{ fontWeight: 'bold', m: 1.2 }}>
                        Out Of Stock
                      </Typography>
                      {/* <Box sx={{ backgroundColor: colors.ratingYellow, borderRadius: "4px" }}>
                        <Typography color={colors.secondary} sx={{ fontWeight: 'bold', mx: 1 }}>
                          Out Of Stock
                        </Typography>
                      </Box> */}
                    </Box>
                  }

                  <Box sx={{ display: 'flex' }}>
                    <Box>
                      {item.IsFavorite ? (
                        <Button onClick={removeFavourites} variant="outlined" size="large" color="secondary" sx={{ my: 2, mr: 1, borderRadius: '20px', textTransform: 'inherit' }} >
                          <AiFillHeart style={{ fontSize: 20, marginRight: '5px' }} />
                          Remove Favourite
                        </Button>
                      ) : (
                        <Button onClick={saveToFavourites} variant="contained" size="large" color="secondary" sx={{ my: 2, mr: 1, borderRadius: '20px', textTransform: 'inherit' }} >
                          <AiOutlineHeart style={{ fontSize: 20, marginRight: '5px' }} />
                          Add to Favourite
                        </Button>
                      )}
                    </Box>
                    {!item.OutOfStock ?
                      <Button type="submit" variant="contained" size="large"
                        sx={{ my: 2, mr: 1, borderRadius: '20px', textTransform: 'inherit' }}
                        onClick={() => addToCartHandler(item, quantity)}
                      >
                        <AiOutlineShoppingCart style={{ fontSize: 20, marginRight: '5px' }} />
                        Add to Cart
                      </Button> :
                      <Button disabled variant="contained" size="large"
                        sx={{ my: 2, mr: 1, borderRadius: '20px', textTransform: 'inherit' }}
                        onClick={() => addToCartHandler(item, quantity)}
                      >
                        <AiOutlineShoppingCart style={{ fontSize: 20, marginRight: '5px' }} />
                        Add to Cart
                      </Button>
                    }
                  </Box>
                </Grid>
                {/* <Grid item md={2} sx={{ bgcolor: '#FAFAFA' }}>
                    <Typography variant="h6" sx={{ my: 1 }}>Services</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', my: 2, }}>
                      <Typography component="img" src={Images.return} sx={{ height: 26, mr: 1 }} />
                      <Typography variant="body1" color={colors.textPrimary}>7 Days Return</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', my: 2, }}>
                      <Typography component="img" src={Images.genuine} sx={{ height: 26, mr: 1 }} />
                      <Typography variant="body1" color={colors.textPrimary}>100% Genuine Product</Typography>
                    </Box>
                  </Grid> */}
              </Grid>
              {/* Product Details */}
              <Typography variant="h4" sx={{ my: 2 }}>Product Detail</Typography>
              <Divider sx={{ width: '25%', height: '2px', bgcolor: colors.primary, my: 1.5 }} />
              <Grid container>

                {item.QADetails.map((Qitem) => {
                  if (Qitem.QName != "File_Image" && Qitem.QName != "Description") {
                    return (
                      <Grid item xs={12} md={6}>
                        <Box sx={{ display: "flex", borderBottom: `1px solid ${colors.gray}`, my: 0.5, p: 1 }}>
                          <Typography variant='body1' color="primary" fontWeight="bold">
                            {Qitem.QName} : &nbsp;
                          </Typography>
                          <Typography variant='body1'>
                            {Qitem.SelectionData[0].Value}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                  }

                })}
              </Grid>
              {/* for description */}
              {item.QADetails.map((Qitem) => {
                if (Qitem.QName == "Description") {
                  return (
                    <Box sx={{ display: "flex", borderBottom: `1px solid ${colors.gray}`, my: 0.5, p: 1 }}>
                      <Typography variant='body1' color="primary" fontWeight="bold">
                        {Qitem.QName} : &nbsp;
                      </Typography>
                      <Typography variant='body1'>
                        {Qitem.SelectionData[0].Value}
                      </Typography>
                    </Box>
                  )
                }

              })}

            </Box>
          ))}

        </Fragment>
      ) : loader ? (
        <Loading />
      ) : <Box display="flex" justifyContent="center">
        <Typography variant='h5'> No Product Found </Typography></Box>}




      {/* ========== Product Reviews ========== */}
      {/* <Box sx={{ pt: 2, pb: 5 }}>
        <Typography variant="h5" sx={{ my: 2 }}>Ratings &amp; Reviews</Typography>
        <Divider sx={{ width: '25%', height: '2px', bgcolor: colors.primary, my: 1.5 }} />
        <Grid container spacing={1} justifyContent="center" sx={{ mt: 4, mb: 1 }} >
          <Grid item md={4}>
            <Typography variant="h4" component="span" sx={{ fontSize: 60 }}>4</Typography>
            <Typography variant="body1" component="span" sx={{ fontSize: 32 }}>/5</Typography>
            <Box>
              <AiFillStar fontSize="26px" color={colors.ratingYellow} />
              <AiFillStar fontSize="26px" color={colors.ratingYellow} />
              <AiFillStar fontSize="26px" color={colors.ratingYellow} />
              <AiFillStar fontSize="26px" color={colors.ratingYellow} />
              <AiOutlineStar fontSize="26px" color="#d8d8d8" />
              <Typography variant="body1" sx={{ verticalAlign: 'super' }}>
                &nbsp;(15 reviews)
              </Typography>
            </Box>
          </Grid>
          <Grid item md={8}>
            <Box sx={{ display: 'flex', my: 1 }}>
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <LinearProgress variant="determinate" color="rating" value={50} sx={{ width: 180, height: 15, alignSelf: 'center', mx: 1 }} />
              <Typography variant="body1" sx={{ verticalAlign: 'super' }}>
                &nbsp;&nbsp;(5)
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', my: 1 }}>
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiOutlineStar fontSize="22px" color="#d8d8d8" />
              <LinearProgress variant="determinate" color="rating" value={50} sx={{ width: 180, height: 15, alignSelf: 'center', mx: 1 }} />
              <Typography variant="body1" sx={{ verticalAlign: 'super' }}>
                &nbsp;&nbsp;(4)
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', my: 1 }}>
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiOutlineStar fontSize="22px" color="#d8d8d8" />
              <AiOutlineStar fontSize="22px" color="#d8d8d8" />
              <LinearProgress variant="determinate" color="rating" value={50} sx={{ width: 180, height: 15, alignSelf: 'center', mx: 1 }} />
              <Typography variant="body1" sx={{ verticalAlign: 'super' }}>
                &nbsp;&nbsp;(3)
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', my: 1 }}>
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiOutlineStar fontSize="22px" color="#d8d8d8" />
              <AiOutlineStar fontSize="22px" color="#d8d8d8" />
              <AiOutlineStar fontSize="22px" color="#d8d8d8" />
              <LinearProgress variant="determinate" color="rating" value={50} sx={{ width: 180, height: 15, alignSelf: 'center', mx: 1 }} />
              <Typography variant="body1" sx={{ verticalAlign: 'super' }}>
                &nbsp;&nbsp;(2)
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', my: 1 }}>
              <AiFillStar fontSize="22px" color={colors.ratingYellow} />
              <AiOutlineStar fontSize="22px" color="#d8d8d8" />
              <AiOutlineStar fontSize="22px" color="#d8d8d8" />
              <AiOutlineStar fontSize="22px" color="#d8d8d8" />
              <AiOutlineStar fontSize="22px" color="#d8d8d8" />
              <LinearProgress variant="determinate" color="rating" value={50} sx={{ width: 180, height: 15, alignSelf: 'center', mx: 1 }} />
              <Typography variant="body1" sx={{ verticalAlign: 'super' }}>
                &nbsp;&nbsp;(1)
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="h6" sx={{ py: 1.5, my: 2, borderTop: `1px solid ${colors.gray}`, borderBottom: `1px solid ${colors.gray}` }}>
          Product Reviews
        </Typography>
        <Box sx={{ my: 3 }}>
          <Box>
            <AiFillStar fontSize="22px" color={colors.ratingYellow} />
            <AiFillStar fontSize="22px" color={colors.ratingYellow} />
            <AiFillStar fontSize="22px" color={colors.ratingYellow} />
            <AiFillStar fontSize="22px" color={colors.ratingYellow} />
            <AiOutlineStar fontSize="22px" color="#d8d8d8" />
            <Typography variant="caption" component="p" sx={{ fontWeight: 700, my: 1 }}>
              Shairyar Lakhany - 25-Jan-2022
            </Typography>
          </Box>
          <Typography variant="subtitle2" color="initial">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. At illo vero accusamus molestiae. Tenetur, ducimus?
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Box>
            <AiFillStar fontSize="22px" color={colors.ratingYellow} />
            <AiFillStar fontSize="22px" color={colors.ratingYellow} />
            <AiFillStar fontSize="22px" color={colors.ratingYellow} />
            <AiFillStar fontSize="22px" color={colors.ratingYellow} />
            <AiOutlineStar fontSize="22px" color="#d8d8d8" />
            <Typography variant="caption" component="p" sx={{ fontWeight: 700, my: 1 }}>
              Shairyar Lakhany - 25-Jan-2022
            </Typography>
          </Box>
          <Typography variant="subtitle2" color="initial">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. At illo vero accusamus molestiae. Tenetur, ducimus?
          </Typography>
        </Box>
      </Box> */}

      {/* ========== Product Questions ========== */}
      {/* <Box sx={{ pt: 2, pb: 5 }}>
        <Typography variant="h5" sx={{ my: 2 }}>Questions About This product (10)</Typography>
        <Divider sx={{ width: '25%', height: '2px', bgcolor: colors.primary, my: 1.5 }} />
        <Box sx={{ py: 1.5, mx: 1, borderBottom: `1px solid ${colors.gray}` }}>
          <Grid container spacing={1} sx={{ my: 1 }} alignItems="center">
            <Grid item md={0.5}>
              <BsFillChatLeftFill fontSize="22px" color={colors.primary} />
            </Grid>
            <Grid item md={11.5}>
              <Typography variant="subtitle2" color="initial">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. At illo vero accusamus molestiae. Tenetur, ducimus?
              </Typography>
              <Typography variant="caption" component="p" color={colors.darkGray} sx={{ fontWeight: 700, my: 0.5 }}>
                Shairyar Lakhany - 25-Jan-2022
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }} alignItems="center">
            <Grid item md={0.5}>
              <BsFillChatRightFill fontSize="22px" color={colors.primary} />
            </Grid>
            <Grid item md={11.5}>
              <Typography variant="subtitle2" color="initial">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. At illo vero accusamus molestiae. Tenetur, ducimus?
              </Typography>
              <Typography variant="caption" component="p" color={colors.darkGray} sx={{ fontWeight: 700, my: 0.5 }}>
                Shairyar Lakhany - 25-Jan-2022
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box> */}

      {/* ========== Related Products ========== */}
      <Box sx={{ pt: 2, pb: 5 }}>
        <Typography variant="h4" sx={{ my: 2 }}>Related Products</Typography>
        <Divider sx={{ width: '25%', height: '2px', bgcolor: colors.primary, my: 1.5 }} />
        {newArrival.length ? (
          <Fragment>
            <Box sx={{ my: 2 }}>
              <Slider
                card={'simpleCard'}
                product={newArrival.slice(0, 10)}
                slideView={1}
                spaceBetween={10}
                speed={1000}
                delay={2000}
                pagination={true}
                breakpoints={{
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 10
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                }}
              />
            </Box>
          </Fragment>
        ) : (
          <Loading />
        )}
      </Box>

    </Container>
  );
}

export default ProductDetail;