import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  createFilterOptions,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  IconButton,
  Badge,
  Button,
  CssBaseline,
  AppBar,
  Toolbar,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Collapse,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  ListItemButton,
  ListSubheader,
} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { makeStyles } from "@mui/styles";
import { HiOutlineTruck } from "react-icons/hi";
import { FiLogIn, FiLogOut } from "react-icons/fi";
import { FaListAlt } from "react-icons/fa";
import {
  AiOutlineSearch,
  AiOutlineShop,
  AiOutlineShopping,
} from "react-icons/ai";
import {
  MdExpandLess,
  MdExpandMore,
  MdFavorite,
  MdLanguage,
  MdNotificationsNone,
  MdOutlineContacts,
  MdOutlineSell,
} from "react-icons/md";
import { BiCategoryAlt, BiHome } from "react-icons/bi";
import { TiShoppingCart } from "react-icons/ti";
import { BsFillPersonFill, BsInfoCircle, BsPerson } from "react-icons/bs";
import { GrTextAlignLeft } from "react-icons/gr";
import { GrFilter } from "react-icons/gr"
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { MenuOutlined } from "@mui/icons-material";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import { connect } from "react-redux";
import InputBase from "@mui/material/InputBase";
import { useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";

import colors from "app/style/colors";
import Images from "assets/Images";
import { Service } from "app/config/service";
import useAuth from "app/hooks/useAuth";

// *Import Components
import BasicModal from "../Dialog/SellerRegisterModal";
import { PrimaryBasicButton } from "../UI/Button";
import { RemoveSpace } from "app/utils/index";
import AccountCircle from "@mui/icons-material/AccountCircle";
import FilterDrawer from "../FilterDrawer/FilterDrawer";

const useStyles = makeStyles({
  topNav: {
    color: colors.textPrimary,
    fontSize: "12px",
    padding: "0px 15px",
    textDecoration: "none",
    borderRight: `1px solid ${colors.gray}`,
    "&:last-child": {
      border: 0,
    },
  },
  icon: {
    verticalAlign: "middle",
    cursor: "pointer",
    color: colors.primary,
  },
  menu: {
    color: colors.white,
    padding: "10px",
    textDecoration: "none",
  },
});

const mapStateToProps = (state) => {
  return {
    items: state.items,
  };
};

const navItems = [
  {
    name: "Home",
    icon: <BiHome size={20} color={colors.secondary} />,
    path: "/",
  },
  {
    name: "About us",
    icon: <BsInfoCircle size={20} color={colors.secondary} />,
    path: "/",
  },
  {
    name: "Contact Us",
    icon: <MdOutlineContacts size={20} color={colors.secondary} />,
    path: "/",
  },
  {
    name: "All Vendors",
    icon: <AiOutlineShop size={20} color={colors.secondary} />,
    path: "/vendors-list",
  },
];

const drawerWidth = 240;

function Header(props) {
  const navigate = useNavigate();
  const { items } = props;

  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { customer, customerLogout, vendor, salePerson, salePersonLogout } = useAuth();
  const { pathname } = useLocation();
  const vendorName = localStorage.getItem("vendorName");

  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  const popupVendor = usePopupState({
    variant: "popover",
    popupId: "vendorMenu",
  });
  const [openDialog, setOpenDialog] = useState(false);
  //* For Filter Products

  //

  const [category, setCategory] = useState([]);
  const [vendorsList, setVendorsList] = useState([]);
  const [topCategory, setTopCategory] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [subTotal, setSubTotal] = useState(0);

  // *For Menu Drop Down
  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  // * For header drawer
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const [openCategoryList, setOpenCategoryList] = useState(false);
  // Filter Search
  let [searchRecommend, setSearchRecommend] = useState([]);
  let [changeSearch, setChangeSearch] = useState("");

  const handleCategoryList = () => {
    setOpenCategoryList(!openCategoryList);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const topNav = [
    {
      icon: <MdOutlineSell fontSize="18px" className={classes.icon} />,
      label: "Sell on PartzBuddy",
      path: "/vendor",
    },
    {
      icon: <HiOutlineTruck fontSize="18px" className={classes.icon} />,
      label: "Track Your Order",
      path: "/",
    },
    {
      icon: <AiOutlineShopping fontSize="18px" className={classes.icon} />,
      label: "Shop",
      path: "/",
    },
    {
      icon: <FiLogIn fontSize="16px" className={classes.icon} />,
      label: "Login",
      path: "/login",
    },
    {
      icon: <MdLanguage fontSize="18px" className={classes.icon} />,
      label: "EN",
      path: "/",
    },
  ];

  const mainMenu = [
    { label: "Home", path: "/" },
    { label: "Shop", path: "/shop" },
    { label: "About", path: "/about" },
    { label: "Contact", path: "/contact" },
  ];

  const dropDown = (
    <Menu
      anchorEl={anchorEl}
      open={openDropdown}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          ".MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem component={Link} to="/profile" sx={{ my: 1 }}>
        <ListItemIcon>
          <BsFillPersonFill />
        </ListItemIcon>
        Profile
      </MenuItem>
      <MenuItem component={Link} to="/order" sx={{ my: 1 }}>
        <ListItemIcon>
          <FaListAlt />
        </ListItemIcon>
        Orders
      </MenuItem>
      <MenuItem component={Link} to="/favourites" sx={{ my: 1 }}>
        <ListItemIcon>
          <MdFavorite />
        </ListItemIcon>
        Favourites
      </MenuItem>
      <MenuItem onClick={() => customerLogout()}>
        <ListItemIcon>
          <FiLogOut fontSize="16px" className={classes.icon} />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  const switchBtn = (
    <Fragment>
      {vendor ? (
        <PrimaryBasicButton
          component={Link}
          to={pathname.substring(0, 7) === "/vendor" ? "/" : "/vendor"}
          variant="contained"
        >
          {pathname.substring(0, 7) === "/vendor"
            ? "Switch to Buying"
            : "Switch to Sell"}
        </PrimaryBasicButton>
      ) : (
        <PrimaryBasicButton
          onClick={() => setOpenDialog(true)}
          variant="contained"
        >
          Switch to Sell
        </PrimaryBasicButton>
      )}
    </Fragment>
  );

  const authBtn = (
    <Fragment>
      {salePerson ? (
        <PrimaryBasicButton onClick={() => salePersonLogout()}>
          Logout
        </PrimaryBasicButton>
      ) : (
        <PrimaryBasicButton component={Link} to="/session" variant="outlined">
          Login
        </PrimaryBasicButton>
      )}
    </Fragment>
  );

  const menuLiStyle = {
    listStyle: "none",
    textDecoration: "none",
    color: colors.black,
    fontWeight: "600",
    py: 1.2,
    px: 2.5,
    mx: 0.5,
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      bgcolor: `${colors.primary} !important`,
      color: colors.white,
      transition: "0.3s ease-in-out",
    },
    "&:first-child": {
      bgcolor: "#ddd",
    },
  };

  const filterStyle = {
    position: "relative",
    listStyle: "none",
    textDecoration: "none",
    color: colors.black,
    fontWeight: "600",
    py: 1.2,
    px: 2.5,
    // mx: 0.5,
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      bgcolor: `${colors.primary} !important`,
      color: colors.white,
      transition: "0.3s ease-in-out",
    },
    "&:first-child": {
      bgcolor: "#ddd",
    },
  }

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.CategoryName,
  });

  // Top Categories
  const getTopCategory = async () => {
    try {
      const { Data } = await Service.getTopCategory();
      if (Data === null) return;
      setTopCategory(Data);
    } catch (error) {
      console.log(
        "🚀 ~ file: Header.js ~ line 259 ~ getTopCategory ~ error",
        error
      );
    }
  };

  // All Categories
  const getCategory = async () => {
    try {
      const { Data } = await Service.getCategory();
      setCategory(Data);
    } catch (error) {
      console.log("file: Header.js => line 258 => getCategory => error", error);
    }
  };

  // All Vendors
  const getVendorList = async () => {
    try {
      const { Data, Status, ResponseCode } = await Service.getVendorList();
      if (Status === true && ResponseCode === 200) {
        setVendorsList(Data);
      } else if (Status === false && ResponseCode === 404) {
        setVendorsList([]);
      }
    } catch (error) {
      console.log("🚀 ~ file: Header.js:288 ~ getVendorList ~ error", error);
    }
  };

  const getSubTotal = () => {
    let productAmount = 0;
    if (items.length > 0) {
      items.forEach((value) => {
        const givenDiscount = value.discount ? value.price - value.discount : 0;
        const price = (value.price - givenDiscount) * value.qty; // code change from (value.price - value.discount) * value.qty
        productAmount += price;
        setSubTotal(productAmount);
      });
    } else {
      setSubTotal(0);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const container = window !== undefined ? () => window().document.body : undefined;

  //* For Filter Drawer 
  const [filterOpen, setFilterOpen] = useState(false)
  const filterContainer = window !== undefined ? () => window().document.body : undefined;
  const filterDrawerToggle = () => {
    setFilterOpen(!filterOpen);
  };

  useEffect(() => {
    getCategory();
    getVendorList();
    getTopCategory();
    // searchProducts(1, search);
  }, []);

  useEffect(() => {
    getSubTotal();
  }, [items]);

  //* Navigate to Search Products Page  
  const searchProducts = async (formData) => {
    try {
      navigate(`/product/${formData.search}`);
    } catch (error) {
      console.log("🚀 ~ file: Header.js:280 ~ getSearchProduct ~ error", error);
    }
  };

  //* Debounce Function For Api Call Delay
  let timerID;
  //* API For Search Filter
  const searchProductsFilter = async (a, productName) => {
    clearTimeout(timerID);
    try {
      setLoading(true)
      timerID = setTimeout(async () => {
        const { Data } = await Service.searchProducts(a, productName);
        if (Data) {
          let searchData = []
          Data[0].ItemArray.map((val) => {
            searchData.push({ id: val.ItemID, label: val.ItemName })
          })
          setSearchRecommend(searchData);
        }
        else {
          setSearchData([])
        }
      }, 500)

    } catch (error) {
      console.log(
        "file: ProductCategory.js => line 25 => getNewArrival => error: ", error
      );
    }
    finally {
      setLoading(false)
    }
  };

  const drawer = (
    <Fragment>
      {pathname.substring(0, 7) === "/vendor" ? (
        <ListItem>
          <ListItemIcon>
            <AiOutlineShop
              fontSize="22px"
              className={classes.icon}
              style={{ cursor: "text", color: colors.secondary }}
            />
          </ListItemIcon>
          <ListItemText sx={{ color: colors.secondary }} primary={vendorName} />
        </ListItem>
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmit(searchProducts)}
          sx={{ display: "flex", alignItems: "center", px: 2, py: 1 }}>
          <IconButton type="submit">
            <AiOutlineSearch size={22} color={colors.secondary} />
          </IconButton>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            // options={searchRecommend === "" ? false : searchRecommend}
            freeSolo
            loading={loading}
            getOptionLabel={(searchRecommend) => (searchRecommend ? searchRecommend?.label : "")}
            // getOptionLabel={(searchRecommend) => `${searchRecommend?.label}`}
            options={searchRecommend}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            noOptionsText={"No product found"}
            onSelect={(e) => {
              setValue("search", e.target.value)
            }}
            // options={category}
            // getOptionLabel={(option) => option.CategoryName}
            // filterOptions={filterOptions}
            sx={{ width: "100%", ml: 1 }}
            size="small"
            renderInput={(params) => <TextField
              {...register("search")}
              {...params}
              label="search"
              name="search"
              onChange={(event) => {
                setValue("search", event.target.value)
                searchProductsFilter(1, event.target.value);
              }} />}
          />
        </Box>
      )}
      <Divider />
      <List sx={{ p: 0 }}>
        {navItems.map((item, index) => (
          <>
            <ListItem
              button
              key={index}
              onClick={() => {
                navigate(item.path);
                handleDrawerToggle();
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
            <Divider />
          </>
        ))}
        {pathname.substring(0, 7) === "/vendor" ? null : (

          <Fragment>
            <ListItem button onClick={handleCategoryList}>
              <ListItemIcon>
                <BiCategoryAlt size={20} color={colors.secondary} />
              </ListItemIcon>
              <ListItemText primary="All Categories" />
              {openCategoryList ? <MdExpandLess /> : <MdExpandMore />}
            </ListItem>

            <Collapse in={openCategoryList} unmountOnExit>
              <List component="div" disablePadding>
                {category.map((item, index) => (
                  <ListItem key={index} button sx={{ pl: 6 }}>
                    <ListItemText primary={item.CategoryName} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Fragment>
        )}
        <Divider />
      </List>
    </Fragment>
  );

  // const filter = (
  //   <Menu
  //     id="basic-menu"
  //     anchorFilter={anchorFilter}
  //     filterOpen={filterOpen}
  //     onClose={filterClose}
  //     MenuListProps={{
  //       'aria-labelledby': 'basic-button',
  //     }}
  //   >
  //     <MenuItem onClick={filterClose}>Profile</MenuItem>
  //     <MenuItem onClick={filterClose}>My account</MenuItem>
  //     <MenuItem onClick={filterClose}>Logout</MenuItem>
  //   </Menu>
  // )

  return (
    <Fragment>
      {/* ========== Seller Login/Register Modal ========== */}
      <BasicModal show={openDialog} onClose={() => setOpenDialog(false)} />

      {/* ========== Header ========== */}
      <Box
        sx={{
          borderBottom:
            pathname.substring(0, 7) === "/vendor"
              ? `4px solid ${colors.primary}`
              : "",
          display: { xs: "none", md: "block" },
        }}
      >
        <Container>
          <Grid
            container
            spacing={1}
            sx={{ my: 2 }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={3} md={2} lg={2}>
              <Link to="/">
                <Typography
                  component="img"
                  src={Images.logo}
                  sx={{
                    width: { xs: "80%", sm: "60%", lg: "auto" },
                    height: "auto",
                  }}
                />
              </Link>
            </Grid>
            {/* DropDown Box For Top Categories */}

            <Grid
              sx={{ display: { xs: "none", md: "block" } }}
              item
              xs={0}
              md={5}
              lg={5}
            >
              {/* Search Product===================================================================================================> */}

              <Box
                component="form"
                onSubmit={handleSubmit(searchProducts)}
                sx={{ display: "flex" }}
              >
                <Autocomplete
                  // component="form"
                  freeSolo
                  disablePortal
                  loading={loading}
                  id="combo-box-demo"
                  getOptionLabel={(searchRecommend) => (searchRecommend.label !== undefined ? searchRecommend?.label : "")}
                  options={searchRecommend}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  noOptionsText={"No product found"}
                  onSelect={(e) => { setValue("search", e.target.value) }}
                  // options={searchRecommend === "" ? false : searchRecommend}
                  sx={{ width: 400 }}
                  onSubmit={handleSubmit(searchProducts)}
                  renderOption={(params, searchRecommend) => (
                    <Box component='li' {...params} key={searchRecommend.id}>
                      {searchRecommend.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...register("search")}
                      // label="search"
                      name="search"
                      {...params}
                      onChange={(event) => {
                        setValue("search", event.target.value)
                        searchProductsFilter(1, event.target.value);
                      }}
                    />
                  )}
                />
                <IconButton
                  type="submit"
                  edge="end"
                  sx={{
                    borderRadius: "6px",
                    // background: "#80808038",
                    background: colors.white,
                    width: "61px",
                    color: "black",
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Box>

            </Grid>

            <Grid item xs={9} md={5} lg={5} sx={{ textAlign: "right" }}>
              {pathname.substring(0, 7) === "/vendor" ? (
                <Fragment>
                  {/* <Tooltip title="Notification" placement="bottom">
                    <IconButton size="small" sx={{ mx: 1, color: colors.black }}>
                      <Badge badgeContent={4} color="primary">
                        <MdNotificationsNone fontSize="24px" className={classes.icon} />
                      </Badge>
                    </IconButton>
                  </Tooltip> */}
                  <AiOutlineShop
                    fontSize="24px"
                    className={classes.icon}
                    style={{ cursor: "text" }}
                  />
                  <Typography
                    variant="caption"
                    sx={{ verticalAlign: "sub", fontSize: 16, ml: 0.5 }}
                  >
                    {vendorName}
                  </Typography>
                </Fragment>
              ) : (
                <Fragment>
                  {customer && (
                    <Fragment>
                      {/* <Tooltip title="Notification" placement="bottom">
                        <IconButton size="small" sx={{ mx: 1, color: colors.black }}>
                          <Badge badgeContent={4} color="primary">
                            <MdNotificationsNone fontSize="24px" className={classes.icon} />
                          </Badge>
                        </IconButton>
                      </Tooltip> */}
                      <IconButton
                        size="small"
                        sx={{ mx: 1, color: colors.black }}
                        onClick={handleClick}
                      >
                        <BsPerson fontSize="24px" className={classes.icon} />
                      </IconButton>
                    </Fragment>
                  )}
                  <Tooltip title="My Cart" placement="bottom">
                    <Typography
                      component={Link}
                      to="/cart"
                      sx={{
                        fontWeight: "700",
                        color: colors.textPrimary,
                        textDecoration: "none",
                      }}
                    >
                      <Badge badgeContent={items.length} color="primary">
                        <TiShoppingCart
                          fontSize="24px"
                          className={classes.icon}
                        />
                      </Badge>
                      &nbsp;
                      {subTotal > 0 && `Rs ${subTotal}`}
                    </Typography>
                  </Tooltip>
                  {!customer && authBtn}
                </Fragment>
              )}
              {switchBtn}
              {dropDown}
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* ========== Header Menu ========== */}
      {pathname.substring(0, 7) === "/vendor" ? (
        ""
      ) : (
        <Box
          sx={{
            display: {
              xs: "none",
              md: "block",
            },
            borderBottom: `4px solid ${colors.primary}`,
            mt: 4,
          }}
        >
          <Container>
            <Typography component="ul" sx={{ display: "flex", p: 0 }}>
              {/* <====================All Vendors Here=========> */}

              <Typography
                component="li"
                sx={menuLiStyle}
                // {...bindHover(popupVendor)}
                onClick={() => {
                  navigate("/vendors-list");
                }}
              >
                <GrTextAlignLeft
                  fontSize="22px"
                  style={{ marginRight: "15px", verticalAlign: "middle" }}
                />
                All Vendors
              </Typography>

              {/* <====================All Categories Here=========> */}

              <Typography
                component="li"
                sx={menuLiStyle}
                // {...bindHover(popupState)}
                onClick={() => {
                  navigate("/allcategories-lists");
                }}
              >
                <GrTextAlignLeft
                  fontSize="22px"
                  style={{ marginRight: "15px", verticalAlign: "middle" }}
                />
                All Categories
              </Typography>

              {category.slice(0, 4).map((item, index) => (
                <Typography
                  key={index}
                  component="li"
                  sx={menuLiStyle}
                  onClick={() =>
                    navigate(
                      `/category/${item.CategoryName}/${item.CategoryID}`
                    )
                  }
                >
                  {item.CategoryName}
                </Typography>
              ))}

            </Typography>
          </Container>
        </Box>
      )}

      {/* ========== Drawer Header ========== */}
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            ml: { sm: `${drawerWidth}px` },
            bgcolor: colors.white,
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs={4}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    color="primary"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => handleDrawerToggle()}
                    sx={{ mr: 2 }}
                  >
                    <MenuOutlined />
                  </IconButton>
                  <Link to="/">
                    <Typography
                      component="img"
                      src={Images.logo}
                      sx={{
                        width: { xs: "100%", sm: "80%" },
                        my: 2,
                        height: "auto",
                      }}
                    />
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={8} textAlign={{ xs: "right" }}>
                {pathname.substring(0, 7) === "/vendor" ? (
                  <Fragment></Fragment>
                ) : (
                  <Fragment>
                    {customer && (
                      <Fragment>
                        <IconButton
                          size="small"
                          sx={{ mx: { sx: 0, sm: 1 }, color: colors.black }}
                          onClick={handleClick}
                        >
                          <BsPerson fontSize="24px" className={classes.icon} />
                        </IconButton>
                      </Fragment>
                    )}
                    <Tooltip title="My Cart" placement="bottom">
                      <Typography
                        component={Link}
                        to="/cart"
                        sx={{
                          fontWeight: "700",
                          color: colors.textPrimary,
                          textDecoration: "none",
                        }}
                      >
                        <Badge badgeContent={items.length} color="primary">
                          <TiShoppingCart
                            fontSize="24px"
                            className={classes.icon}
                          />
                        </Badge>
                      </Typography>
                    </Tooltip>
                    {!customer && authBtn}
                  </Fragment>
                )}
                {switchBtn}
                {dropDown}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={() => handleDrawerToggle()}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
        </Box>
      </Box>
    </Fragment>
  );
}

export default connect(mapStateToProps)(Header);
