import { Box, Button, Collapse, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import GridViewIcon from '@mui/icons-material/GridView';

import React, { useEffect } from 'react'
import colors from 'app/style/colors';
import { useState } from 'react';
import { Service } from 'app/config/service';
import { BsCheckLg } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { PrimaryBasicButton } from './../UI/Button';
import { styled } from '@mui/material/styles';
import Images from 'assets/Images.js';

function FilterDrawer({ filterOpen, filterDrawerToggle, productName }) {
  const navigate = useNavigate();
  const itemName = productName

  //* Navigate to Filter Products Page  
  const filterProducts = async (categoryChecked, brandChecked, itemName) => {
    console.log("🚀 ~ file: FilterDrawer.js:25 ~ filterProducts ~ brandChecked", brandChecked)

    let CategoryID = []
    CategoryID = categoryChecked.map((item, index) => {
      return `&CatID[${index}]=${item}`
    })
    let BrandID = []
    BrandID = brandChecked.map((item, index) => {
      return `&BrandID[${index}]=${item}`
    })

    try {
      navigate('/filter', {
        state: {
          CatID: CategoryID.join(""),
          BrandID: BrandID.join(""),
          ItemName: itemName
        }
      });
    } catch (error) {
      console.log("🚀 ~ file: Header.js:280 ~ getSearchProduct ~ error", error);
    } finally {
      filterDrawerToggle()
    }
  };

  //* For All Filtered  API Call
  const [filteredData, setFilteredData] = useState([])
  // const filterProducts = async (categoryChecked) => {
  //   let CategoryID = []
  //   CategoryID = categoryChecked.map((item, index) => {
  //     return `&CatID[${index}]=${item}`
  //   })
  //   try {
  //     const { Data } = await Service.filterProducts(1, "", CategoryID.join(""));
  //     if (Data) {
  //       console.log("🚀 ~ ON Click=======> searchProducts ~ Data", Data)
  //       let searchData = []
  //       Data[0].ItemArray.map((val) => {
  //         searchData.push({ id: val.ItemID, label: val.ItemName })
  //       })
  //       setFilteredData(searchData)
  //     }
  //     else {
  //       setFilteredData([])
  //     }
  //   } catch (error) {
  //     console.log(
  //       "file: ProductCategory.js => line 25 => getNewArrival => error: ", error
  //     );
  //   }
  // };

  //* For Get Categories And Sub Categories
  const [category, setCategory] = useState([])

  const [subCategoryList, setSubCategoryList] = useState([])
  const getCategory = async () => {
    try {
      const { Data } = await Service.getCategory();
      setCategory(Data);
    } catch (error) {
      console.log("🚀 ~ file: FilterDrawer.js:48 ~ getCategory ~ error", error)
    }
  };

  // *Get Sub Categories
  const getSubCategory = async (id) => {
    try {
      const { Data } = await Service.getSubCategory(id)
      setSubCategoryList(Data)
    }
    catch (error) {
      console.log("🚀 ~ file: FilterDrawer.js:59 ~ getSubCategory ~ error", error)

    }
  }

  //* For Brands ApI Call
  const [brandList, setBrandList] = useState([]);
  const getBrands = async () => {
    try {
      const { Data } = await Service.getBrands()
      setBrandList(Data)
    }
    catch (error) {
      console.log("🚀 ~ file: FilterDrawer.js:72 ~ getBrands ~ error", error)
    }
  };

  const [openCategory, setOpenCategory] = useState(false);
  const [openBrand, setOpenBrand] = useState(false);
  const [categoryChecked, setCategoryChecked] = useState([]);
  const [brandChecked, setBrandChecked] = useState([]);

  const handleCategoryChecked = (value) => {
    const currentIndex = categoryChecked.indexOf(value);
    const newChecked = [...categoryChecked];

    if (currentIndex === -1) {
      console.log("🚀 ~ IF currentIndex", currentIndex)
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCategoryChecked(newChecked);
  };

  const handleBrandChecked = (value) => {
    console.log("🚀 ~ file: FilterDrawer.js:95 ~ handleBrandChecked ~ value", value)
    const currentIndex = brandChecked.indexOf(value);
    const newChecked = [...brandChecked];

    if (currentIndex === -1) {
      console.log("🚀 ~ IF BrandIndex", currentIndex)
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setBrandChecked(newChecked);
  };

  const handleCategory = () => {
    setOpenCategory(!openCategory);
  };

  const handleBrands = () => {
    setOpenBrand(!openBrand);
  }
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    margin: '20px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  //* API CALL For Filter Category And Brand
  useEffect(() => {
    getCategory()
    getBrands()
  }, [])

  const list = (
    <Box>

      <List
        sx={{ width: '100%', maxWidth: 380, minWidth: 340, bgcolor: 'background.paper' }}
        component="nav"
        subheader={
          <ListSubheader component="div">
            <Box display="flex" justifyContent="center">
              <Typography sx={{ color: colors.primary, bgcolor: colors.gray, py: 1, px: 5, borderRadius: "6px" }}>
                Select Products Category
              </Typography>
            </Box>
          </ListSubheader>
        }
      >

        <ListItemButton onClick={handleCategory}
          sx={{
            minHeight: 48,
            m: 1,
            borderRadius: "8px",
            "&.Mui-selected": {
              bgcolor: colors.secondary,
              color: colors.white,
              ":hover": {
                backgroundColor: colors.secondary,
                color: colors.white,
              }
            },
            ":hover": {
              backgroundColor: colors.secondary,
              color: colors.white,
            },
          }}>
          <ListItemIcon>
            <GridViewIcon />
          </ListItemIcon>
          <ListItemText primary="Categories" />
          {openCategory ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton >

        <Collapse in={openCategory} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {category.map((value, index) => (
              <ListItem key={index}>
                <ListItemButton sx={{ pl: 4 }}
                  onClick={() => handleCategoryChecked(value.CategoryID)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={categoryChecked.indexOf(value.CategoryID) !== -1}
                      tabIndex={-1}
                      disableRipple
                    // inputProps={{ 'aria-labelledby': index }}
                    />
                  </ListItemIcon>
                  <ListItemText id={index} primary={`${value.CategoryName}`} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>

      {/* Brand Collapse List */}
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
      >
        <ListItemButton onClick={handleBrands}
          sx={{
            minHeight: 48,
            m: 1,
            borderRadius: "8px",
            "&.Mui-selected": {
              bgcolor: colors.secondary,
              color: colors.white,
              ":hover": {
                backgroundColor: colors.secondary,
                color: colors.white,
              }
            },
            ":hover": {
              backgroundColor: colors.secondary,
              color: colors.white,
            },
          }}>
          <ListItemIcon>
            <LoyaltyIcon />
          </ListItemIcon>
          <ListItemText primary="Brands" />
          {openBrand ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton >

        <Collapse in={openBrand} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {brandList.map((value, index) => (
              <ListItemButton key={index} sx={{ pl: 4 }}
                onClick={() => handleBrandChecked(value.ID)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={brandChecked.indexOf(value.ID) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': index }}
                  />
                </ListItemIcon>
                <ListItemText id={index} primary={` ${value.Value}`} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </List>

      <Box width="100%" py={1} display="flex" justifyContent="center">
        <Button variant="contained" sx={{ borderRadius: "18px" }}
          onClick={() => filterProducts(categoryChecked, brandChecked, itemName)}>
          Search
        </Button>
      </Box>
    </Box>
  );
  return (
    <Box sx={{ position: "absolute" }}>
      {/* <Button onClick={filterDrawerToggle}></Button> */}
      <Drawer
        // container={filterContainer}
        variant="temporary"
        open={filterOpen}
        onClose={() => filterDrawerToggle()}
      >
        <DrawerHeader>
          <img src={Images.logo} alt="RidoLogo" style={{ width: "100px", marginRight: "90px" }} />
        </DrawerHeader>
        {list}
      </Drawer>
    </Box>
  )
}

export default FilterDrawer