import React, { useRef, useState } from 'react';
import { Box, Divider, FormControl, OutlinedInput, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import colors from 'app/style/colors';
import { Service } from 'app/config/service';

// *Import Components
import { Error } from 'app/components/UI/Error';
import VerifyOtp from 'app/components/Dialog/VerifyOtp';
import { PrimaryLoadButton } from 'app/components/UI/Button';
import { ErrorToaster, SuccessToaster } from 'app/components/UI/Toaster';

function CustomerRegister() {

  // *For Form Validation
  const { register, formState: { errors }, handleSubmit, watch, reset } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const [loading, setLoading] = useState(false);

  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [otp, setOtp] = useState();

  // *For Customer Register Data
  const [customerData, setCustomerData] = useState({});

  // *For Send Otp
  const sendOtp = async (data) => {
    console.log(data)
    setLoading(true)
    try {
      const otp = Math.floor(100000 + Math.random() * 900000)
      let obj = {
        EmailPh: data.phone,
        Name: data.name,
        Password: data.password,
        ConfirmPassword: data.confirmPassword,
        Cell: data.phone,
        BusinessTypeID: 1,
        UserTypeID: 2, //* 2 For Customer
        CountryCodes: "PK",
      }
      setCustomerData(obj)

      const { responseCode, message } = await Service.sendOtp(otp, data.phone);
      if (responseCode) {
        SuccessToaster(message)
        setOtp(otp)
        setOpenVerifyModal(true)
        reset()
      }

    } catch (error) {
      console.log('file: Register.js => line 26 => userRegister => error', error)
      ErrorToaster(error)

    } finally {
      setLoading(false)
    }
  };

  // *For Customer Register
  const customerRegister = async () => {
    try {
      const { Message, ResponseCode, Status, Data } = await Service.register(customerData);
      if (Status === true && ResponseCode === 200) {
        SuccessToaster(Message)
        setOpenVerifyModal(false)
        reset()
      } else {
        ErrorToaster(Message)
      }
    } catch (error) {
      console.log('file: Register.js => line 117 => customerRegister => error', error)
    }
  }

  // *For Resend OTP
  const reSendOtp = async () => {
    try {
      const generateOtp = Math.floor(100000 + Math.random() * 900000)
      const { responseCode, message } = await Service.sendOtp(generateOtp, customerData.Cell);
      if (responseCode) {
        SuccessToaster(message)
        setOtp(generateOtp)
      }
    } catch (error) {
      console.log('file: Register.js => line 47 => reSendOtp => error', error);
    }
  }

  return (
    <Box>

      {/* ===== Verify OTP Modal ===== */}
      <VerifyOtp open={openVerifyModal} otp={otp} register={customerRegister} reSendOtp={() => reSendOtp()} />

      <Typography variant="h5" color="initial">Register</Typography>
      <Divider sx={{ width: '25%', height: '2px', bgcolor: colors.primary, my: 1.5 }} />
      <Typography variant="body1" color="initial" sx={{ mt: 3 }} >Create new account today to reap the benefits of a personalized shopping experience.</Typography>
      <Typography component="form" onSubmit={handleSubmit(sendOtp)} sx={{ my: 2.5, mb: 3 }} >
        {/* <FormControl variant="standard" sx={{ width: '100%', marginY: '10px' }} >

          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>Email Address</Typography>
          <OutlinedInput
          // type="email"
          name="email"
          type="email"
            sx={{ width: '100%', borderRadius: '40px' }}
            error={errors?.email?.message && (true)}
            {...register("email", {
              required: 'Please enter a email address',
            })}
          />
          {errors?.email?.message && (
            <Error message={errors?.email?.message} />
          )}
        </FormControl> */}
        <FormControl variant="standard" sx={{ width: '100%', marginY: '10px' }} >
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>Name</Typography>
          <OutlinedInput
            sx={{ width: '100%', borderRadius: '40px' }}
            error={errors?.name?.message && (true)}
            {...register("name", {
              required: 'Please enter a user name',
            })}
          />
          {errors?.name?.message && (
            <Error message={errors?.name?.message} />
          )}
        </FormControl>
        <FormControl variant="standard" sx={{ width: '100%', my: 1 }} >
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>Phone</Typography>
          <OutlinedInput
            className="number-field"
            type="number"
            error={errors?.email?.message && (true)}
            sx={{ width: '100%', borderRadius: '40px' }}
            {...register("phone", {
              required: 'Please enter phone number.',
            })}
          />
          {errors?.phone?.message && (
            <Error message={errors?.phone?.message} />
          )}
        </FormControl>
        <FormControl variant="standard" sx={{ width: '100%', my: 1 }} >
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>Password</Typography>
          <OutlinedInput
            type="password"
            error={errors?.password?.message && (true)}
            sx={{ width: '100%', borderRadius: '40px' }}
            {...register("password", {
              required: 'Please enter password.',
              minLength: {
                value: 6,
                message: "Password must have at least 6 characters"
              }
            })}
          />
          {errors?.password?.message && (
            <Error message={errors?.password?.message} />
          )}
        </FormControl>
        <FormControl variant="standard" sx={{ width: '100%', my: 1 }} >
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>Confirm Password</Typography>
          <OutlinedInput
            type="password"
            error={errors?.confirmPassword?.message && (true)}
            sx={{ width: '100%', borderRadius: '40px' }}
            {...register("confirmPassword", {
              required: 'Please enter confirm password.',
              validate: value =>
                value === password.current || "Confirm password does not match"
            })}
          />
          {errors?.confirmPassword?.message && (
            <Error message={errors?.confirmPassword?.message} />
          )}
        </FormControl>
        <PrimaryLoadButton type="submit" variant="contained" loading={loading} >
          Register
        </PrimaryLoadButton>
      </Typography>
    </Box>
  );
}

export default CustomerRegister;