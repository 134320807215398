import React, { useState } from 'react';
import { Button, CardActionArea, Card, CardContent, Box, Typography, CardMedia, Table, TableBody, TableCell, TableRow, Dialog, DialogContent } from '@mui/material';
import { toast } from 'react-toastify';

import colors from 'app/style/colors';
import { Service } from 'app/config/service';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { SuccessToaster } from '../UI/Toaster';
import Swal from 'sweetalert2'
import Products from './../../views/Vendor/Products/Products';
import Images from 'assets/Images';


function StoreCard(props) {

  const token = localStorage.getItem("vendorToken")

  const { path, product, getProduct } = props
  const productData = product.QADetails

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => { setOpen(true) };

  const handleClose = () => {
    setOpen(false)
  };

  //* Product DeActivate Api Call

  const deactivateProduct = async (item) => {
    setLoading(true)
    try {
      let obj = {
        BusinessTypeID: 1,
        ItemID: item
      }
      const { Status, ResponseCode } = await Service.deactivateProduct(obj, token)
      if (Status === true && ResponseCode === 200) {
        setLoading(false)
        SuccessToaster('Product Deactivate Successfully!')
      }

    } catch (error) {
      console.log("🚀 ~ file: StoreCard.js:41 ~ deactivateProduct ~ error", error)
    }

    setOpen(false)
  };

  //* Product Out of Stock Api Call

  const outOfStockProduct = async (item) => {
    setLoading(true)
    try {
      let obj = {
        BusinessTypeID: 1,
        ItemID: item
      }
      const { Status, ResponseCode } = await Service.outOfStockProduct(obj, token)
      if (Status === true && ResponseCode === 200) {
        setLoading(false)
        Swal.fire(
          'Out of Stock!',
          'Your product has been Out of Stock.',
          'success'
        )
        // SuccessToaster('Product Out of Stock Successfully!')
      }

    } catch (error) {
      console.log("🚀 ~ file: StoreCard.js:41 ~ outOfStockProduct ~ error", error)
    }

    setOpen(false)
  };

  //* Product Put Back In Stock Api Call

  const inStockProduct = async (item) => {
    setLoading(true)
    try {
      let obj = {
        BusinessTypeID: 1,
        ItemID: item
      }

      const { Status, ResponseCode } = await Service.inStockProduct(obj, token)
      if (Status === true && ResponseCode === 200) {
        setLoading(false)
        Swal.fire(
          'In Stock!',
          'Your product has been In Stock.',
          'success'
        )
        // SuccessToaster('Product In Stock Successfully!')
      }

    } catch (error) {
      console.log("🚀 ~ file: StoreCard.js:41 ~ inStockProduct ~ error", error)
    }

    setOpen(false)
  };

  //* Handle Deactivate Product

  const handleDeactivate = (item) => {
    setOpen(false)
    deactivateProduct(item)
    Swal.fire(
      'Deactivate!',
      'Your file has been deactivate.',
      'success'
    )
    getProduct(1, true)
  };

  //* Handle Out of Stock
  const handleOutofStock = (item) => {

    setOpen(false)
    outOfStockProduct(item)

    getProduct(1, true)
  };

  //* Handle InStock
  const handleInStock = (item) => {
    setOpen(false)
    inStockProduct(item)

    getProduct(1, true)
  };

  // Sweet Alert Swal Delete Confirm
  const handleConfirmDelete = (item) => {
    setOpen(false)
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProduct(item)
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
        getProduct(1, true)
      }
      else {
        setOpen(true)
      }
    })
  }


  const deleteProduct = async (item) => {
    setLoading(true)
    try {
      let obj = {
        BusinessTypeID: 1,
        ItemID: item
      }
      const { Status, ResponseCode } = await Service.deleteProduct(obj, token)
      if (Status === true && ResponseCode === 200) {
        setLoading(false)
        SuccessToaster('Product Delete Successfully!')
      }

    } catch (error) {
      console.log('file: StoreCard.js => line 47 => deleteProduct => error', error);
    }

    setOpen(false)
  };


  return (
    <Box  >
      <Card sx={{
        maxWidth: 220,
        cursor: "pointer",
        '&:hover': {
          boxShadow: 3,
        }
      }}
      >
        <CardActionArea sx={{ maxHeight: "245px", minHeight: "245px", }} onClick={() => handleOpen()}>
          <CardMedia
            component="img"
            height="100px"
            width="100%"
            image={product.File ? product.File : Images.imagePlaceholder}
            alt={product.ItemName}
          />
          <CardContent sx={{ minHeight: "110px" }}>
            <Typography gutterBottom className='text-truncate' variant="subtitle1" component="div" sx={{ lineHeight: 1 }}>
              {product.ItemName}
            </Typography>
            {product.OutOfStock &&
              <Typography gutterBottom variant="subtitle1" sx={{ fontWeight: "bold", color: colors.secondary, lineHeight: 1 }} color="text.secondary" component="div">
                Out of stock
              </Typography>}

            <Typography variant="body1" color="text.secondary">
              Item ID: {product.ItemID}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {product.DiscountAmount === 0 ? (
                <Typography variant="body1" sx={{ fontWeight: "bold", color: colors.primary, lineHeight: 1 }} color="text.secondary">
                  Rs {product.SalesRate}
                </Typography>
              ) : (
                <Typography variant="body1" sx={{ fontWeight: "bold", color: colors.primary, lineHeight: 1 }} color="text.secondary">
                  {/* Rs. {product.SalesRate - product.DiscountAmount} After 27 Feb */}
                  Rs. {product.DiscountAmount}
                </Typography>
              )}
              {product.DiscountAmount > 0 && product.DiscountAmount !== product.SalesRate &&
                <Typography variant="subtitle2" sx={{ ml: 0.5, textDecoration: "line-through", color: colors.secondary, }} color="text.secondary">
                  Rs {product.SalesRate}
                </Typography>
              }
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
      {path === "/vendor/products" &&
        <Dialog
          open={open}
          onClose={() => handleClose()}>
          <DialogContent>

            <Typography variant='h5' sx={{
              textAlign: "center", mb: 2, fontWeight: "bold", color: colors.primary,
            }} >Product Detail</Typography>
            <Table>
              <TableBody>
                {productData.map((item, index) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={index}
                  >
                    <TableCell component="th" scope="row">
                      {item.QName === "File_Image" ? "Image" : item.QName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.QName === "File_Image" ?
                        <img src={item.SelectionData[0].Value} alt="" width="200px" />
                        :
                        item.SelectionData[0].Value
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                component={Link}
                to={`/vendor/edit-product/${product.ItemID}`}
                variant="contained"
                sx={{
                  textTransform: 'capitalize',
                  bgcolor: colors.secondary,
                  mx: 0.5,
                  '&:hover': {
                    bgcolor: "#c4364c",
                  },
                }}
              >
                Quick Edit
              </Button>
              <Button
                onClick={() => handleDeactivate(product.ItemID)}
                variant="contained"
                sx={{
                  textTransform: 'capitalize',
                  bgcolor: colors.secondary,
                  mx: 0.5,
                  '&:hover': {
                    bgcolor: "#c4364c",
                  },
                }}
              >
                Deactivate
              </Button>

              {/* For Out of Stock Products */}
              {product.OutOfStock === true ? <Button
                onClick={() => handleInStock(product.ItemID)}
                variant="contained"
                sx={{
                  textTransform: 'capitalize',
                  bgcolor: colors.secondary,
                  mx: 0.5,
                  '&:hover': {
                    bgcolor: "#c4364c",
                  },
                }}
              >
                InStock
              </Button> :
                <Button
                  onClick={() => handleOutofStock(product.ItemID)}
                  variant="contained"
                  sx={{
                    textTransform: 'capitalize',
                    bgcolor: colors.secondary,
                    mx: 0.5,
                    '&:hover': {
                      bgcolor: "#c4364c",
                    },
                  }}
                >
                  Out of Stock
                </Button>
              }

              {/*  */}
              <LoadingButton
                onClick={() => handleConfirmDelete(product.ItemID)}
                variant="contained"
                loading={loading}
                sx={{
                  textTransform: 'capitalize',
                  mx: 0.5,
                  bgcolor: colors.secondary,
                  '&:hover': {
                    bgcolor: "#c4364c",
                  },
                }}
              >
                Delete
              </LoadingButton>
            </Box>

          </DialogContent>
        </Dialog>
      }
    </Box>

  );
}

export default StoreCard
