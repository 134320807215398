import React, { useState } from "react";
import {
  Tab,
  Box,
  Dialog,
  DialogContent,
  Typography,
  FormControl,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";

import Images from "assets/Images";
import { useForm } from "react-hook-form";
import { Error } from "../UI/Error";
import colors from "app/style/colors";
// Api Import
import { Service } from "app/config/service";
import VerifyOtp from "app/components/Dialog/VerifyOtp";

// Change Password
import ChangePassword from "app/components/Dialog/ChangePassword";
import { SuccessToaster } from "../UI/Toaster";

function ResetPassword(props) {
  console.log("🚀 ~ file: ResetPassword.js:26 ~ ResetPassword ~ props:", props)
  // Loader state
  let [load, setLoad] = useState(false);
  let [openOTP, setOpenOTP] = useState(false);
  let [token, setToken] = useState("");

  let [otp, setOtp] = useState("");
  console.log("🚀 ~ file: ResetPassword.js:33 ~ ResetPassword ~ otp:", otp)
  const [phoneNo, setPhoneNo] = useState("");
  let [number, setNumber] = useState([])
  let [changePassModel, setchangePassModel] = useState(false);
  let [formDataNumber, setFormDataNumber] = useState("")

  //* Resend OTP Link Delay
  const [isDisplayed, setIsDisplayed] = useState(false);

  const { show, onClose, userType } = props;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  //  Forget Your password dialog Box
  let openChangePassword = () => {
    setchangePassModel(!changePassModel);
    setOpenOTP(false)
  };

  // Send OTP to number -------------------------------------------------------------------------------------------------------------------------->
  const sendOtp = async (data) => {
    try {
      const otp = Math.floor(100000 + Math.random() * 900000);
      const { responseCode, message } = await Service.sendOtp(otp, data);
      if (responseCode) {
        SuccessToaster(message)
      }
      setOtp(otp);
      // Open OTP Model to verify
      setOpenOTP(true);

      // onClose()
      // reset()

      // const { Message, ResponseCode, Status, Data } = await Service.register(obj);
      // if (Status === true && ResponseCode === 200) {
      //   SuccessToaster(Message)
      //   reset()
      //   onClose()
      // } else if (Status === false && ResponseCode === 400) {
      //   ErrorToaster(Data)
      // }
    } catch (error) {
    } finally {
    }
  };

  // Open OTP MODEL
  // let OtpModel = () => {
  //   setOpenOTP(!openOTP);
  // };
  const resetPassword = async (data) => {
    setLoad(true);
    setFormDataNumber(data.phone)
    //* Resend OTP Link Delay
    setTimeout(() => (
      setIsDisplayed(true)
    ), 7000)
    try {
      let obj = {
        Email: data.phone + userType, // *-1 For Vendor
      };
      setNumber(obj.Email)
      let { token } = await Service.forgetPassword(obj);
      if (token) {
        setToken(token);
      }
      // Send OTP To NUmber
      setPhoneNo(data.phone)
      sendOtp(data.phone);
      // Open OTP MODEL
    } catch (error) {
      console.log(
        "file: ResetPassword.js => line 20 => resetPassword => error",
        error
      );
      // OtpModel()
    } finally {
      setLoad(false);
    }

  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "40%",
            height: "auto",
            borderRadius: "10px",
          },
        }}
        maxWidth="md"
        open={show}
        onClose={onClose}
      >
        <DialogContent sx={{ p: 4 }}>
          <Box sx={{ textAlign: "center" }}>
            <img src={Images.logo} alt="Partz Buddy" />
          </Box>
          <Typography
            variant="h6"
            sx={{
              color: colors.primary,
              textAlign: "center",
              fontWeight: "bold",
              mt: 2,
            }}
          >
            Forgot Your Password?
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: colors.darkGray, textAlign: "center", mt: 1 }}
          >
            Please enter the phone number associated with your account and We
            will sent OTP to your to reset your password.
          </Typography>
          <Typography
            component="form"
            onSubmit={handleSubmit(resetPassword)}
            sx={{ my: 1.5, mb: 1 }}
          >
            <FormControl variant="standard" fullWidth>
              <TextField

                size="medium"
                variant="outlined"
                label="Phone Number"
                sx={{ my: 1 }}
                error={errors?.phone?.message && true}
                {...register("phone", {
                  required: "Please enter phone number.",
                })}
              />
              {errors?.phone?.message && (
                <Error message={errors?.phone?.message} />
              )}
            </FormControl>
            <LoadingButton
              disabled={load}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ p: 1, my: 2 }}
            >
              Send OTP
            </LoadingButton>
          </Typography>
        </DialogContent>
      </Dialog>

      {/* /* OTP Model -----------------------------------------------------------------------------------------------------------------> */}
      <VerifyOtp
        isDisplayed={isDisplayed}
        open={openOTP}
        onClose={() => setOpenOTP(false)}
        register={openChangePassword}
        resendOtp={() => sendOtp(phoneNo)}
        // sendOtp(phoneNo)
        // reSendOtp={sendOtp(formDataNumber)}
        otp={otp}
      />

      {/* Password Set New Password--------------------------------------------------------------------------------------------------> */}

      <ChangePassword open={changePassModel}
        onClose={setchangePassModel} token={token} number={number} ClosepassDiagloag={onClose} />
    </>
  );
}

export default ResetPassword;
