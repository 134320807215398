import React, { Fragment, useState } from 'react';
import { Box, Typography, Grid, Divider, Checkbox, List, ListItemButton, Collapse, Button, ListItemIcon, ListItemText, ListItem, } from '@mui/material';
import { ExpandLess, ExpandMore, FilterAlt, } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';


import colors from 'app/style/colors';


const useStyles = makeStyles({
  filterBox: {
    padding: '20px',
    backgroundColor: colors.lightGray,
    borderRadius: "10px",
    boxShadow: "rgb(3 0 71 / 10%) 0px 2px 3px",
    '&:nth-last-child': {
      border: '0px',
    },
  },
});

const offerList = ["On Sale", "In Stock", "Featured"]
function Filter({ category }) {


  const classes = useStyles()

  return (
    <Grid item xs={2.5} >
      <Box className={classes.filterBox}>
        <Typography variant='body2' sx={{ fontWeight: "bold" }}>
          Vendors
        </Typography>
        <List sx={{ width: '100%', }}>
          {category.map((item, index) => (
            <Fragment>
              <ListItem key={index} disablePadding  >
                <ListItemButton disableRipple dense sx={{ display: 'block', width: '10px', p: 0 }} >
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    size='small'
                  />
                </ListItemButton>
                <ListItemButton disableRipple sx={{ flex: '100%', py: 0 }}>
                  <ListItemText primary={<Typography variant="h6" style={{ color: colors.textPrimary, fontSize: "14px" }}>{item.ItemName}</Typography>} />
                </ListItemButton>
              </ListItem>

            </Fragment>
          ))}
        </List>
        <Divider sx={{ my: 2 }} />
        <List sx={{ width: '100%', }}>
          {offerList.map((item, index) => (
            <Fragment>
              <ListItem key={index} disablePadding  >
                <ListItemButton disableRipple dense sx={{ display: 'block', width: '10px', p: 0 }} >
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    size='small'
                  />
                </ListItemButton>
                <ListItemButton disableRipple sx={{ flex: '100%', py: 0 }}>
                  <ListItemText primary={<Typography variant="h6" style={{ color: colors.textPrimary, fontSize: "14px" }}>{item}</Typography>} />
                </ListItemButton>
              </ListItem>

            </Fragment>
          ))}
        </List>

      </Box>

    </Grid>
  )
}

export default Filter;