// *Import Components
import AddProduct from "app/views/Vendor/AddProduct/AddProduct";
import Dashboard from "app/views/Vendor/Dashboard/Dashboard";
import VendorOrder from "app/views/Vendor/Order/VendorOrder";
import Products from "app/views/Vendor/Products/Products";
import VendorProfile from "app/views/Vendor/Profile/Profile";
import Promotions from "app/views/Vendor/Promotions/Promotions";
import QuickAddProduct from "app/views/Vendor/QuickAddProduct/QuickAddProduct";
import ProductSalesReport from "app/views/Vendor/Reports/ProductSalesReport";
import RevenueReport from "app/views/Vendor/Reports/RevenueReport";
import Reports from "app/views/Vendor/Reports/Reports";
import SalesReport from "app/views/Vendor/Reports/SalesReport";
import Reviews from "app/views/Vendor/Reviews/Reviews";
import Sales from "app/views/Vendor/Sales/Sales";
import Store from "app/views/Vendor/Store/Store";
import EditProduct from "app/views/Vendor/EditProduct/EditProduct";
import DeactivateProducts from "app/views/Vendor/DeactivateProducts/DeactivateProducts";

export const VendorRoutes = [
  {
    path: "/vendor/profile",
    component: <VendorProfile />
  },
  {
    path: "/vendor",
    component: <Dashboard />
  },
  {
    path: "/vendor/my-orders",
    component: <VendorOrder />
  },
  {
    path: "/vendor/promotions",
    component: <Promotions />
  },
  {
    path: "/vendor/store",
    component: <Store />
  },
  {
    path: "/vendor/products",
    component: <Products />
  },
  {
    path: "/vendor/deactivate-products",
    component: <DeactivateProducts />
  },
  {
    path: "/vendor/reviews",
    component: <Reviews />
  },
  {
    path: "/vendor/sales",
    component: <Sales />
  },
  {
    path: "/vendor/reports",
    component: <Reports />
  },
  {
    path: "/vendor/revenue-report",
    component: <RevenueReport />
  },
  {
    path: "/vendor/sales-report",
    component: <SalesReport />
  },
  {
    path: "/vendor/product-sales-report",
    component: <ProductSalesReport />
  },
  {
    path: "/vendor/add-products",
    component: <AddProduct />
  },
  {
    path: "/vendor/quick-add",
    component: <QuickAddProduct />
  },
  {
    path: "/vendor/edit-product/:id",
    component: <EditProduct />
  }
]