// *Import Components
import Favourites from "app/views/Customer/Favourites/Favourites";
import CustomerOrder from "app/views/Customer/Order/CustomerOrder";
import CustomerProfile from "app/views/Customer/Profile/Profile";

export const CustomerRoutes = [
  {
    path: "/profile",
    component: <CustomerProfile />
  },
  {
    path: "/order",
    component: <CustomerOrder />
  },
  {
    path: "/favourites",
    component: <Favourites />
  },
]