import React from 'react'
import { Box, Typography } from '@mui/material'

import colors from 'app/style/colors'
import Images from 'assets/Images'

export function ProductNotFound({ error }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
      <img src={Images.notFound} width="10%" alt='Not Found' />
      <Typography variant='h5' sx={{ mt: 5, fontWeight: "bold", color: colors.secondary, textAlign: 'center' }}>{error}</Typography>
    </Box>
  )
}

export function PageNotFound() {
  return (
    <Box sx={{ height: '80vh', textAlign: 'center' }}>
      <Typography variant="h4" color="initial" sx={{ pt: 4 }}>
        404 Page NOt Found
      </Typography>
    </Box>
  )
}
