import React, { Fragment, useEffect, useState } from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';

import { Service } from 'app/config/service';

// *Import Components
import Banner from 'app/components/Banners/Banner';
import Slider from 'app/components/Sliders/Slider';
import SimpleCard from 'app/components/Cards/SimpleCard';
import { Loading } from 'app/components/UI/Loader';
import colors from 'app/style/colors';
import { LoadingButton } from '@mui/lab';

function Home() {

  const heading = {
    fontSize: 30,
    fontWeight: 500,
    pt: 2,
    pb: 1,
    mt: 2,
  };

  const [banner, setBanner] = useState([]);
  const [topCategory, setTopCategory] = useState([]);
  const [feature, setFeature] = useState([]);
  const [newArrival, setNewArrival] = useState([]);
  const [bestSeller, setBestSeller] = useState([]);
  const [product, setProduct] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [load, setLoad] = useState(false);

  // *Get Banner
  const getBanner = async () => {
    try {
      const { Data } = await Service.getBanner();
      if (Data === null) return
      setBanner(Data)
    } catch (error) {
      console.log('file: Home.js => line 23 => getBanner => error', error)
    }
  };

  // *Get Top Category
  const getTopCategory = async () => {
    try {
      const { Data } = await Service.getTopCategory();
      if (Data === null) return
      setTopCategory(Data)
    } catch (error) {
      console.log('file: Home.js => line 49 => getTopCategory => error', error)
    }
  };

  // *Get Feature
  const getFeature = async () => {
    try {
      const { Data } = await Service.getFeature();
      if (Data === null) return
      setFeature(Data.Products)
    } catch (error) {
      console.log('file: Home.js => line 33 => getFeature => error', error)
    }
  };

  // *Get New Arrival
  const getNewArrival = async () => {
    try {
      const { Data } = await Service.getNewArrival();
      if (Data === null) return
      setNewArrival(Data)
    } catch (error) {
      console.log('file: Home.js => line 33 => getNewArrival => error', error)
    }
  };

  // *Get Best Seller
  const getBestSeller = async () => {
    try {
      const { Data } = await Service.getBestSeller();
      if (Data === null) return
      setBestSeller(Data)
    } catch (error) {
      console.log('file: Home.js => line 33 => getBestSeller => error', error)
    }
  };


  // *Get Products Filter By Page
  const getProductsFilterByPage = async (pageNo) => {
    setLoad(true)
    try {
      setPageNumber(pageNo + pageNumber)
      const { Data } = await Service.getProductsFilterByPage(pageNo + pageNumber);
      if (Data === null) return
      setLoad(false)
      setProduct([...product, ...Data.Products])
      setProductCount(Data.Count)
      // console.log('file: Home.js => line 100 => getProductsFilterByPage => Data', [...product, ...Data.Products]);
    } catch (error) {
      console.log('file: Home.js => line 103 => getProductsFilterByPage => error', error);
    }
  };

  useEffect(() => {
    getBanner()
    getTopCategory()
    getFeature()
    setTimeout(() => {
      getNewArrival()
      getBestSeller()
      getProductsFilterByPage(1)
    }, 1000);
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Fragment>

      <Box sx={{ bgcolor: 'rgba(245, 245, 245,0.5)' }}>

        {/* ========== Banners ========== */}
        {banner.length > 0 &&
          <Banner banner={banner} />
        }

        <Container>

          {/* ========== Top Category ========== */}
          <Typography variant="h3" sx={heading} >
            Top Category
          </Typography>
          {topCategory.length ? (
            <Box sx={{ my: 2 }}>
              <Slider
                card='categoryCard'
                product={topCategory}
                slideView={3}
                spaceBetween={5}
                speed={2000}
                delay={2500}
                pagination={false}
                breakpoints={{
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 5,
                    spaceBetween: 5
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 2,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                  },
                }}
              />
            </Box>
          ) : (
            <Loading />
          )}

          {/* ========== Featured Products ========== */}


          <Typography variant="h3" sx={heading} >
            Our Featured Products
          </Typography>
          {feature.length ? (
            <Box sx={{ my: 2 }}>

              <Slider
                card='simpleCard'
                product={feature}
                slideView={1}
                spaceBetween={10}
                speed={2000}
                delay={2500}
                pagination={false}
                breakpoints={{
                  // when window width is >= 640px
                  440: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                }}
              />
            </Box>
          ) : (
            <Loading />
          )}

          {/* ========== Best Sellers ========== */}
          <Typography variant="h3" sx={heading} >
            Best Sellers
          </Typography>
          {bestSeller.length ? (
            <Box sx={{ my: 2 }}>
              <Grid container spacing={1.5} columns={10}>
                {bestSeller.map((item, index) => (
                  <Grid key={index} item xs={10} sm={5} md={2} >
                    <SimpleCard product={item} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Loading />
          )}

          {/* ========== New Arrivals ========== */}
          <Typography variant="h3" sx={heading} >
            New Arrival
          </Typography>
          {newArrival.length ? (
            <Box sx={{ my: 2 }}>
              <Slider
                card={'simpleCard'}
                product={newArrival}
                slideView={1}
                spaceBetween={10}
                speed={2000}
                delay={2500}
                pagination={true}
                breakpoints={{
                  // when window width is >= 640px
                  400: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                }}
              />
            </Box>
          ) : (
            <Loading />
          )}

          {/* ========== Explore More ========== */}
          <Typography variant="h3" sx={heading} >
            Explore More
          </Typography>
          {product.length ? (
            <Box sx={{ my: 2 }}>
              <Grid container spacing={1.5} columns={10}>
                {product.map((item, index) => (
                  <Grid key={index} item xs={10} sm={5} md={2}>
                    <SimpleCard product={item} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Loading />
          )}
          {productCount !== product.length &&
            <Box sx={{ textAlign: "center", my: 2 }} onClick={() => getProductsFilterByPage(1)}>
              <LoadingButton loading={load} variant='contained' sx={{ bgcolor: colors.secondary, '&:hover': { bgcolor: colors.secondaryDark } }}>
                Load More
              </LoadingButton>
            </Box>
          }
        </Container>

      </Box>

    </Fragment>
  );
}

export default Home;