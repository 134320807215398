import React, { Fragment } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import colors from 'app/style/colors';
import './style.css'
import Images from 'assets/Images'

// print()
function VendorOrderDetail(props) {

  const { orderDetail, show, handleClose, print } = props

  // if (print)
  // {
  //   document.getElementById("printit").print()
  // }

  return (
    <Dialog
      open={show}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle sx={{ textAlign: "center", width: "100%" }} id="alert-dialog-title " >
        <Avatar
          alt="partzbuddy"
          src={Images.logo}
          sx={{ width: '90px', height: 'auto', borderRadius: '0px', m: 'auto' }}
        />
        <p style={{ width: "100%", textAlign: 'center' }}><b>Order Details</b></p>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant='h5' sx={{ fontWeight: "bold", color: colors.secondary }}>{orderDetail?.productName}</Typography>
          {/* <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }} >
            <Typography variant='body1' sx={{ fontWeight: "bold", color: colors.primary }}>Customer Name</Typography>
            <Typography variant='body1' sx={{ color: colors.primary }}>{orderDetail?.name}</Typography>
          </Box> */}
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }} >
            <Typography variant='body1' sx={{ fontWeight: "bold", color: colors.primary }}>Invoice</Typography>
            <Typography variant='body1' sx={{ color: colors.primary }}>{orderDetail?.invoice}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }} >
            <Typography variant='body1' sx={{ fontWeight: "bold", color: colors.primary }}>Date</Typography>
            <Typography variant='body1' sx={{ color: colors.primary }}>{orderDetail?.date}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }} >
            <Typography variant='body1' sx={{ fontWeight: "bold", color: colors.primary }}>Delivery Address</Typography>
            <Typography variant='body1' sx={{ color: colors.primary }}>{orderDetail?.address}</Typography>
          </Box>
          <Box sx={{ mt: 2, py: 1, borderTop: `1px solid ${colors.primary}`, borderBottom: `1px solid ${colors.primary}` }}>
            {orderDetail?.saleDetail ? (
              <Fragment>
                {orderDetail?.saleDetail.map((item, index) => (
                  <Box key={index} sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }} >


                    <Typography variant='body1' sx={{ color: colors.primary }}><Avatar alt="Remy Sharp" sx={{ width: '400', objectFit: "cover" }} src={item.File} /></Typography>
                    <Typography variant='body1' sx={{ color: colors.primary }}>{item.Qty}x</Typography>
                    <Typography variant='body1' sx={{ color: colors.primary, flex: 1 / 2, textAlign: "center" }}>{item.ItemName}</Typography>
                    <Typography variant='body1' sx={{ color: colors.primary }}>Rs: {item.SalePrice}</Typography>
                  </Box>
                ))}
              </Fragment>
            ) : (
              null
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }} >
            <Typography variant='body1' sx={{ fontWeight: "bold", color: colors.primary }}>SubTotal</Typography>
            <Typography variant='body1' sx={{ color: colors.primary }}>{orderDetail?.subTotal}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }} >
            <Typography variant='body1' sx={{ fontWeight: "bold", color: colors.primary }}>Discount Amount</Typography>
            <Typography variant='body1' sx={{ color: colors.primary }}>{orderDetail?.discount} </Typography>
          </Box>
          {/* <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }} >
            <Typography variant='body1' sx={{ fontWeight: "bold", color: colors.primary }}>Delivery Charges</Typography>
            <Typography variant='body1' sx={{ color: colors.primary }}>{orderDetail?.delivery}</Typography>
          </Box> */}
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }} >
            <Typography variant='body1' sx={{ fontWeight: "bold", color: colors.primary }}>Total Amount</Typography>
            <Typography variant='body1' sx={{ color: colors.primary }}>{orderDetail?.totalAmount}</Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button variant='contained' onClick={() => handleClose()}>Save</Button> */}
        {/* <Button variant='contained' onClick={() => handleClose()}>Share</Button> */}
      </DialogActions>
    </Dialog>
  );
}

export default VendorOrderDetail
