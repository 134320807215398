import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';


import colors from 'app/style/colors';
import useAuth from './../../hooks/useAuth';

function OrderSummary(props) {

  const { items } = props
  const { pathname } = useLocation();

  const [subTotal, setSubTotal] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [givenDiscount, setGivenDiscount] = useState(0);

  // const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState(false);
  const { customer } = useAuth()

  const getTotal = () => {
    let productAmount = 0;
    let totalDiscount = 0;
    let discountGiven = 0;
    if (items.length > 0) {
      items.forEach(value => {

        const price = value.price * value.qty
        // const discount = value.discount * value.qty
        const discount = value.discount === 0 ? price : value.discount * value.qty
        const givenDiscount = (value.discount ? value.price - value.discount : 0) * value.qty

        totalDiscount += discount
        productAmount += price
        discountGiven += givenDiscount
        setSubTotal(productAmount)
        setDiscountAmount(totalDiscount)
        setDeliveryCharges(value.delivery * items.length)
        setGivenDiscount(discountGiven)
      });
    }
  };

  useEffect(() => {
    getTotal()
  }, [items]);

  return (
    <Box sx={{ bgcolor: colors.lightGray, borderRadius: '10px', padding: '15px' }} >
      <Typography variant="h5" color={colors.textPrimary}>Order Summary</Typography>
      <Divider sx={{ mt: 2, mb: 3 }} />
      <Box sx={{ display: 'flex', my: 2 }}>
        <Typography variant="body1" sx={{ flex: 'auto' }} >Subtotal</Typography>
        <Typography variant="body1" align="right">Rs {subTotal}</Typography>
      </Box>
      <Box sx={{ display: 'flex', my: 2 }}>
        <Typography variant="body1" sx={{ flex: 'auto' }} >Discount</Typography>
        <Typography variant="body1" align="right">
          Rs {givenDiscount}
          {/* + (pathname === '/checkout' && deliveryCharges) */}
          {/* subTotal - (discountAmount) */}
        </Typography>
      </Box>

      {pathname === '/checkout' ?
        <Fragment>
          <Box sx={{ display: 'flex', my: 2 }}>
            <Typography variant="body1" sx={{ flex: 'auto' }} >Delivery Charges</Typography>
            <Typography variant="body1" align="right">Rs {deliveryCharges}</Typography>
          </Box>
          {/* <Box sx={{ display: 'flex', my: 2 }}>
            <Typography variant="body1" sx={{ flex: 'auto' }} >Taxes</Typography>
            <Typography variant="body1" align="right">Rs 0</Typography>
          </Box> */}
          {/* {!discount &&
            <Typography align="right">
              <Typography variant="caption" color="primary"
                onClick={() => { setDiscount(true) }}
                sx={{ cursor: 'pointer' }}
              >
                I have a promo code
              </Typography>
            </Typography>
          }
          {discount &&
            <Box sx={{ display: 'flex' }}>
              <TextField
                label="Enter a promo code"
                size="small"
                sx={{ flex: 'auto', mr: 1 }}
                InputLabelProps={{
                  style: { fontSize: 14 }
                }}
              />
              <Button variant="contained" size="small">Apply</Button>
            </Box>
          } */}
          <Divider sx={{ marginY: 2 }} />
          <Box sx={{ display: 'flex' }}>
            <Typography variant="h6" sx={{ flex: 'auto', fontWeight: '700' }} >Total</Typography>
            <Typography variant="h6" align="right" sx={{ fontWeight: '700' }} >
              Rs {(subTotal - givenDiscount + deliveryCharges)}
            </Typography>
          </Box>
        </Fragment> :
        <Box sx={{ display: 'flex', my: 2 }}>
          <Typography variant="body1" sx={{ flex: 'auto' }} >Total</Typography>
          <Typography variant="body1" align="right">Rs {subTotal - givenDiscount}</Typography>
        </Box>
      }

      {pathname === '/cart' &&
        <Button variant="contained"
          component={Link}
          to={customer ? "/checkout" : "/session"}
          sx={{ width: '100%', mt: 2 }}
        >
          Checkout
        </Button>
      }

    </Box>
  );
}

export default OrderSummary;