import React, { useEffect, useState } from 'react';
import { Chip, Container, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HiOutlineEye } from 'react-icons/hi';

import colors from 'app/style/colors';
import { Service } from 'app/config/service';
import { getDate } from 'app/utils';

// *Import Components
import CustomerSideNav from 'app/components/SideNav/CustomerSideNav';
import CustomerOrderDetail from 'app/components/Dialog/CustomerOrderDetail';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: "bold"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function CustomerOrder() {

  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  // *For Order
  const [order, setOrder] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});

  const handleClickOpen = (saleDetail, delivery, total, discountAmount) => {
    let data = {
      saleDetail: saleDetail,
      delivery: delivery,
      total: total,
      discountAmount: discountAmount,
    }
    setOpen(true);
    setSelectedOrder(data)
  };

  const handleClose = () => {
    setOpen(false);
  };

  // *For Get Order
  const getOrder = async () => {
    try {
      const token = localStorage.getItem('customerToken')
      const { Data } = await Service.getCustomerOrder(token)
      setOrder(Data)
    } catch (error) {
      console.log('file: CustomerOrder.js => line 56 => getOrder => error', error)
    }
  };

  useEffect(() => {
    getOrder()
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container sx={{ my: 3 }}>

      {/* ========== Modals ========== */}
      <CustomerOrderDetail orderDetail={selectedOrder} show={open} handleClose={handleClose} />

      <Grid container spacing={4}>
        <Grid item xs={12} sm={4} md={3}>
          <CustomerSideNav />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <Typography variant={'h5'} sx={{ my: 2, fontWeight: "bold", color: colors.primary }}>Orders</Typography>
          <Divider sx={{ mb: 2.5, mt: 1 }} />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {/* <StyledTableCell>Product Name</StyledTableCell> */}
                  <StyledTableCell>Order Id</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.map((item, index) => (
                  <StyledTableRow key={index}>
                    {/* <StyledTableCell sx={{ py: 0.5 }} component="th" scope="row">{item?.SaleDetails?.ItemName}</StyledTableCell> */}
                    <StyledTableCell sx={{ py: 0.5, width: 100 }} >{item.OrderID}</StyledTableCell>
                    <StyledTableCell sx={{ py: 0.5, width: 100 }} >{item.TotalPaid}</StyledTableCell>
                    <StyledTableCell sx={{ py: 0.5, width: 130 }} >{getDate(item.SalesDate)}</StyledTableCell>
                    <StyledTableCell sx={{ py: 0.5, width: 180 }} >
                      <Chip
                        label={item.OrderStatus}
                        sx={{
                          bgcolor:
                            item.OrderStatus === "Pending"
                              ? colors.primary
                              : item.OrderStatus === "Delivered"
                                ? colors.darkGreen
                                : item.OrderStatus === "In Progress"
                                  ? colors.primaryMedium
                                  : colors.ratingYellow,
                          color: colors.white,
                          height: "22px"
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell sx={{ py: 0.5, width: "100px" }}  >
                      <Tooltip title="Order Detail">
                        <IconButton
                          onClick={() => handleClickOpen(item?.SaleDetails, item.DeliveryCharges, item.TotalPaid, item.DiscountAmount)}
                        >
                          <HiOutlineEye size={22} style={{ color: colors.secondary, marginRight: 5 }} />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>
      </Grid>
    </Container>
  )
}

export default CustomerOrder