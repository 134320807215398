import React from 'react';
import { FormControl, InputLabel, OutlinedInput, TextField } from '@mui/material';


import { Error } from './Error';

export function InputField({ label, error, register, defaultValue, size, onChange, value, width, type }) {
  return (
    <FormControl variant="standard" fullWidth sx={{ my: 1.2, width: width }} >
      <TextField
        size={size}
        variant="outlined"
        label={label}
        error={error && (true)}
        defaultValue={defaultValue}
        value={value}
        InputLabelProps={{ shrink: { defaultValue } ? true : false }}
        type={type}
        {...register}
      // onChange={onChange}
      />
      {error && (
        <Error message={error} />
      )}

    </FormControl>
  )
}

export function OutlineInputField({ label, error, register, endAdornment, updatedValue }) {
  return (
    <FormControl fullWidth sx={{ marginY: '10px' }}>
      <InputLabel error={error && error}>{label}</InputLabel>
      <OutlinedInput
        endAdornment={endAdornment}
        label={label}
        variant="outlined"
        error={error && (true)}
        {...register}
        value={updatedValue}
      />
      {error && (
        <Error message={error} />
      )}
    </FormControl>
  )
}

