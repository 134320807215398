import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Box, Button, DialogTitle, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useForm } from 'react-hook-form';

// *Import Components
import { Error } from '../UI/Error';
import { Service } from 'app/config/service';
import { SuccessToaster } from '../UI/Toaster';

const options = ['Pending', 'In Progress', "Dispatched"];

function UpdateStatus(props) {

  const vendorToken = localStorage.getItem("vendorToken")

  const { orderStatus, show, handleClose, orderDetail, getOrder } = props

  // *For Form Validation
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [allStatus, setAllStatus] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState("");

  const getOrderStatus = async () => {
    try {
      const { Data } = await Service.orderStatus()
      setAllStatus(Data)
    } catch (error) {
      console.log('file: UpdateStatus.js:22 => changeStatus => error', error);
    }
  };

  const changeOrderStatus = async () => {
    try {
      let obj = {
        BusinessTypeID: 1,
        OrderID: orderDetail?.OrderID,
        StatusID: selectedStatusId
      }
      const { Status, Message } = await Service.changeOrderStatus(obj, vendorToken)
      if (Status) {
        handleClose()
        SuccessToaster(Message)
        getOrder()
      }
    } catch (error) {
      console.log('file: UpdateStatus.js:34 => changeOrderStatus => error', error);
    }
  };

  useEffect(() => {
    getOrderStatus()
  }, [])


  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={show}
    >
      <DialogTitle>Change Order Status</DialogTitle>
      <Box component="form" onSubmit={handleSubmit(changeOrderStatus)}>
        <DialogContent dividers sx={{ overflow: 'hidden' }}>
          <FormControl sx={{ width: '100%', my: 0, ml: 1 }} >
            <RadioGroup aria-label="Change Status" defaultValue={orderStatus} >
              {/* <FormControlLabel
                value={orderStatus}
                control={<Radio />}
                label={orderStatus}
                {...register("status", {
                  required: 'Please select status'
                })}
              /> */}
              {allStatus.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option?.Name}
                  control={<Radio />}
                  label={option?.Name}
                  {...register("status")}
                  onChange={() => setSelectedStatusId(option?.Id)}
                />
              ))}
            </RadioGroup>
            {/* {errors?.status?.message && (
              <Error message={errors?.status?.message} />
            )} */}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' type='submit'>Ok</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default UpdateStatus
