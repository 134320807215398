import { LoadingButton } from '@mui/lab';
import { Box, Container, FormControl, TextField, Typography } from '@mui/material'
import { Error } from 'app/components/UI/Error';
import colors from 'app/style/colors'
import Images from 'assets/Images'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Service } from 'app/config/service';
import { emailRegex } from 'app/utils';
import useAuth from 'app/hooks/useAuth';
import { SuccessToaster } from 'app/components/UI/Toaster';
import { InputField } from 'app/components/UI/TextField';
import ResetPassword from 'app/components/Dialog/ResetPassword';

function SalesPersonLogin() {

  const { register, formState: { errors }, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  let [openModel,setOpen]=useState(false)

  const { getSalePersonToken } = useAuth()
  const navigate = useNavigate()

  const userDetail = async (data) => {
    setLoading(true)
    try {
      let obj = {
        username: data.email + '-0', // *-0 For Sale person
        password: data.password,
        grant_type: 'password',
        BusinessTypeID: 1,
      }
      const { access_token, userName, AccountID } = await Service.login(obj);
      if (userName && access_token && AccountID) {
        SuccessToaster('Login Successfully!')

        localStorage.setItem('salePersonToken', access_token)
        localStorage.setItem('salePersonId', AccountID)
        localStorage.setItem('salePersonName', userName)
        getSalePersonToken(access_token)
        navigate('/salesperson/add-product')
      }
    } catch (error) {
      console.log('file: SalesPersonLogin.js => line 19 => userDetail => error', error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);



  let open=()=>
  {
    setOpen(!openModel)
  }
  

  return (




    <Container sx={{ py: 10 }}>

{/* Reset Password ------------------------> Sales Person */}
<ResetPassword show={openModel} onClose={open} userType="-0" />




      <Box sx={{ boxShadow: "0px 8px 45px rgb(3 0 71 / 9%)", width: { xs: "100%", md: "40%" }, p: 2, mx: "auto", borderRadius: "10px" }}>
        <Box sx={{ width: { xs: "100%", md: "100%" }, mx: "auto" }}>
          <Box sx={{ textAlign: "center" }}>
            <img src={Images.logo} alt="Partz Buddy" />
          </Box>
          <Typography variant="h6" sx={{ color: colors.primary, textAlign: "center", fontWeight: "bold", mt: 2 }}>Login as a Sales Person</Typography>
        </Box>

        <Typography component="form" onSubmit={handleSubmit(userDetail)} sx={{ my: 1.5, mb: 1, width: { xs: "100%", md: "100%" }, mx: "auto" }} >
          <InputField
            size="medium"
            label="Email"
            error={errors?.email?.message}
            register={register("email", {
              required: 'Please enter email.',
              pattern: {
                value: emailRegex,
                message: 'Please enter a valid email address',
              }
            })}
          />
          {/* <FormControl variant="standard" fullWidth>
            <TextField
              size="medium"
              variant="outlined"
              label="Email"
              sx={{ my: 1 }}
              error={errors?.email?.message && (true)}
              {...register("email", {
                required: 'Please enter email.',
                pattern: {
                  value: emailRegex,
                  message: 'Please enter a valid email address',
                }
              })}
            />
            {errors?.email?.message && (
              <Error message={errors?.email?.message} />
            )}
          </FormControl> */}
          <InputField
            size="medium"
            label="Password"
            type="password"
            error={errors?.password?.message}
            register={register("password", {
              required: 'Please enter your password.',
            })}
          />
          {/* <FormControl variant="standard" fullWidth>
            <TextField
              size="medium"
              variant="outlined"
              label="Password"
              type="password"
              sx={{ my: 1 }}
              error={errors?.password?.message && (true)}
              {...register("password", {
                required: 'Please enter your password.',
              })}
            />
            {errors?.password?.message && (
              <Error message={errors?.password?.message} />
            )}
          </FormControl> */}
          <LoadingButton type="submit" loading={loading} fullWidth variant="contained" sx={{ p: 1, my: 2, }} >
            Login
          </LoadingButton>
          {/* Forget password */}
          <Box>
            <Typography color="#F14662" onClick={()=>{open()}} textAlign={"right"} sx={{cursor:'pointer'}}>forget your password</Typography>
          </Box>
        </Typography>
      </Box>

    </Container>
  )
}

export default SalesPersonLogin