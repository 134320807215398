import React from 'react'
import { Grid, Typography, Container, Divider, Box, Toolbar } from '@mui/material'

import colors from 'app/style/colors'

// *Import Component
import { ProductNotFound } from 'app/components/NotFound/NotFound'
import VendorSideNav from 'app/components/SideNav/VendorSideNav'

function Promotions() {
  return (
    <Container sx={{ my: 3 }}>
      <Grid container columnSpacing={4}>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "100%", height: 40, display: { xs: "block", md: "none" } }}
        >
          <Toolbar />
        </Box>
        <Grid item xs={12} md={3}>
          <VendorSideNav />
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant={'h4'} sx={{ my: 2, fontWeight: "bold", color: colors.primary }}>My Promotions</Typography>
          <Divider sx={{ mb: 2.5, mt: 1 }} />
          <ProductNotFound error="No Promotions Found" />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Promotions