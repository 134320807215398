import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Geocode from "react-geocode";
import { FaMapMarkerAlt } from 'react-icons/fa';

import colors from 'app/style/colors';
import { Service } from 'app/config/service';

// *Import Component
import { Error } from 'app/components/UI/Error';
import MapModal from 'app/components/Dialog/MapDialogBox';
import VerifyOtp from 'app/components/Dialog/VerifyOtp';
import { SecondaryLoadButton } from 'app/components/UI/Button';
import { ErrorToaster, SuccessToaster } from 'app/components/UI/Toaster';
import { InputField, OutlineInputField } from 'app/components/UI/TextField';

function VendorRegister(props) {

  const { onChange, onClose } = props

  // make the env later
  Geocode.setApiKey("AIzaSyBJ3IVlUfKfOT9ra-80Fex_t_hM5mfigro");

  const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false);
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [otp, setOtp] = useState();
  // *For Vendor Register Data
  const [vendorData, setVendorData] = useState({});
  const [currentLocation, setCurrentLocation] = useState({ latitude: 0, longitude: 0 })
  const [updatedAddress, setUpdatedAddress] = useState("")

  // *For Resend OTP
  const reSendOtp = async () => {
    try {
      const generateOtp = Math.floor(100000 + Math.random() * 900000)
      const { responseCode, message } = await Service.sendOtp(generateOtp, vendorData.Cell);
      if (responseCode) {
        SuccessToaster(message)
        setOtp(generateOtp)
      }
    } catch (error) {
      console.log('file: Register.js => line 47 => reSendOtp => error', error);
    }
  }

  // *For Send Otp
  const sendOtp = async (data) => {
    console.log(data)
    setLoading(true)
    try {
      const otp = Math.floor(100000 + Math.random() * 900000)
      let obj = {
        EmailPh: data.phone,
        Name: data.shopName,
        Password: data.password,
        ConfirmPassword: data.confirmPassword,
        Cell: data.phone,
        BusinessTypeID: 1,
        UserTypeID: 1, //* 1 For Vendor
        CountryCodes: "PK",
        Latitude: currentLocation.latitude,
        Longitude: currentLocation.longitude,
        // AddressTitle: "",
        GoogleAddress: updatedAddress,

      }

      setVendorData(obj)

      const { responseCode, message } = await Service.sendOtp(otp, data.phone);
      if (responseCode) {
        SuccessToaster(message)
        setOtp(otp)
        setOpenDialog(false)
        setOpenVerifyModal(true)
      }

      // onClose()
      // reset()

      // const { Message, ResponseCode, Status, Data } = await Service.register(obj);
      // if (Status === true && ResponseCode === 200) {
      //   SuccessToaster(Message)
      //   reset()
      //   onClose()
      // } else if (Status === false && ResponseCode === 400) {
      //   ErrorToaster(Data)
      // }
    } catch (error) {
      ErrorToaster('Something went wrong')
    } finally {
      setLoading(false)
    }
  };

  // *For Vendor Register
  const vendorRegister = async () => {
    try {
      const { Message, ResponseCode, Status } = await Service.register(vendorData);
      if (Status === true && ResponseCode === 200) {
        SuccessToaster(Message)
        reset()
        setOpenVerifyModal(false)
        onClose()
      } else {
        ErrorToaster(Message)
      }
    } catch (error) {
      console.log('file: Register.js => line 121 => vendorRegister => error', error)
    }
  }

  const changeLocation = (val) => {
    const latitude = val.lat()
    const longitude = val.lng()
    setCurrentLocation({ latitude, longitude })
  }

  const geoLocation = () => {
    Geocode.fromLatLng(currentLocation?.latitude, currentLocation?.longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setUpdatedAddress(address)
        setValue("address", address)
      },
      (error) => {
        console.error(error);
      }
    );
  }

  const openMapDialog = () => {
    setOpenDialog(true)
    navigator.geolocation.getCurrentPosition((location) => {
      const latitude = location.coords.latitude
      const longitude = location.coords.longitude
      setCurrentLocation({ latitude, longitude })
    })
  }

  useEffect(() => {
    geoLocation()
  }, [currentLocation])

  return (
    <Fragment>

      {/* ===== Map Modal ===== */}
      <MapModal currentLocation={currentLocation} open={openDialog} locationChange={changeLocation} onClose={() => setOpenDialog(!openDialog)} />

      {/* ===== Verify OTP Modal ===== */}
      <VerifyOtp open={openVerifyModal} reSendOtp={() => reSendOtp()} otp={otp} register={vendorRegister} />

      <Typography sx={{ mt: 2 }} component="form" onSubmit={handleSubmit(sendOtp)}>
        <InputField
          size="medium"
          name="email"
          type="email"
          label="Email ID"
          error={errors?.email?.message}
          register={register("email", {
            required: 'Please enter your email.',
          })}
        />
        <InputField
          size="medium"
          label="Name / Shop Name"
          error={errors?.shopName?.message}
          register={register("shopName", {
            required: 'Please enter shop name.',
          })}
        />
        <InputField
          size="medium"
          label="Phone Number"
          error={errors?.phone?.message}
          register={register("phone", {
            required: 'Please enter phone number.',
          })}
        />
        <OutlineInputField
          label={"Address"}
          updatedValue={updatedAddress}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                edge="end"
              >
                <FaMapMarkerAlt onClick={() => openMapDialog()} />
              </IconButton>
            </InputAdornment>
          }
          error={errors?.address?.message}
          register={register("address", {
            onChange: (e) => setUpdatedAddress(e.target.value),
            required: 'Please enter address.',
          })
          }
        />
        <InputField
          size="medium"
          label="Password"
          type="password"
          error={errors?.password?.message}
          register={register("password", {
            required: 'Please enter password.',
            minLength: {
              value: 6,
              message: "Password must have at least 6 characters"
            }
          })}
        />

        <InputField
          size="medium"
          label="Confirm Password"
          type="password"
          error={errors?.confirmPassword?.message}
          register={register("confirmPassword", {
            required: 'Confirm Password is required',
            validate: value =>
              value === password.current || "Confirm password does not match"
          })}
        />
        <Typography sx={{ color: colors.darkGray, textAlign: { xs: "center", sm: "right" }, mt: 2, fontSize: "14px" }}>
          Already have an account?
          <Typography color="secondary" component="span" sx={{ cursor: "pointer", fontSize: "14px" }} onClick={() => onChange()} >&nbsp; Login</Typography>
        </Typography>
        <SecondaryLoadButton fullWidth loading={loading} type='submit' variant="contained">Register</SecondaryLoadButton>

      </Typography>

    </Fragment>
  )
}

export default VendorRegister