import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  Divider,
  Box,
  Toolbar,
  TextField,
  Button,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import colors from "app/style/colors";
import { Service } from "app/config/service";

// *Import Component
import StoreCard from "app/components/Cards/StoreCard";
import { ProductNotFound } from "app/components/NotFound/NotFound";
import VendorSideNav from "app/components/SideNav/VendorSideNav";
import { Loading } from "app/components/UI/Loader";
import { LoadingButton } from "@mui/lab";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { textAlign } from "@mui/system";
import { useForm } from "react-hook-form";

function Products() {

  const { register, handleSubmit } = useForm();
  const { pathname } = useLocation();

  const [productList, setProductList] = useState([]);
  let [MainLoading, setMainLoading] = useState(false);
  let [loading, setLoading] = useState(false);
  let [pageNo, setPageNo] = useState(2);
  let [LengthChk, setLengthChk] = useState(0)
  const accountId = localStorage.getItem("vendorId");

  // *Get products Api
  const getProduct = async (pageNum, status) => {

    try {
      if (pageNum === 1) {
        setMainLoading(true);
      }
      else {
        setLoading(true);
      }

      if (status === true) {
        setPageNo(2)
      }
      // setPageNo((prev)=>{ return (prev+PGnum)})

      const { Data, Status, ResponseCode } = await Service.getVendorProduct(accountId, status ? 1 : pageNo);

      setLengthChk(Data[0].ItemArray.length)
      if (Status === true && ResponseCode === 200) {
        if (status) {
          setProductList(Data[0]?.ItemArray)
        }
        else {
          setProductList([...productList, ...Data[0]?.ItemArray]);
        }

      } else if (Status === false && ResponseCode === 404) {

        setProductList([]);
      }
    } catch (error) {
      console.log("🚀 ~ file: Products.js:64 ~ getProduct ~ error", error)
    } finally {
      setMainLoading(false);
      setLoading(false);
    }
  };

  const searchProduct = async (data) => {
    try {
      const { Data } = await Service.getVendorProduct(accountId, 1, data.search);
      if (Data) {
        setProductList(Data[0]?.ItemArray);
      }
      else {
        setProductList([]);
      }
    } catch (error) {
      console.log('file: Products.js:85 => searchProduct => error', error);
    }
  }

  useEffect(() => {
    getProduct(1, true);
    window.scrollTo({ top: 0 });
  }, []);


  return (
    <>
      <Container
        sx={{ my: 3 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Grid container columnSpacing={4}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: "100%",
              height: 40,
              display: { xs: "block", md: "none" },
            }}
          >
            <Toolbar />
          </Box>
          <Grid item xs={12} md={3}>
            <VendorSideNav />
          </Grid>

          {MainLoading ? (

            <Grid md="9">
              <Loading sx={{ width: "100%", textAlign: 'center' }} />
            </Grid>


          ) : (
            <Grid item xs={12} md={9}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography
                  variant={"h4"}
                  sx={{ my: 2, fontWeight: "bold", color: colors.primary }}
                >
                  My Products
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit(searchProduct)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "300px",
                    "& .MuiFormControl-root": { width: "100%" },
                  }}
                >
                  <TextField
                    placeholder="Search Product"
                    id="input-with-sx"
                    variant="standard"
                    name="search"

                    {...register('search')}
                  />
                  <Button
                    type="submit"
                    variant="text"
                    sx={{ "& .MuiInput-root": { p: "0" } }}
                  >
                    <SearchOutlinedIcon sx={{ color: "red" }} />
                  </Button>
                </Box>
              </Box>
              <Divider sx={{ mb: 2.5, mt: 1 }} />
              {productList.length ? (
                <Box sx={{ my: 2 }}>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    {productList.map((item, index) => (
                      <Grid key={index} item md={3}>
                        <StoreCard
                          path={pathname}
                          product={item}
                          getProduct={getProduct}
                        />
                      </Grid>
                    ))}
                    <Grid
                      item
                      xs="12"
                      display={"flex"}
                      justifyContent="center"
                      mt="20px"
                    >
                      {
                        LengthChk === 20 &&
                        <LoadingButton
                          loading={loading}
                          variant="contained"
                          sx={{
                            bgcolor: colors.secondary,
                            "&:hover": { bgcolor: colors.secondaryDark },
                          }}
                          onClick={() => {
                            getProduct(pageNo, false);
                            setPageNo(prev => prev + 1);

                          }}
                        >
                          Load More
                        </LoadingButton>
                      }
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <ProductNotFound error="No Product Found" />
              )}
            </Grid>

          )}
        </Grid>
      </Container>
    </>
  );
}

export default Products;
