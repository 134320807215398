import React from 'react';
import { makeStyles } from '@mui/styles';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import "swiper/css";
import "swiper/css/pagination"

import { RemoveUrlSpace } from 'app/utils';

const useStyles = makeStyles(theme => ({
  slider: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: "420px",
    width: '100%',
    [theme.breakpoints.down('600')]: {
      backgroundSize: 'contain',
      height: "150px",
    },
    [theme.breakpoints.up('600')]: {
      backgroundSize: 'contain',
      height: "280px",
    },
    [theme.breakpoints.up('800')]: {
      backgroundSize: 'cover',
      height: "420px",
    }
  },

}));

function Banner(props) {

  const { banner } = props;
  SwiperCore.use([Autoplay, Pagination]);

  const classes = useStyles();

  return (
    <Swiper
      className="mySwiper banner"
      loop={true}
      speed={1000}
      pagination={{
        "dynamicBullets": true,
        "clickable": true,
      }}
      autoplay={{
        "delay": 2500,
        "disableOnInteraction": false,
      }}
    >
      {banner.map((item, index) => (
        <SwiperSlide
          key={index}
          className={classes.slider}
          style={{
            backgroundImage: `url(${RemoveUrlSpace(item.ImageUrl)})`,
          }}
        >
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Banner; 