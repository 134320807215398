import React from 'react';
import { Box, Grid, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function CategoryCard(props) {

  const { category } = props;
  const navigate = useNavigate();

  return (
    <Box sx={{ p: 2, cursor: 'pointer' }}>
      <Grid container spacing={0.5} alignItems="center" onClick={() => navigate(`/category/${category.CategoryName}/${category.CategoryID}`)}>
        <Grid item md={5}>
          <CardMedia image={category.ImageUrl} sx={{ width: 70, height: 70, borderRadius: '50%' }} />
        </Grid>
        <Grid item md={7}>
          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{category.CategoryName}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CategoryCard;