import React, { useEffect, useState } from 'react';
import { Typography, Container, Divider, Grid, FormControl, TextField, InputLabel, OutlinedInput, Select, MenuItem, Box, Button, Toolbar } from '@mui/material';
import { Percent } from '@mui/icons-material';
import { Controller } from "react-hook-form";
import { useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


import colors from 'app/style/colors';
import { Service } from 'app/config/service';

// *Import Component
import SideNav from 'app/components/SideNav/VendorSideNav';
import imageCompress from 'app/hooks/imageCompress';
import { LoadingButton } from '@mui/lab';
import { SuccessToaster } from 'app/components/UI/Toaster';
import { AiFillDelete } from 'react-icons/ai';

const useStyles = makeStyles({
  image: {
    borderRadius: 2,
    objectFit: "contain"
  }
});

function QuickAddProduct() {

  const classes = useStyles()

  const navigate = useNavigate()

  const { register, handleSubmit, formState: { errors }, control, setValue } = useForm();

  const [loading, setLoading] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [vehiclesList, setVehiclesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [vehicles, setVehicles] = useState('');
  const [brand, setBrand] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [selectedCoverImage, setSelectedCoverImage] = useState("");
  const [selectedImage1, setSelectedImage1] = useState("");
  const [selectedImage2, setSelectedImage2] = useState("");
  const [selectedImage3, setSelectedImage3] = useState("");
  const [selectedImage4, setSelectedImage4] = useState("");
  const [selectedImage5, setSelectedImage5] = useState("");
  const [discount, setDiscount] = useState('');
  const [discountedPrice, setDiscountPrice] = useState('');
  const [productPrice, setProductPrice] = useState('');

  // *For Set Category
  const handleCategory = (event) => {
    setSelectedCategory(event.target.value);
  };

  // *For Set Vehicle
  const handleVehicle = (event) => {
    setVehicles(event.target.value);
  };

  // *For Set Brand
  const handleBrand = (event) => {
    setBrand(event.target.value);
  };

  // *For Set Manufacturer
  const handleManufacturer = (event) => {
    setManufacturer(event.target.value);
  };


  // *For Calculate Discount
  const calculateDiscount = (val) => {
    setDiscount(val)
    const discounted_price = productPrice - (productPrice * val / 100)
    setDiscountPrice(Math.round(discounted_price))
  }

  // *For Cover Image
  const imageChange = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const Data = await imageCompress(e)
        setSelectedCoverImage(Data);
      }
    } catch (error) {
      console.log('file: QuickAddProduct.js => line 88 => imageChange => error', error);
    }
  };

  // *For multiple Image
  const imageChange1 = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const Data = await imageCompress(e)
        setSelectedImage1(Data);
      }
    } catch (error) {
      console.log('file: QuickAddProduct.js => line 102 => imageChange1 => error', error);
    }
  };

  const imageChange2 = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const Data = await imageCompress(e)
        setSelectedImage2(Data);
      }
    } catch (error) {
      console.log('file: QuickAddProduct.js => line 114 => imageChange2 => error', error);
    }
  };

  const imageChange3 = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const Data = await imageCompress(e)
        setSelectedImage3(Data);
      }
    } catch (error) {
      console.log('file: QuickAddProduct.js => line 126 => imageChange3 => error', error);
    }
  };

  const imageChange4 = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const Data = await imageCompress(e)
        setSelectedImage4(Data);
      }
    } catch (error) {
      console.log('file: QuickAddProduct.js => line 138 => imageChange4 => error', error);
    }
  };

  const imageChange5 = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const Data = await imageCompress(e)
        setSelectedImage5(Data);
      }
    } catch (error) {
      console.log('file: QuickAddProduct.js => line 150 => imageChange5 => error', error);
    }
  };

  // *For Product Detail
  const productDetail = async (form) => {
    setLoading(true)
    try {
      let obj = [
        { QID: 1, Value: form.productName },
        { QID: 2, Value: manufacturer },
        { QID: 3, Value: Number(productPrice) },
        { QID: 4, Value: Number(discount) },
        { QID: 5, Value: Number(discountedPrice) },
        { QID: 6, Value: form.description },
        { QID: 8, Value: vehicles },
        { QID: 12, Value: brand },
        { QID: 11, Value: selectedCategory },
        { QID: 14, Value: selectedCoverImage },
        { QID: 14, Value: selectedImage1 },
        { QID: 14, Value: selectedImage2 },
        { QID: 14, Value: selectedImage3 },
        { QID: 14, Value: selectedImage4 },
        { QID: 14, Value: selectedImage5 },
      ]

      let updatedQuestionIndex = 0
      var questionDetail = {};

      for (const questionIndex in obj) {
        const { Value, QID } = obj[questionIndex];
        if (Value) {
          questionDetail['data[' + updatedQuestionIndex + '].QID'] = QID;
          questionDetail['data[' + updatedQuestionIndex + '].Value'] = Value;
          updatedQuestionIndex++;
        }
      }

      const formData = {
        ...questionDetail,
        BusinessTypeID: 1
      }
      const token = localStorage.getItem('vendorToken')
      const { Status, ResponseCode } = await Service.addProduct(formData, token)
      if (Status === true && ResponseCode === 200) {
        setManufacturer('')
        setBrand('')
        setVehicles('')
        setSelectedCategory('')
        setSelectedCoverImage('')
        setSelectedImage1('')
        setSelectedImage2('')
        setSelectedImage3('')
        setSelectedImage4('')
        setSelectedImage5('')
        setDiscount('')
        setDiscountPrice('')
        setProductPrice('')
        setValue('productName', '')
        setValue('description', '')
        setLoading(false)
        SuccessToaster('Product Added Successfully!')
      }

    } catch (error) {
      console.log('file: QuickAddProduct.js => line 44 => productDetail => error', error);
    }
  };

  // *Get Category Api
  const getCategory = async () => {
    const { Data } = await Service.getCategory()

    setCategoryList(Data)
  };

  // *Get Brands Api
  const getBrandsAP = async () => {
    const { Data } = await Service.getBrands()

    setBrandList(Data)
  };

  // *Get Manufacturer Api
  const getManufacturer = async () => {
    const { Data } = await Service.getManufacturers()
    setManufacturerList(Data)
  }

  // *Get Vehicles Api
  const getVehicles = async () => {
    const { Data } = await Service.getVehicles()
    setVehiclesList(Data)
  }

  useEffect(() => {
    getCategory()
    getBrandsAP()
    getManufacturer()
    getVehicles()
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container sx={{ my: 3 }}>
      <Grid container columnSpacing={4}>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "100%", height: 40, display: { xs: "block", md: "none" } }}
        >
          <Toolbar />
        </Box>
        <Grid item xs={12} md={3}>
          <SideNav />
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant={'h4'} sx={{ my: 2, fontWeight: "bold", color: colors.primary }}>Quick Add Product</Typography>
          <Divider sx={{ mb: 2.5, mt: 1 }} />
          <Typography sx={{ mt: 2 }} component="form" onSubmit={handleSubmit(productDetail)}>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard" sx={{ marginY: '10px' }} >
                  <TextField
                    variant="outlined"
                    label="Product Name"
                    error={errors?.productName?.message && (true)}
                    sx={{ width: '100%' }}
                    {...register("productName", {
                      required: 'Please enter product name.',
                    })}
                  />
                  {errors?.productName?.message && (
                    <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.productName?.message}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant='standard' sx={{ marginY: '10px' }} >
                  <TextField
                    onChange={(e) => setProductPrice(e.target.value)}
                    value={productPrice}
                    inputProps={{ min: "1" }}
                    type='number'
                    variant="outlined"
                    label="Product Price"
                    error={errors?.productPrice?.message && (true)}
                    sx={{ width: '100%' }}
                    {...register("productPrice", {
                      onChange: (e) => setProductPrice(e.target.value),
                      required: 'Please enter product price.',
                    })}
                  />
                  {errors?.productPrice?.message && (
                    <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.productPrice?.message}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth sx={{ marginY: '10px' }}>
                  <InputLabel>Discount Percent</InputLabel>
                  <OutlinedInput
                    inputProps={{ min: "1" }}
                    startAdornment={<Percent position="start" sx={{ mr: 1 }}>$</Percent>}
                    label="Discount Percent"
                    sx={{ width: '100%' }}
                    {...register("discountVal")}
                    type='number'
                    onChange={(e) => calculateDiscount(e.target.value)}
                    value={discount}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="standard" sx={{ marginY: '10px' }} >
                  <TextField
                    disabled
                    variant="outlined"
                    label="Discount Amount"
                    sx={{ width: '100%' }}
                    // {...register("discountVal")}
                    value={discountedPrice}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth sx={{ marginY: '10px' }}>
                  <InputLabel>Category</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Category"
                        value={selectedCategory}
                        error={errors?.selectedCategory?.message && (true)}
                        onChange={handleCategory}
                      >
                        {categoryList.map((val, index) => (
                          <MenuItem key={index} value={val.CategoryID}>{val.CategoryName}</MenuItem>
                        ))}

                      </Select>
                    )}
                    name="selectedCategory"
                    control={control}
                    error={errors?.selectedCategory?.message && (true)}
                  // {...register("vehicleName", {
                  //   required: 'Please select vehicle.',
                  // })}
                  />
                  {errors?.selectedCategory?.message && (
                    <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.selectedCategory?.message}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth sx={{ marginY: '10px' }}>
                  <InputLabel>Vehicles</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Vehicles"
                        value={vehicles}
                        error={errors?.vehicleName?.message && (true)}
                        onChange={handleVehicle}
                      >
                        {vehiclesList.map((val, index) => (
                          <MenuItem key={index} value={val.ID}>{val.Value}</MenuItem>
                        ))}
                      </Select>
                    )}
                    name="vehicleName"
                    control={control}
                    error={errors?.vehicleName?.message && (true)}
                  // {...register("vehicleName", {
                  //   required: 'Please select vehicle.',
                  // })}
                  />
                  {errors?.vehicleName?.message && (
                    <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.vehicleName?.message}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth sx={{ marginY: '10px' }}>
                  <InputLabel>Brand</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Brand"
                        value={brand}
                        error={errors?.vehicleBrand?.message && (true)}
                        onChange={handleBrand}
                      >
                        {brandList.map((val, index) => (
                          <MenuItem key={index} value={val.ID}>{val.Value}</MenuItem>
                        ))}
                      </Select>
                    )}
                    name="vehicleBrand"
                    control={control}
                    error={errors?.vehicleBrand?.message && (true)}
                  // {...register("vehicleBrand", {
                  //   required: 'Please select brand.',
                  // })}
                  />
                  {errors?.vehicleBrand?.message && (
                    <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.vehicleBrand?.message}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth sx={{ marginY: '10px' }}>
                  <InputLabel>Manufacturer</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        onChange={handleManufacturer}
                        label="Manufacturer"
                        value={manufacturer}
                        error={errors?.vehicleManufacturer?.message && (true)}
                      >
                        {manufacturerList.map((val, index) => (
                          <MenuItem key={index} value={val.ID}>{val.Value}</MenuItem>
                        ))}
                      </Select>
                    )}
                    name="vehicleManufacturer"
                    control={control}
                    error={errors?.vehicleManufacturer?.message && (true)}
                  // {...register("vehicleManufacturer", {
                  //   required: 'Please select manufacturer.',
                  // })}
                  />
                  {errors?.vehicleManufacturer?.message && (
                    <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.vehicleManufacturer?.message}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard" sx={{ marginY: '10px' }} >
                  <TextField
                    multiline
                    rows={4}
                    variant="outlined"
                    label="Description"
                    sx={{ width: '100%' }}
                    error={errors?.description?.message && (true)}
                    {...register("description", {
                      required: 'Please enter product description.',
                    })}
                  />
                  {errors?.description?.message && (
                    <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.description?.message}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography component='div' variant='h4' sx={{ mt: 2, color: colors.primary }} >Add Prodcut Images</Typography>
              </Grid>
              <Grid item xs={12} sm={2} sx={{ mt: 2 }}>
                <Box sx={{ position: "relative" }}>
                  <label style={{ overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`, height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}
                    htmlFor='file-input'
                  >
                    {selectedCoverImage ? (
                      <Box sx={{ position: "relative" }}  >
                        <img
                          src={selectedCoverImage}
                          alt=""
                          width="300px"
                          height="200px"
                          className={classes.image}
                          onClick={imageChange}
                        />
                        <Typography component='div' sx={{ position: "absolute", top: 120, left: 100, color: colors.white, bgcolor: colors.secondary, width: "100px", textAlign: "center" }}>Cover</Typography>
                      </Box>
                    ) : (
                      <FaPlus
                        size={40}
                        style={{ color: colors.primary, cursor: "pointer" }}
                        onClick={imageChange}
                      />
                    )}
                  </label>
                  <input
                    style={{ display: "none" }}
                    id="file-input"
                    type="file"
                    // defaultValue={selectedCoverImage} //Add after delete image
                    error={errors?.selectedCoverImage?.message && (true)}
                    {...register("selectedCoverImage", {
                      required: 'Cover Image is required.',
                    })}
                    onChange={imageChange}
                    accept="image/x-png,image/jpeg"
                  />
                  {/* For Delete image  */}
                  {selectedCoverImage &&
                    <AiFillDelete onClick={() => setSelectedCoverImage('')} style={{ position: "absolute", top: -5, right: -5, fontSize: "20px", cursor: "pointer", backgroundColor: colors.primaryLight, color: "red", padding: "3px", borderRadius: "20px" }} />
                  }
                  {errors?.selectedCoverImage?.message && (
                    <Typography color="error" sx={{ margin: '0px', fontSize: '12px' }} >{errors?.selectedCoverImage?.message}</Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} sx={{ mt: 2 }}>
                <Box onDragOver={(event) => event.preventDefault()} sx={{ position: "relative" }}>
                  <label style={{ overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`, height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}
                    htmlFor='file-input1'>
                    {selectedImage1 ? (
                      <img
                        src={selectedImage1}
                        alt=""
                        width="300px"
                        height="200px"
                        className={classes.image}
                        onClick={imageChange1}
                        onDragStart={() => console.log(selectedImage1, "Dragging")}
                        draggable={true}
                      />
                    ) : (
                      <FaPlus
                        size={40}
                        style={{ color: colors.primary, cursor: "pointer" }}
                        onClick={imageChange1}
                      />
                    )}
                  </label>
                  <input
                    style={{ display: "none" }}
                    id="file-input1"
                    type="file"
                    accept="image/x-png,image/jpeg"
                    onChange={imageChange1}
                  />
                  {selectedImage1 &&
                    <AiFillDelete onClick={() => { setSelectedImage1(''); console.log(selectedImage1) }} style={{ position: "absolute", top: -5, right: -5, fontSize: "20px", cursor: "pointer", backgroundColor: colors.primaryLight, color: "red", padding: "3px", borderRadius: "20px" }} />
                  }
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} sx={{ mt: 2 }}>
                <Box onDragOver={(event) => event.preventDefault()} sx={{ position: "relative" }}>
                  <label style={{ overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`, height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}
                    htmlFor='file-input2'>
                    {selectedImage2 ? (
                      <img
                        src={selectedImage2}
                        alt=""
                        width="300px"
                        height="200px"
                        className={classes.image}
                        onClick={imageChange2}
                        onDragStart={() => console.log(selectedImage2, "Dragging")}
                        draggable={true}
                      />
                    ) : (
                      <FaPlus
                        size={40}
                        style={{ color: colors.primary, cursor: "pointer" }}
                        onClick={imageChange2}
                      />
                    )}
                  </label>
                  <input
                    style={{ display: "none" }}
                    id="file-input2"
                    type="file"
                    accept="image/x-png,image/jpeg"
                    onChange={imageChange2}
                  />
                  {selectedImage2 &&
                    <AiFillDelete onClick={() => setSelectedImage2('')} style={{ position: "absolute", top: -5, right: -5, fontSize: "20px", cursor: "pointer", backgroundColor: colors.primaryLight, color: "red", padding: "3px", borderRadius: "20px" }} />
                  }
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} sx={{ mt: 2 }}>
                <Box onDragOver={(event) => event.preventDefault()} sx={{ position: "relative" }}>
                  <label style={{ overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`, height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}
                    htmlFor='file-input3'
                  >
                    {selectedImage3 ? (
                      <img
                        src={selectedImage3}
                        alt=""
                        width="300px"
                        height="200px"
                        className={classes.image}
                        onClick={imageChange3}
                        onDragStart={() => console.log(selectedImage3, "Dragging")}
                        draggable={true}
                      />
                    ) : (
                      <FaPlus
                        size={40}
                        style={{ color: colors.primary, cursor: "pointer" }}
                        onClick={imageChange3}
                      />
                    )}
                  </label>
                  <input
                    style={{ display: "none" }}
                    id="file-input3"
                    type="file"
                    accept="image/x-png,image/jpeg"
                    onChange={imageChange3}
                  />
                  {selectedImage3 &&
                    <AiFillDelete onClick={() => setSelectedImage3('')} style={{ position: "absolute", top: -5, right: -5, fontSize: "20px", cursor: "pointer", backgroundColor: colors.primaryLight, color: "red", padding: "3px", borderRadius: "20px" }} />
                  }
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} sx={{ mt: 2 }}>
                <Box onDragOver={(event) => event.preventDefault()} sx={{ position: "relative" }}>
                  <label style={{ overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`, height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}
                    htmlFor='file-input4'>
                    {selectedImage4 ? (
                      <img
                        src={selectedImage4}
                        alt=""
                        width="300px"
                        height="200px"
                        className={classes.image}
                        onClick={imageChange4}
                        onDragStart={() => console.log(selectedImage4, "Dragging")}
                        draggable={true}
                      />
                    ) : (
                      <FaPlus
                        size={40}
                        style={{ color: colors.primary, cursor: "pointer" }}
                        onClick={imageChange4}
                      />
                    )}
                  </label>
                  <input
                    style={{ display: "none" }}
                    id="file-input4"
                    type="file"
                    accept="image/x-png,image/jpeg"
                    onChange={imageChange4}
                  />
                  {selectedImage4 &&
                    <AiFillDelete onClick={() => setSelectedImage4('')} style={{ position: "absolute", top: -5, right: -5, fontSize: "20px", cursor: "pointer", backgroundColor: colors.primaryLight, color: "red", padding: "3px", borderRadius: "20px" }} />
                  }
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} sx={{ mt: 2 }}>
                <Box onDragOver={(event) => event.preventDefault()} sx={{ position: "relative" }}>
                  <label style={{ overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`, height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}
                    htmlFor='file-input5'>
                    {selectedImage5 ? (
                      <img
                        src={selectedImage5}
                        alt=""
                        width="300px"
                        height="200px"
                        className={classes.image}
                        onClick={imageChange5}
                        onDragStart={() => console.log(selectedImage5, "Dragging")}
                        draggable={true}
                      />
                    ) : (
                      <FaPlus
                        size={40}
                        style={{ color: colors.primary, cursor: "pointer" }}
                        onClick={imageChange5}
                      />
                    )}
                  </label>
                  <input
                    style={{ display: "none" }}
                    id="file-input5"
                    type="file"
                    accept="image/x-png,image/jpeg"
                    onChange={imageChange5}
                  />
                  {selectedImage5 &&
                    <AiFillDelete onClick={() => setSelectedImage5('')} style={{ position: "absolute", top: -5, right: -5, fontSize: "20px", cursor: "pointer", backgroundColor: colors.primaryLight, color: "red", padding: "3px", borderRadius: "20px" }} />
                  }
                </Box>
              </Grid>
            </Grid>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              sx={{ mt: 2, float: "right" }}
            >
              Post Product
            </LoadingButton>
          </Typography>
        </Grid>
      </Grid>

    </Container>
  )
}

export default QuickAddProduct