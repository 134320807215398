import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AiOutlineRight } from 'react-icons/ai';

import colors from 'app/style/colors';
import { convertToUpperCase } from 'app/utils';

const useStyles = makeStyles({
  link: {
    fontSize: '16px',
    fontWeight: 500,
    color: colors.black,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: colors.secondary,
      transition: '0.3s ease-in-out',
    },
  },
});

function Breadcrumb() {

  const classes = useStyles();
  const { category, name } = useParams();

  return (
    <Breadcrumbs
      separator={<AiOutlineRight fontSize="small" sx={{ mx: 1 }} />}
      aria-label="breadcrumb"
      sx={{ mt: 3, mb: 2 }}
    >
      <Link to="/" className={classes.link} >
        Home
      </Link>
      {category &&
        <Link to="/" className={classes.link} >
          {convertToUpperCase(category)}
        </Link>
      }
      <Typography sx={{ fontWeight: 500 }}>
        {convertToUpperCase(name)}
      </Typography>
    </Breadcrumbs>
  );
}

export default Breadcrumb;