import React, { useState } from 'react';
import { Tab, Box, Dialog, DialogContent, Typography, FormControl, OutlinedInput, TextField } from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';

import Images from 'assets/Images';
import { useForm } from 'react-hook-form';
import { Error } from '../UI/Error';
import colors from 'app/style/colors';
import { useNavigate } from 'react-router-dom';




function SalesPersonLoginDialog(props) {

  const { show, onClose } = props
  const { register, formState: { errors }, handleSubmit } = useForm();

  const navigate = useNavigate()

  const userDetail = (data) => {
    try {
      console.log(data)
      if (data) navigate("/salesperson/add-product")
      onClose()
    } catch (error) {
      console.log('file: ResetPassword.js => line 20 => resetPassword => error', error);
    }
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '40%', height: "auto", borderRadius: "10px" } }}
      maxWidth="md"
      open={show}
      onClose={onClose}
    >
      <DialogContent sx={{ p: 4 }}>
        <Box sx={{ textAlign: "center" }}>
          <img src={Images.logo} alt="Partz Buddy" />
        </Box>
        <Typography variant="h6" sx={{ color: colors.primary, textAlign: "center", fontWeight: "bold", mt: 2 }}>Login as a Sales Person </Typography>
        <Typography component="form" onSubmit={handleSubmit(userDetail)} sx={{ my: 1.5, mb: 1 }} >
          <FormControl variant="standard" fullWidth>
            <TextField
              size="medium"
              variant="outlined"
              label="Phone Number"
              sx={{ my: 1 }}
              error={errors?.phone?.message && (true)}
              {...register("phone", {
                required: 'Please enter phone number.',
              })}
            />
            {errors?.phone?.message && (
              <Error message={errors?.phone?.message} />
            )}
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <TextField
              size="medium"
              variant="outlined"
              label="Password"
              type="password"
              sx={{ my: 1 }}
              error={errors?.password?.message && (true)}
              {...register("password", {
                required: 'Please enter your password.',
              })}
            />
            {errors?.password?.message && (
              <Error message={errors?.password?.message} />
            )}
          </FormControl>
          <LoadingButton type="submit" fullWidth variant="contained" sx={{ p: 1, my: 2, }} >
            Login 
          </LoadingButton>
         
        
       
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default SalesPersonLoginDialog
