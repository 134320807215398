import React from 'react'
import { Grid, Typography, Container, Divider, Box, Toolbar } from '@mui/material'
import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs'

import colors from 'app/style/colors'

// *Import Component
import { ProductNotFound } from 'app/components/NotFound/NotFound'
import SideNav from 'app/components/SideNav/VendorSideNav'

const reviews = [
  { buyerName: "Adeel", orderID: 1113, customerFeedBack: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
  { buyerName: "Haider", orderID: 2013, customerFeedBack: "" },
]

function Reviews() {
  return (
    <Container sx={{ my: 3 }}>
      <Grid container columnSpacing={4}>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "100%", height: 40, display: { xs: "block", md: "none" } }}
        >
          <Toolbar />
        </Box>
        <Grid item xs={12} md={3}>
          <SideNav />
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant={'h4'} sx={{ my: 2, fontWeight: "bold", color: colors.primary }}>My Reviews</Typography>
          <Divider sx={{ mb: 2.5, mt: 1 }} />
          {reviews.length ? (
            <Grid container spacing={2}>
              {reviews.map((reviewsData, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <Box sx={{ border: `1px solid ${colors.primary}`, p: 1, minHeight: "180px", borderRadius: "10px" }}>
                    <Box sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: `1px solid ${colors.primary}`
                    }}>
                      <Typography sx={{ fontWeight: "bold" }}>{reviewsData.buyerName}</Typography>
                      <Typography sx={{ fontWeight: "bold" }}>{`Order ID: ${reviewsData.orderID}`}</Typography>
                    </Box>
                    <Box sx={{ textAlign: "center", my: 2 }}>
                      <Typography variant='h6' sx={{ fontWeight: "bold" }}>Rate:</Typography>
                      <BsStarFill size={20} color={colors.secondary} />
                      <BsStarFill size={20} color={colors.secondary} />
                      <BsStarFill size={20} color={colors.secondary} />
                      <BsStarHalf size={20} color={colors.secondary} />
                      <BsStar size={20} color={colors.secondary} />
                      {reviewsData.customerFeedBack &&
                        <Box>
                          <Typography variant='body2' sx={{ fontWeight: "bold", mt: 1 }}>Customer Feedback:</Typography>
                          <Typography variant='body2'>{reviewsData.customerFeedBack}</Typography>
                        </Box>
                      }
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <ProductNotFound error="No Reviews Found" />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Reviews