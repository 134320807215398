import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { RemoveSpace } from 'app/utils';
import { useNavigate } from 'react-router-dom';
import Images from 'assets/Images'



export default function ImgMediaCard({ data }) {
    let navigate = useNavigate()

    return (
        <Card sx={{ maxWidth: 500, '& .MuiTypography-h4': { fontWeight: 900 }, '& .MuiTypography-root ': { fontSize: '15px', cursor: "pointer", textAlign: "center" } }} >
            <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image={Images.vendorBg}
                // image='http://localhost:3000/static/media/shopBanner.57f3ba224b943ae94d06.jpg'
                sx={{ objectFit: "contain" }}
            />
            <CardMedia
                component="img"
                alt="green iguana"
                height="60"

                sx={{ mx: "auto", height: "80px", width: '80px', mt: '-40px', objectFit: 'contain', boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}
                image={Images.logo}
            />
            <CardContent>
                <Typography gutterBottom variant="h3" component="div" sx={{ fontSize: '15px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    {data.Name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Phone: {data.Contact}
                </Typography>
            </CardContent>
            {/* <CardActions sx={{ width: "100%", '& .MuiButton-root': { width: '100%', background: "#F14662", color: 'white' }, '& .MuiButton-root:hover': { background: "white", color: "#F14662" } }}>
                <Button contained size="small">More Info</Button>
            </CardActions> */}
        </Card>
    );
}