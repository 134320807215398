import { Navigate } from "react-router-dom";

// *Import Components
import Home from "app/views/Home/Home";
import ProductDetail from "app/views/ProductDetail/ProductDetail";
import MyCart from "app/views/MyCart/MyCart";
import Checkout from "app/views/Checkout/Checkout";
import Shop from "app/views/Shop/Shop";
import { PageNotFound } from "app/components/NotFound/NotFound";
import ProductCategory from "app/views/ProductCategory/ProductCategory";
import SearchProducts from "app/views/SearchProducts/SearchProducts";
import VendorList from "app/views/VendorList/Vendor.js";
import AllCategories from 'app/views/AllCategory/AllCategory.js'
import FilterProducts from "app/views/FilterProducts/FilterProducts";

export const AppRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/cart",
    component: <MyCart />,
  },
  {
    path: "/checkout",
    component: <Checkout />,
  },
  {
    path: "/product/:category/:name/:id",
    component: <ProductDetail />,
  },
  {
    path: "/product/:productName",
    component: <SearchProducts />,
  },
  {
    path: "/product/:productName?",
    component: <SearchProducts />,
  },
  {
    path: "/filter",
    component: <FilterProducts />,
  },

  {
    path: "/shop/:name/:id",
    component: <Shop />,
  },
  {
    path: "/category/:name/:catId",
    component: <ProductCategory />,
  },
  {
    path: "/allcategories-lists",
    component: <AllCategories />
  },
  {
    path: "/vendors-list",

    component: <VendorList />,
  },
  {
    path: "*",
    component: <Navigate to="/404" />,
  },
  {
    path: "/404",
    component: <PageNotFound />,
  },
];
