import useAuth from "app/hooks/useAuth";

export const errorHandler = (error) => {
  console.log('error', error);
  if (error.response.status === 401) {

    const token = localStorage.getItem('vendorToken')
    if (token) {
      localStorage.clear()
      window.location.reload()
    }
    else {
      window.location.reload()
    }
    const userToken = localStorage.getItem('customerToken')
    if (userToken) {
      localStorage.clear()
      window.location.reload()
    }
    // localStorage.removeItem('customerToken');
    // localStorage.removeItem('vendorToken');
    // window.location.href('/');
  }
  else if (error.response) {
    return error.response.data.error_description;
  } else if (error.request) {
    return error.error;
  } else {
    return 'Something went wrong';
  }
};
