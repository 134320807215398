import React, { useState } from "react";
import { Box, Button, Dialog, DialogTitle, DialogActions, Typography, Alert, } from "@mui/material";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { GrRefresh } from "react-icons/gr";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import colors from "app/style/colors";
import { LoadingButton } from "@mui/lab";
import { PrimaryLoadButton } from "../UI/Button";

function VerifyOtp(props) {
  const { open, otp, register, resendOtp, closeModel, isDisplayed } = props;
  console.log("🚀 ~ file: VerifyOtp.js:15 ~ VerifyOtp ~ otp:", otp)

  const [otpValue, setOtpValue] = useState();

  // *For Form Validation
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const verify = async (data) => {
    setLoading(true);
    try {
      const verifyOtp = parseInt(otpValue);
      if (verifyOtp === otp) {

        register()

        setOtpValue("");
      } else {
        toast.warn("Please enter a valid OTP.", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        setOtpValue();
      }
    } catch (error) {
      console.log(
        "file: UpdateStatus.js => line 32 => changeStatus => error",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (val) => {

    setOtpValue(val);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}


      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          height: "auto",
          borderRadius: 3,
          p: 3,
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        Please enter verification code.
      </DialogTitle>
      <Box component="form" onSubmit={handleSubmit(verify)}>
        <Box dividers sx={{ overflow: "hidden", pb: 2 }}>
          <OtpInput
            value={otpValue}
            numInputs={6}
            onChange={handleChange}
            inputStyle={{
              width: "30px",
              height: "50px",
              fontSize: "20px",
              border: "none",
              borderBottom: `1px solid ${colors.primary}`,
            }}
            focusStyle={{
              width: "30px",
              height: "50px",
              fontSize: "20px",
              outline: "none",
              border: "none",
              borderBottom: `1px solid ${colors.secondary}`,
            }}
            containerStyle={{ display: "flex", justifyContent: "space-around" }}
          />
        </Box>
        <DialogActions sx={{ pt: 3, display: "block", textAlign: "center" }}>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            sx={{ borderRadius: 5, py: 1, px: 5 }}
          >
            Verify
          </LoadingButton>
          {isDisplayed ? (
            <Typography
              // onClick={() => (resendOtp)}
              onClick={resendOtp}
              sx={{
                fontSize: 12,
                color: colors.textPrimary,
                mt: 2.5,
                cursor: "pointer",
              }}
            >
              <GrRefresh
                style={{
                  fontSize: 18,
                  verticalAlign: "middle",
                  padding: 1,
                  marginRight: "5px",
                }}
              />
              Resend Code
            </Typography>
          ) : (
            <Typography></Typography>
          )

          }
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default VerifyOtp;
