import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import InfoIcon from '@mui/icons-material/Info';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { Service } from 'app/config/service';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;


  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const { open, close, OrderID } = props
  let [Loading, setLoading] = useState(false)




  let token = localStorage.getItem("vendorToken")

  let DeleteOrder = async () => {

    setLoading(true)
    try {
      const { Data, statusCode, Message } = await Service.deleteOrderByID(OrderID, token);

      if (statusCode === 200) {
        close()
      }

    } catch (error) {
      console.log("file: DeleteOrder.js => line 70 => DeleteOrder => error",
        error)

    }
    finally {
      setLoading(false)

    }
  }



  return (
    <div>

      <BootstrapDialog
        fullWidth

        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={close} display='flex' alignItems="center" gap="10px">
          Confirm Delete <InfoIcon sx={{ color: 'orange' }} />
        </BootstrapDialogTitle>
        <DialogContent dividers>

          <Typography mb="20px">Are you sure you want to delete this order?</Typography>
          <Box sx={{ display: "flex", justifyContent: 'center', gap: "10px" }} fullWidth>
            <LoadingButton loading={Loading} variant='outlined' onClick={DeleteOrder}>Yes</LoadingButton>
            <LoadingButton onClick={close} variant='outlined'>No</LoadingButton>
          </Box>
        </DialogContent>

      </BootstrapDialog>
    </div>
  );
}