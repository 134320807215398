import { React, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { Loading } from "app/components/UI/Loader";
import { Service } from "app/config/service";
import { Box, Breadcrumbs, Button, Container, Grid, TextField, Typography } from "@mui/material";
import SimpleCard from "../../components/Cards/SimpleCard";
import colors from "../../style/colors";
import { AiOutlineRight } from "react-icons/ai";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import Images from "assets/Images.js";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useForm } from 'react-hook-form'
import VendorCard from "app/components/AllCategoryCard/AllCategoryCard";
// import "./Style.css";
import { RemoveSpace } from "app/utils";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles({
  link: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.black,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: colors.secondary,
      transition: "0.3s ease-in-out",
    },
  },
});

function AllCategories() {
  const classes = useStyles();
  const { productName } = useParams();
  const [loader, setLoader] = useState(false);
  const [searchData, setSearchData] = useState([]);
  let [allCategories, setAllCategories] = useState([]);
  let [load, setLoad] = useState(false);
  let [loadchk, setLoadchk] = useState(false);
  let [num, setNum] = useState(1);
  let [count, setCount] = useState(1);
  let [vendorList, setVendorsList] = useState([]);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  let [dataa, setdataa] = useState("")
  let navigate = useNavigate();
  // Search Products



  // Search Form Data--------------
  let SearchCategories = (formData) => {

    console.log(formData)
    if (formData !== "") {
      setVendorsList(() => [])
      getCategoryList(formData.search, 1, true)
      setdataa(formData.search)
    }

  };



  const getCategoryList = async (name, Pageno, check) => {
    setNum(Pageno);

    if (Pageno == 1) {
      setLoader(true);
    } else {
      setLoadchk(true);
    }
    try {
      const { Data, Status, ResponseCode } = await Service.getFilterCategory(
        name, Pageno
      );

      // console.log(Data);
      setCount(Data.TotalCount);


      if (Status === true && ResponseCode === 200) {
        if (!check) {

          setAllCategories([...allCategories, ...Data.CategoryList]);
        }
        else {
          setAllCategories(Data.CategoryList)
        }
      } else if (Status === false && ResponseCode === 404) {
        setAllCategories([]);
      }
    } catch (error) {
      console.log("🚀 ~ file: Header.js:288 ~ getCategoryList ~ error", error);
    } finally {
      setLoader(false);
      setLoadchk(false);
    }
  };

  // Search Products
  useEffect(() => {
    getCategoryList("", 1, false);
    window.scrollTo({ top: 0 });
    setSearchData([]);
  }, [productName]);


  return (
    <Container>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Breadcrumbs
          separator={<AiOutlineRight fontSize="small" sx={{ mx: 1 }} />}
          aria-label="breadcrumb"
          sx={{ mt: 3, mb: 2 }}
        >
          <Link to="/" className={classes.link}>
            Home / All Categories
          </Link>
          <Typography sx={{ fontWeight: 500 }}>{productName}</Typography>
        </Breadcrumbs>



        {/* Search filter */}
        <Box
          component="form"
          onSubmit={handleSubmit(SearchCategories)}
          sx={{
            display: "flex",
            alignItems: "center",
            width: "300px",
            "& .MuiFormControl-root": { width: "100%" },
          }}
        >
          <TextField
            placeholder="Search bar"
            id="input-with-sx"
            variant="standard"
            name="search"

            {...register('search')}
          />
          <Button
            type="submit"
            variant="text"
            sx={{ "& .MuiInput-root": { p: "0" } }}
          >
            <SearchOutlinedIcon sx={{ color: "red" }} />
          </Button>
        </Box>
      </Grid>

      {loader ? (
        <Loading />
      ) : (
        // <></>
        <Grid>
          <Grid container spacing={3} columns={{ sm: 12, md: 12, lg: 10 }}>
            {allCategories.length !== 0 ? (
              allCategories.map((item) => (
                <Grid
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(
                      `/category/${item.CategoryName}/${item.CategoryID}`
                    );
                  }}
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={2}
                >
                  <VendorCard data={item} />
                </Grid>
              ))
            ) : (
              <div className="Error">
                <img src={Images.notFound}></img>
                <h2>Oops No Category found</h2>
              </div>
            )}
          </Grid>

          {/* Loaddddddd Moreeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee */}

          {count > allCategories.length ? (
            <Box
              sx={{ textAlign: "center", my: 2 }}
              onClick={() => {
                getCategoryList(dataa, num + 1, false);
              }}
            >
              <LoadingButton
                loading={loadchk}
                variant="contained"
                sx={{
                  bgcolor: colors.secondary,
                  "&:hover": { bgcolor: colors.secondaryDark },
                }}
              >
                Load More
              </LoadingButton>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      )}
    </Container>
  );
}

export default AllCategories;
