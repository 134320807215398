import React, { useEffect, useRef, useState } from 'react'
import { Grid, Typography, Container, Divider, Box, FormControl, TextField, Toolbar, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import Geocode from "react-geocode";
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaMapMarkerAlt } from 'react-icons/fa';

import colors from 'app/style/colors';
import { Service } from 'app/config/service';

// *Import Component
import { Error } from 'app/components/UI/Error';
import MapModal from 'app/components/Dialog/MapDialogBox';
import VendorSideNav from 'app/components/SideNav/VendorSideNav';
import { SuccessToaster } from 'app/components/UI/Toaster';
import { InputField, OutlineInputField } from 'app/components/UI/TextField';

function VendorProfile() {

  let accountId = localStorage.getItem('vendorId')
  let token = localStorage.getItem('vendorToken')

  Geocode.setApiKey("AIzaSyBJ3IVlUfKfOT9ra-80Fex_t_hM5mfigro");

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, watch, reset: reset2 } = useForm();
  const password = useRef({});
  password.current = watch("newPassword", "");

  const [profileLoading, setProfileLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);

  // *For Profile
  const [profile, setProfile] = useState();

  const [openDialog, setOpenDialog] = useState(false);

  const [currentLocation, setCurrentLocation] = useState({ latitude: 0, longitude: 0 })
  const [updatedAddress, setUpdatedAddress] = useState("")

  // *For Save Button Hide
  const [button, setButton] = useState(true)

  // *For Get Profile
  const getProfile = async () => {
    try {
      const { Data } = await Service.getProfileDetail(accountId)
      setProfile(Data[0])
      setValue("name", Data[0].Name)
      setValue("phone", Data[0].EmailPh.slice(0, -2))
      setValue("addressTitle", Data[0]?.Addresses[0]?.AddressTitle)
      setValue("streetInfo", Data[0]?.Addresses[0]?.StreetInfo)
      const latitude = Data[0]?.Addresses[0]?.Latitude
      const longitude = Data[0]?.Addresses[0]?.Longitude
      setCurrentLocation({ latitude, longitude })
      localStorage.setItem('vendorName', Data[0].Name)
    } catch (error) {
      console.log('file: Profile.js => line 13 => getProfile => error', error)
    }
  };

  // *For Update Profile
  const updateProfile = async (data) => {
    setProfileLoading(true)
    try {
      let address = [{
        AddressTitle: data.addressTitle,
        Address: data.address,
        StreetInfo: data.streetInfo,
        Latitude: currentLocation.latitude,
        Longitude: currentLocation.longitude,
      }]

      let updateAddressIndex = 0
      var addressDetail = {};

      for (const addressIndex in address) {
        const { AddressTitle, Address, StreetInfo, Latitude, Longitude } = address[addressIndex];
        if (Address) {
          addressDetail['vendorAddresses[' + updateAddressIndex + '].AddressTitle'] = AddressTitle;
          addressDetail['vendorAddresses[' + updateAddressIndex + '].Address'] = Address;
          addressDetail['vendorAddresses[' + updateAddressIndex + '].StreetInfo'] = StreetInfo;
          addressDetail['vendorAddresses[' + updateAddressIndex + '].Latitude'] = Latitude;
          addressDetail['vendorAddresses[' + updateAddressIndex + '].Longitude'] = Longitude;
          updateAddressIndex++;
        }
      }


      let obj = {
        AccountID: parseInt(accountId),
        UserTypeID: 1, // *1 For Vendor
        Name: data.name,
        ...addressDetail
      }
      console.log('file: Profile.js => line 96 => updateProfile => obj', obj);
      const { Status, ResponseCode } = await Service.updateProfile(obj, token)
      if (Status === true && ResponseCode === 200) {
        SuccessToaster('Profile Updated Successfully!')
        getProfile()
        setButton(false)
      }

    } catch (error) {
      console.log('file: Profile.js => line 41 => updateProfile => error', error)
    } finally {
      setProfileLoading(false)
    }
  };

  // *For Save Button Hide & Show
  const saveButton = () => {
    try {
      setButton(true)
    } catch (error) {
      console.log('file: settings.js => line 127 => setSaveButton => error', error)
    }
  };

  // *For Change Password
  const changePassword = async (data) => {
    setPasswordLoading(true)
    try {
      let obj = {
        OldPassword: data.oldPassword,
        NewPassword: data.newPassword,
        ConfirmPassword: data.confirmPassword,
      }
      const { status } = await Service.changePassword(obj, token)
      SuccessToaster('Password Changed Successfully!')

      reset2()
    } catch (error) {
      console.log('file: Profile.js => line 89 => changePassword => error', error)
    } finally {
      setPasswordLoading(false)
    }
  };

  const changeLocation = (val) => {
    const latitude = val.lat()
    const longitude = val.lng()
    setCurrentLocation({ latitude, longitude })
  };

  const geoLocation = () => {
    Geocode.fromLatLng(currentLocation?.latitude, currentLocation?.longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setUpdatedAddress(address)
        setValue("address", address)
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const openMapDialog = () => {
    setOpenDialog(true)
    navigator.geolocation.getCurrentPosition((location) => {
      const latitude = location.coords.latitude
      const longitude = location.coords.longitude
      setCurrentLocation({ latitude, longitude })
    })
  };

  useEffect(() => {
    getProfile()
    window.scrollTo({ top: 0 })
  }, []);

  useEffect(() => {
    geoLocation()
  }, [currentLocation]);

  return (
    <Container sx={{ my: 3 }}>

      {/* ===== Map Modal ===== */}
      <MapModal currentLocation={currentLocation} open={openDialog} locationChange={changeLocation} onClose={() => setOpenDialog(!openDialog)} />

      <Grid container columnSpacing={3}>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "100%", height: 40, display: { xs: "block", md: "none" } }}
        >
          <Toolbar />
        </Box>
        <Grid item xs={12} md={3}>
          <VendorSideNav />
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant={'h4'} sx={{ my: 2, fontWeight: "bold", color: colors.primary }}>My Profile</Typography>
          <Divider sx={{ mb: 2.5, mt: 1 }} />
          <Box component="form" sx={{ mb: 3 }} onSubmit={handleSubmit(updateProfile)}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <InputField
                  label='Name'
                  size="medium"
                  value={`${profile?.Name}`}
                  error={errors?.name?.message}
                  register={register("name", {
                    required: 'Please enter name.',
                  })
                  }
                  onChange={saveButton}
                />
                {/* <FormControl variant="standard" fullWidth >
                  <TextField
                    size="medium"
                    variant="outlined"
                    label="Name"
                    defaultValue={`${profile?.Name}`}
                    error={errors?.name?.message && (true)}
                    {...register("name", {
                      required: 'Please enter name.',
                    })}
                    onChange={saveButton}
                  />
                  {errors?.name?.message && (
                    <Error message={errors?.name?.message} />
                  )}
                </FormControl> */}
              </Grid>
              <Grid item xs={6}>
                <InputField
                  label='Phone Number'
                  size="medium"
                  type='number'
                  value={`${profile?.EmailPh.slice(0, -2)}`}
                  register={register("phone")}
                  InputProps={{
                    readOnly: true,
                  }}
                  onChange={saveButton}
                />
                {/* <FormControl variant="standard" fullWidth >
                  <TextField
                    size="medium"
                    variant="outlined"
                    label="Phone Number"
                    // type='number'
                    value={`${profile?.EmailPh.slice(0, -2)}`}
                    error={errors?.phone?.message && (true)}
                    {...register("phone")}
                    InputProps={{
                      readOnly: true,
                    }}
                    onChange={saveButton}
                  />
                  {errors?.phone?.message && (
                    <Error message={errors?.phone?.message} />
                  )}
                </FormControl> */}
              </Grid>
              <Grid item xs={6}>
                <InputField
                  label='Address Title'
                  size="medium"
                  defaultValue={`${profile?.Addresses[0]?.AddressTitle ? profile?.Addresses[0]?.AddressTitle : ''}`}
                  register={register("addressTitle")}
                  onChange={saveButton}
                />
                {/* <FormControl variant="standard" fullWidth >
                  <TextField
                    size="medium"
                    variant="outlined"
                    label="Address Title"
                    defaultValue={`${profile?.Addresses[0]?.AddressTitle ? profile?.Addresses[0]?.AddressTitle : ''}`}
                    {...register("addressTitle")}
                    onChange={saveButton}
                  />
                </FormControl> */}
              </Grid>
              <Grid item xs={6}>
                <InputField
                  label='Street Info'
                  size="medium"
                  defaultValue={`${profile?.Addresses[0]?.StreetInfo ? profile?.Addresses[0]?.StreetInfo : ''}`}
                  register={register("streetInfo")}
                  onChange={saveButton}
                />
                {/* <FormControl variant="standard" fullWidth >
                  <TextField
                    size="medium"
                    variant="outlined"
                    label="Street Info"
                    defaultValue={`${profile?.Addresses[0]?.StreetInfo ? profile?.Addresses[0]?.StreetInfo : ''}`}
                    {...register("streetInfo")}
                    onChange={saveButton}
                  />
                </FormControl> */}
              </Grid>
              <Grid item xs={12}>
                {/* <FormControl variant="standard" fullWidth >
                  <TextField
                    size="medium"
                    variant="outlined"
                    label="Address"
                    defaultValue={`${profile?.Addresses}`}
                    error={errors?.address?.message && (true)}
                    {...register("address")}
                    onChange={saveButton}
                  />
                  {errors?.address?.message && (
                    <Error message={errors?.address?.message} />
                  )}
                </FormControl> */}
                <OutlineInputField
                  label={"Address"}
                  updatedValue={updatedAddress}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                      >
                        <FaMapMarkerAlt onClick={() => openMapDialog()} />
                      </IconButton>
                    </InputAdornment>
                  }
                  error={errors2?.address?.message && (true)}
                  register={register2("address", {
                    onChange: (e) => setUpdatedAddress(e.target.value),
                    required: 'Please enter address.',
                  })
                  }
                />
                {/* <FormControl fullWidth sx={{ marginY: '10px' }}>
                  <InputLabel >Address</InputLabel>
                  <OutlinedInput
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                        >
                          <FaMapMarkerAlt onClick={() => openMapDialog()} />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Address"
                    variant="outlined"
                    error={errors?.address?.message && (true)}
                    sx={{ width: '100%' }}
                    {...register("address", {
                      onChange: (e) => setUpdatedAddress(e.target.value),
                      required: 'Please enter address.',
                    })}
                    value={updatedAddress}
                  />
                  {errors?.address?.message && (
                    <Error message={errors?.address?.message} />
                  )}
                </FormControl> */}
              </Grid>
              {/* <Grid item xs={12}>
                <FormControl variant="standard" fullWidth >
                  <TextField
                    size="medium"
                    variant="outlined"
                    label="Address"
                    defaultValue={`${profile?.Addresses}`}
                    error={errors?.address?.message && (true)}
                    {...register("address")}
                    onChange={saveButton}
                  />
                  {errors?.address?.message && (
                    <Error message={errors?.address?.message} />
                  )}
                </FormControl>
              </Grid> */}
              <Grid item xs={12}>
                {button &&
                  <LoadingButton
                    type='submit'
                    loading={profileLoading}
                    variant="contained"
                    sx={{
                      borderRadius: '10px',
                      padding: '8px 30px',
                      textTransform: 'capitalize',
                      bgcolor: colors.secondary,
                      '&:hover': {
                        bgcolor: "#c4364c",
                      },
                    }}
                  >
                    Save Changes
                  </LoadingButton>
                }
              </Grid>
            </Grid>
          </Box>

          {/* ========== Change Password ========== */}
          <Box component="form" sx={{ mb: 3 }} onSubmit={handleSubmit2(changePassword)}>
            <Grid container spacing={3}  >
              <Grid item xs={12}>
                <Typography variant={'h6'} sx={{ color: colors.primary }}>Change Password</Typography>
                <Divider sx={{ mt: 1 }} />
              </Grid>
              <Grid item xs={12} >
                <InputField
                  width={{
                    xs: "100%",
                    sm: "50%"
                  }}
                  label="Old Password"
                  size="medium"
                  type="password"
                  error={errors2?.oldPassword?.message && (true)}
                  register={register2("oldPassword", {
                    required: 'Please enter old password.',
                  })}
                />
                {/* <FormControl variant="standard" sx={{ width: { xs: "100%", md: "50%" } }}>
                  <TextField
                    size="medium"
                    type="password"
                    variant="outlined"
                    label="Old Password"
                    error={errors2?.oldPassword?.message && (true)}
                    {...register2("oldPassword", {
                      required: 'Please enter old password.',
                    })}
                  />
                  {errors2?.oldPassword?.message && (
                    <Error message={errors2?.oldPassword?.message} />
                  )}
                </FormControl> */}
              </Grid>
              <Grid item xs={12} >
                <InputField
                  width={{
                    xs: "100%",
                    sm: "50%"
                  }}
                  label="New Password"
                  size="medium"
                  type="password"
                  error={errors2?.newPassword?.message && (true)}
                  register={register2("newPassword", {
                    required: 'Please enter new password.',
                    minLength: {
                      value: 6,
                      message: "Password must have at least 6 characters"
                    }
                  })}
                />
                {/* <FormControl variant="standard" sx={{ width: { xs: "100%", md: "50%" } }} >
                  <TextField
                    size="medium"
                    type="password"
                    variant="outlined"
                    label="New Password"
                    error={errors2?.newPassword?.message && (true)}
                    {...register2("newPassword", {
                      required: 'Please enter new password.',
                      minLength: {
                        value: 6,
                        message: "Password must have at least 6 characters"
                      }
                    })}
                  />
                  {errors2?.newPassword?.message && (
                    <Error message={errors2?.newPassword?.message} />
                  )}
                </FormControl> */}
              </Grid>
              <Grid item xs={12} >
                <InputField
                  width={{
                    xs: "100%",
                    sm: "50%"
                  }}
                  label="Confirm Password"
                  size="medium"
                  type="password"
                  error={errors2?.confirmPassword?.message && (true)}
                  register={register2("confirmPassword", {
                    required: 'Please enter confirm password.',
                    validate: value =>
                      value === password.current || "Confirm password does not match"
                  })}
                />
                {/* <FormControl variant="standard" sx={{ width: { xs: "100%", md: "50%" } }}  >
                  <TextField
                    size="medium"
                    type="password"
                    variant="outlined"
                    label="Confirm Password"
                    error={errors2?.confirmPassword?.message && (true)}
                    {...register2("confirmPassword", {
                      required: 'Please enter confirm password.',
                      validate: value =>
                        value === password.current || "Confirm password does not match"
                    })}
                  />
                  {errors2?.confirmPassword?.message && (
                    <Error message={errors2?.confirmPassword?.message} />
                  )}
                </FormControl> */}
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  type='submit'
                  loading={passwordLoading}
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    padding: '8px 30px',
                    textTransform: 'capitalize',
                    bgcolor: colors.secondary,
                    '&:hover': {
                      bgcolor: "#c4364c",
                    },
                  }}
                >
                  Change Password
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>

        </Grid>
      </Grid>
    </Container>
  )
}

export default VendorProfile