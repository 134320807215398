import { React, useEffect } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { Loading } from "app/components/UI/Loader";
import { Service } from "app/config/service";
import { Box, Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import SimpleCard from "../../components/Cards/SimpleCard";
import colors from "../../style/colors";
import { AiOutlineRight } from "react-icons/ai";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import Images from 'assets/Images.js'
import { GrFilter } from "react-icons/gr"
import FilterDrawer from "../../components/FilterDrawer/FilterDrawer";
import "./Style.css"
import FilterSideNav from "app/components/SideNav/FilterSideNav";

const useStyles = makeStyles({
  link: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.black,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: colors.secondary,
      transition: "0.3s ease-in-out",
    },
  },
});
const filterStyle = {

  listStyle: "none",
  textDecoration: "none",
  color: colors.black,
  bgcolor: colors.gray,
  fontWeight: "600",
  py: 1,
  px: 2,
  mx: 0.5,
  mt: 0.5,
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  cursor: "pointer",
  "&:hover": {
    bgcolor: `${colors.primaryLight} !important`,
    color: colors.white,
    transition: "0.3s ease-in-out",
  },
  "&:first-child": {
    bgcolor: "#ddd",
  },
}

function SearchProducts() {
  let [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const { productName } = useParams();
  const [loader, setLoader] = useState(false);
  const [searchData, setSearchData] = useState([]);
  let [num, setNum] = useState(1);
  let [load, setLoad] = useState(false);
  const { state } = useLocation();


  //* For Filter Drawer 
  const [filterOpen, setFilterOpen] = useState(false)
  const filterContainer = window !== undefined ? () => window().document.body : undefined;
  const filterDrawerToggle = () => {
    setFilterOpen(!filterOpen);
  };

  //* Search Products API

  const searchProducts = async (a, newList) => {
    setNum(a);
    setLoad(true);
    if (a == 1) {
      setLoader(true);
    }
    try {
      const { Data } = await Service.searchProducts(a, productName);
      setLoad(true);
      if (Data === null) setSearchData([]);
      if (newList) {
        setSearchData(Data[0]?.ItemArray);
      } else {
        setSearchData([...searchData, ...Data[0]?.ItemArray]);
      }
    } catch (error) {
      console.log(
        "file: ProductCategory.js => line 25 => getNewArrival => error: error",
        error
      );
    } finally {
      setLoader(false);
      setLoad(false);
    }
  };

  //* Search Products
  useEffect(() => {
    searchProducts(1, true);
    window.scrollTo({ top: 0 });
    setSearchData([])

  }, [productName]);

  //* Call Back For Category Use Search Params

  const searchParamsFun = (params) => {
    console.log("🚀 ~ file: SearchProducts.js:112 ~ searchParamsFun ~ params", params)
    setSearchParams(params)
  }

  //* For Get Categories And Sub Categories
  const [category, setCategory] = useState([])

  const [subCategoryList, setSubCategoryList] = useState([])
  const getCategory = async () => {
    try {
      const { Data } = await Service.getCategory();
      setCategory(Data);
    } catch (error) {
      console.log("🚀 ~ file: FilterDrawer.js:48 ~ getCategory ~ error", error)
    }
  };

  //* For Brands ApI Call
  const [brandList, setBrandList] = useState([]);
  const getBrands = async () => {
    try {
      const { Data } = await Service.getBrands()
      setBrandList(Data)
    }
    catch (error) {
      console.log("🚀 ~ file: FilterDrawer.js:72 ~ getBrands ~ error", error)
    }
  };

  //* API CALL For Filter Category And Brand
  useEffect(() => {
    getCategory()
    getBrands()
  }, [])
  return (
    <Container>
      <Grid item xs={12} display="flex">
        <Breadcrumbs
          separator={<AiOutlineRight fontSize="small" sx={{ mx: 1 }} />}
          aria-label="breadcrumb"
          sx={{ mt: 3, mb: 2 }}
        >
          <Link to="/" className={classes.link}>
            Home
          </Link>
          <Typography sx={{ fontWeight: 500 }}>{productName}</Typography>
        </Breadcrumbs>
        <Box alignSelf='center'>
          <FilterDrawer
            // filterContainer={filterContainer}
            filterOpen={filterOpen}
            filterDrawerToggle={filterDrawerToggle}
            productName={productName} />

          <Typography
            // component="li"
            sx={filterStyle}
            // {...bindHover(popupVendor)}
            onClick={() => {
              filterDrawerToggle()
            }}
          > Filter{" "}
            <GrFilter fontSize="22px" style={{ verticalAlign: "middle" }} />
          </Typography>
        </Box>
      </Grid>

      {loader ? (
        <Loading />
      ) : (
        // <></>

        <Grid>
          <Grid container spacing={1}>
            {/* <Grid item xs={6} md={4} lg={3} >
              <FilterSideNav category={category} brandList={brandList} searchParamsFun={searchParamsFun} />
            </Grid> */}
            <Grid item xs={6} md={12} lg={12}>
              <Grid container spacing={2} columns={{ sm: 6, md: 8, lg: 8 }}>
                {searchData.length !== 0 ?

                  searchData.map((item, index) => (
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                      <SimpleCard key={index} product={item} />
                    </Grid>
                  )) : (
                    <div className="Error">
                      <img src={Images.notFound}></img>
                      <h2>Oops No Product found</h2>
                    </div>


                  )

                }
              </Grid>
            </Grid>
          </Grid>

          {searchData.length >= 10 ? (
            <Box
              sx={{ textAlign: "center", my: 2 }}
              onClick={() => {
                searchProducts(num + 1);
              }}
            >
              <LoadingButton
                loading={load}
                variant="contained"
                sx={{
                  bgcolor: colors.secondary,
                  "&:hover": { bgcolor: colors.secondaryDark },
                }}
              >
                Load More
              </LoadingButton>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      )}
    </Container>
  );
}

export default SearchProducts;
