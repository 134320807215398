import React, { useEffect, useState } from 'react'
import { Box, Card, CardActionArea, CardContent, CardMedia, Container, Divider, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import colors from 'app/style/colors'
import { Service } from 'app/config/service'
import { RemoveSpace } from 'app/utils'

// *Import Components
import { ProductNotFound } from 'app/components/NotFound/NotFound'
import CustomerSideNav from 'app/components/SideNav/CustomerSideNav'
import { Loading } from 'app/components/UI/Loader'

function Favourites() {

  // *For Favourite

  const [favourite, setFavourite] = useState([]);

  const [loader, setLoader] = useState(false);
  // *For Get Favourite
  const getFavourite = async () => {
    try {
      setLoader(true)
      let favouriteProducts = []
      const accountId = localStorage.getItem('customerId')
      const { Data } = await Service.getCustomerFavourite(accountId)
      for (let index = 0; index < Data.length; index++) {
        const productId = Data[index];
        const product = await Service.getProductByProductId(productId)
        favouriteProducts.push(product.Data[0])
      }
      setFavourite(favouriteProducts)
    } catch (error) {
      console.log('file: Favourites.js => line 23 => getFavourite => error', error)
    }
    finally {
      setLoader(false)
    }
  };

  useEffect(() => {
    getFavourite()
  }, []);

  return (
    <Container sx={{ my: 3 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4} md={3}>
          <CustomerSideNav />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <Typography variant={'h5'} sx={{ my: 2, fontWeight: "bold", color: colors.primary }}>Favourites</Typography>
          <Divider sx={{ mb: 2.5, mt: 1 }} />
          {loader ?
            <Loading /> :
            favourite.length ? (
              <Grid container spacing={3}>
                {favourite.map((item, index) => (
                  <Grid key={index} item xs={6} sm={6} md={3}>
                    <Box component={Link} to={`/product/${RemoveSpace(item.CategoryName)}/${RemoveSpace(item.ItemName)}/${item.ItemID}`} sx={{ textDecoration: 'none' }}>
                      <Card>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="100"
                            image={item.File}
                            alt={item.ItemName}
                            sx={{ width: "80%", mx: "auto", my: 3, objectFit: "cover" }}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="body1">
                              {item.ItemName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Rs.{item.SalesRate}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <ProductNotFound error="No Favourites Products" />
            )
          }

        </Grid>
      </Grid>
    </Container>
  )
}

export default Favourites