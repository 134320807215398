import { Box, Collapse, List, ListItem, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import GridViewIcon from '@mui/icons-material/GridView';

import colors from 'app/style/colors';
import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
// import { Service } from 'app/config/service';
// import { BsCheckLg } from 'react-icons/bs';
// import { useNavigate } from 'react-router-dom';
// import { PrimaryBasicButton } from './../UI/Button';
// import { styled } from '@mui/material/styles';
// import Images from 'assets/Images.js';



function FilterSideNav({ category, brandList, searchParamsFun }) {
  console.log("🚀 ~ file: FilterSideNav.js:22 ~ FilterSideNav ~ searchParamsFun", searchParamsFun)
  const [openCategory, setOpenCategory] = useState(false);
  const [openBrand, setOpenBrand] = useState(false);
  const [categoryChecked, setCategoryChecked] = useState([]);

  const [brandChecked, setBrandChecked] = useState([]);


  const handleCategory = () => {
    setOpenCategory(!openCategory);
  };

  const handleBrands = () => {
    setOpenBrand(!openBrand);
  }

  const handleCategoryChecked = (value) => {
    const currentIndex = categoryChecked.indexOf(value);
    const newChecked = [...categoryChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCategoryChecked(newChecked);
    searchParamsFun(categoryChecked)
  };

  const handleBrandChecked = (value) => {
    const currentIndex = brandChecked.indexOf(value);
    const newChecked = [...brandChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setBrandChecked(newChecked);
  };

  return (
    <Box >
      <Box>
        {/* <img src={Images.logo} alt="RidoLogo" style={{ width: "100px", marginRight: "90px" }} /> */}
      </Box>
      <Box>

        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          component="nav"
          subheader={
            <ListSubheader component="div">
              <Box display="flex" justifyContent="center">
                <Typography sx={{ color: colors.primary, bgcolor: colors.gray, py: 1, px: 4, borderRadius: "6px" }}>
                  Select Products Category
                </Typography>
              </Box>
            </ListSubheader>
          }
        >

          <ListItemButton
            onClick={handleCategory}
            sx={{
              minHeight: 48,
              m: 1,
              borderRadius: "8px",
              "&.Mui-selected": {
                bgcolor: colors.secondary,
                color: colors.white,
                ":hover": {
                  backgroundColor: colors.secondary,
                  color: colors.white,
                }
              },
              ":hover": {
                backgroundColor: colors.secondary,
                color: colors.white,
              },
            }}>
            <ListItemIcon>
              <GridViewIcon />
            </ListItemIcon>
            <ListItemText primary="Categories" />
            {openCategory ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton >

          <Collapse in={openCategory} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {category?.map((value, index) => (
                <ListItem key={index}>
                  <ListItemButton sx={{ pl: 4 }}
                    onClick={() => handleCategoryChecked(value.CategoryID)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={categoryChecked.indexOf(value.CategoryID) !== -1}
                        tabIndex={-1}
                        disableRipple
                      // inputProps={{ 'aria-labelledby': index }}
                      />
                    </ListItemIcon>
                    <ListItemText id={index} primary={`${value.CategoryName}`} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>

        {/* Brand Collapse List */}
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
        >
          <ListItemButton onClick={handleBrands}
            sx={{
              minHeight: 48,
              m: 1,
              borderRadius: "8px",
              "&.Mui-selected": {
                bgcolor: colors.secondary,
                color: colors.white,
                ":hover": {
                  backgroundColor: colors.secondary,
                  color: colors.white,
                }
              },
              ":hover": {
                backgroundColor: colors.secondary,
                color: colors.white,
              },
            }}>
            <ListItemIcon>
              <LoyaltyIcon />
            </ListItemIcon>
            <ListItemText primary="Brands" />
            {openBrand ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton >

          <Collapse in={openBrand} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {brandList?.map((value, index) => (
                <ListItemButton key={index} sx={{ pl: 4 }}
                  onClick={() => handleBrandChecked(value.ID)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={brandChecked.indexOf(value.ID) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': index }}
                    />
                  </ListItemIcon>
                  <ListItemText id={index} primary={` ${value.Value}`} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>

        <Box width="100%" py={1} display="flex" justifyContent="center">
          {/* <Button variant="contained" sx={{ borderRadius: "18px" }}
          onClick={() => filterProducts(categoryChecked, brandChecked, itemName)}>
          Search
        </Button> */}
        </Box>
      </Box>
    </Box>
  )
}

export default FilterSideNav