import moment from "moment";

// *Email Regex
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// *Current Year
export const CurrentYear = () => {
  let date = new Date();
  let year = date.getFullYear();

  return (
    <span>
      {year}
    </span>
  )
}

// *Remove Url Space
export const RemoveUrlSpace = (string) => {
  let url = string.replaceAll(' ', '%20');
  return url;
}

// *Remove Space
export const RemoveSpace = (string) => {
  const name = string.replace(/ /g, '-').toLowerCase();
  return name;
}

// *Convert TO Uppercase
export const convertToUpperCase = (string) => {
  if (!string) return
  const replace = string?.replace(/-/g, ' ');
  const name = replace?.replace(/-/g, ' ').charAt(0).toUpperCase() + replace.slice(1);
  return name;
}

// *Date Format
export const getDate = (date) => {
  const newDate = moment(date).format('DD-MM-YYYY')
  return newDate
}
