import React, { Fragment, useEffect, useState } from "react";
import {
  Typography,
  Container,
  Divider,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Chip,
  Tooltip,
  IconButton,
  Toolbar,
  Box,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DialogDelete from "app/components/UI/Dialog.js"

// import { ComponentToPrint } from "./ComponentToPrint";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { RiFileEditLine } from "react-icons/ri";
import { HiOutlineEye } from "react-icons/hi";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

import colors from "app/style/colors";
import { Service } from "app/config/service";
import { getDate } from "app/utils";

// *Import Component
import { ProductNotFound } from "app/components/NotFound/NotFound";


// Vendor Order Detail-------------------------->
import VendorOrderDetail from "app/components/Dialog/VendorOrderDetail";




import UpdateStatus from "app/components/Dialog/UpdateStatus";
import VendorSideNav from "app/components/SideNav/VendorSideNav";
import { Loading } from "app/components/UI/Loader";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

// hide last border
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function VendorOrder() {
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const [IDofOrder, setIDofOrder] = useState("")

  let [openModel, setOpenModel] = useState(false)
  const [loading, setLoading] = useState(true)
  // *For Status
  const [status, setStatus] = useState();

  // *For Order
  const [order, setOrder] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState("");

  let [printInvoice, setPrintInvoice] = useState(true);

  // *For Order Detail Dialog
  const orderDetail = (saleDetail, invoice, date, address, qty, delivery, discount, total, print) => {
    console.log("🚀 ~ file: VendorOrder.js:88 ~ orderDetail ~ total:", total)
    console.log("🚀 ~ file: VendorOrder.js:88 ~ orderDetail ~ discount:", discount)
    // for(let i=0;total.length>)
    let data = {
      saleDetail: saleDetail,
      invoice: invoice,
      date: getDate(date),
      address: address,
      qty: qty,
      delivery: delivery,
      subTotal: total,//total - discount, //Qty added
      discount: (total - discount),
      // discount,change after 27 Feb
      totalAmount: discount,
      // total - discount, // change after 27 Feb
    };
    setOpen(true);
    setSelectedOrder(data);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // *For Status Dialog
  const statusDialog = (item) => {
    setOpenUpdate(true);
    setStatus(item.OrderStatus);
    setSelectedOrder(item)
  };

  const handleClickListClose = (newValue) => {
    setOpenUpdate(false);
  };

  // *For Get Order
  const getOrder = async () => {
    setLoading(true)
    try {
      const accountId = localStorage.getItem("vendorId");
      const { Data } = await Service.getVendorOrder(accountId);
      setOrder(Data);
      setLoading(false)
    } catch (error) {
      console.log(
        "file: CustomerOrder.js => line 56 => getOrder => error",
        error
      );
    }
  };

  useEffect(() => {
    getOrder();
    window.scrollTo({ top: 0 });
  }, []);

  let orderDetailsData = (item) => {
    if (item.SaleDetails.length > 1) {
      let total = 0;
      for (let i = 0; item.SaleDetails.length > i; i++) {
        total = total + item.SaleDetails[i].Total;
      }
      orderDetail(

        item?.SaleDetails,
        item?.OrderID,
        item.SalesDate,
        item?.DeliveryAddress,
        item?.SaleDetails[0]?.Qty,
        item?.DeliveryCharges,
        item?.DiscountAmount,

        // item?.SaleDetails[0]?.Qty,
        // item?.SaleDetails[0]?.SalePrice,
        // item?.SaleDetails[0]?.DiscountAmount,
        total
      );
    } else {
      orderDetail(
        item?.SaleDetails,
        item?.OrderID,
        item.SalesDate,
        item?.DeliveryAddress,
        item?.SaleDetails[0]?.Qty,
        // item?.TotalAmount,
        // item?.SaleDetails[0]?.DiscountAmount,
        item.DeliveryCharges,
        item?.DiscountAmount,
        // item?.SaleDetails[0]?.DiscountAmount,
        item?.SaleDetails[0].Total
      );
    }
  };

  // handle Model --------------->
  let HandleDialogBox = (OrderID) => {
    setIDofOrder(OrderID)
    setOpenModel(!openModel)
  }

  return (
    <Container sx={{ my: 3 }}>
      {/* ========== Order Detail Modals ========== */}
      <VendorOrderDetail
        orderDetail={selectedOrder}
        show={open}
        handleClose={handleClose}
        print={printInvoice}
      />

      {/* ========== Update Status Modals ========== */}
      <UpdateStatus
        getOrder={getOrder}
        orderDetail={selectedOrder}
        orderStatus={status}
        show={openUpdate}
        handleClose={handleClickListClose}
      />

      <Grid container columnSpacing={4}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: "100%",
            height: 40,
            display: { xs: "block", md: "none" },
          }}
        >
          <Toolbar />
        </Box>
        <Grid item xs={12} md={3}>
          <VendorSideNav />
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography
            variant={"h4"}
            sx={{ my: 2, fontWeight: "bold", color: colors.primary }}
          >
            My Orders
          </Typography>
          <Divider sx={{ mb: 2.5, mt: 1 }} />
          {loading ? (
            <Box sx={{ width: "100%" }}><Loading /></Box>
          ) : !order.length ? (
            <ProductNotFound error="No Order Found" />
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Invoice</StyledTableCell>
                    <StyledTableCell>Customer Name</StyledTableCell>
                    <StyledTableCell>Price</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.map((item, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        sx={{ py: 0.5, width: 70 }}
                        component="th"
                        scope="row"
                      >
                        {item.OrderID}
                      </StyledTableCell>
                      <StyledTableCell sx={{ py: 0.5, width: 250 }}>
                        {item.AccountName}
                      </StyledTableCell>
                      <StyledTableCell sx={{ py: 0.5, width: 120 }}>
                        {(item.TotalPaid - item.DeliveryCharges)}
                        {/* {(item.DiscountAmount)} Changes After 27 Feb */}
                      </StyledTableCell>
                      <StyledTableCell sx={{ py: 0.5, width: 150 }}>
                        {getDate(item.SalesDate)}
                      </StyledTableCell>
                      <StyledTableCell sx={{ py: 0.5, width: 50 }}>
                        <Chip
                          label={item.OrderStatus}
                          sx={{
                            bgcolor:
                              item.OrderStatus === "Pending"
                                ? colors.primary
                                : item.OrderStatus === "Delivered"
                                  ? colors.darkGreen
                                  : item.OrderStatus === "In Progress"
                                    ? colors.primaryMedium
                                    : colors.ratingYellow,
                            color: colors.white,
                            height: "22px",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell sx={{ py: 0.5, width: 250 }}>
                        <Tooltip title="Order Detail">
                          <IconButton onClick={() => orderDetailsData(item)}>
                            <HiOutlineEye
                              size={22}
                              style={{
                                color: colors.secondary,
                                marginRight: 5,
                              }}
                            />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          title="Update Status"
                          onClick={() => item.OrderStatus !== "Delivered" ? statusDialog(item) : undefined}
                        >
                          <IconButton>
                            <RiFileEditLine
                              size={22}
                              style={{
                                color:
                                  item.OrderStatus !== "Delivered" ? colors.secondary : colors.darkGray
                              }}
                            />
                          </IconButton>
                        </Tooltip>

                        {/* Print the invoice -------------------> */}

                        <Tooltip
                          title="Print"
                          onClick={async () => {
                            await orderDetailsData(item);
                            window.print();
                          }}
                        >
                          <IconButton>
                            <LocalPrintshopIcon
                              sx={{ color: "rgb(241, 70, 98)" }}
                            />
                          </IconButton>

                        </Tooltip>

                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>

      <DialogDelete open={openModel} close={HandleDialogBox} OrderID={IDofOrder} />
    </Container>
  );
}

export default VendorOrder;
