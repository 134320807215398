import React, { Fragment, useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import { connect } from 'react-redux';

// *Import Components
import CheckoutStepper from 'app/components/CheckoutStepper/CheckoutStepper';
import OrderSummary from 'app/components/OrderSummary/OrderSummary';

const mapStateToProps = (state) => {
  return {
    items: state.items,
    totalAmount: state.totalAmount
  }
}

function Checkout(props) {

  const { items } = props

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Fragment>
      <Container>
        <Grid container spacing={2} sx={{ mt: 4, mb: 8 }}>

          {/* ========== Checkout Detail ========== */}
          <Grid item xs={12} md={7}>
            <CheckoutStepper items={items} />
          </Grid>

          {/* ========== Order Summary ========== */}
          <Grid item xs={12} md={5}>
            <OrderSummary items={items} />
          </Grid>

        </Grid>
      </Container>
    </Fragment>
  );
}

export default connect(mapStateToProps)(Checkout);