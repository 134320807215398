import { React, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { Loading } from "app/components/UI/Loader";
import { Service } from "app/config/service";
import { useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Container,
  createFilterOptions,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SimpleCard from "../../components/Cards/SimpleCard";
import colors from "../../style/colors";
import { AiOutlineRight, AiOutlineSearch } from "react-icons/ai";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import Images from "assets/Images.js";
import VendorCard from "app/components/VendorCard/vendorcard.js";
import "./Style.css";
import { RemoveSpace } from "app/utils";
import { useNavigate } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { GrDocument } from "react-icons/gr";

const useStyles = makeStyles({
  link: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.black,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: colors.secondary,
      transition: "0.3s ease-in-out",
    },
  },
});

function VendorFilter() {
  const classes = useStyles();
  const { productName } = useParams();
  const [loader, setLoader] = useState(false);
  const [searchData, setSearchData] = useState([]);
  let [vendorList, setVendorsList] = useState([]);
  let [load, setLoad] = useState(false);
  let [loadchk, setLoadchk] = useState(false);
  let [num, setNum] = useState(1);
  let [count, setCount] = useState(1);
  let navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  let [dataa, setdataa] = useState("")





  // Search Vendors --------------------------------------
  let SearchVender = (formData) => {

    if (formData !== "") {
      setVendorsList(() => [])
      getVendorList(formData.search, 1, false)
      setdataa(formData.search)
    }

  };

  // get Vendor List ----------------------------------------
  const getVendorList = async (vendorName, pageNum, check) => {
    setNum(pageNum);

    if (pageNum == 1) {
      setLoader(true);
    } else {
      setLoadchk(true);
    }
    try {
      const { Data, Status, ResponseCode } = await Service.getvendorfilter(
        vendorName,
        pageNum
      );

      setCount(Data.TotalCount);

      if (Status === true && ResponseCode === 200) {
        if (check) {
          setVendorsList([...vendorList, ...Data.Vendors]);
        }
        else {
          setVendorsList(Data.Vendors);
        }
      }
      else if (Status === false && ResponseCode === 404) {
        setVendorsList([]);
      }
    } catch (error) {
      console.log("🚀 ~ file: Header.js:288 ~ getVendorList ~ error", error);
    } finally {
      setLoader(false);
      setLoadchk(false);
    }
  };

  // Use Effect Api CAllll

  useEffect(() => {
    getVendorList("", 1, true);
    window.scrollTo({ top: 0 });
    setSearchData([]);
  }, [productName]);


  return (
    <Container>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "20px",
          alignItems: "center",
          mb: "10",
        }}
      >
        <Breadcrumbs
          separator={<AiOutlineRight fontSize="small" sx={{ mx: 1 }} />}
          aria-label="breadcrumb"
          sx={{
            mt: 3,
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Link to="/" className={classes.link}>
            Home / Vendors
          </Link>
          <Typography sx={{ fontWeight: 500 }}>{productName}</Typography>
        </Breadcrumbs>

        {/* search bar -----------------------> ==============================================================*/}
        <Box
          component="form"
          onSubmit={handleSubmit(SearchVender)}
          sx={{
            display: "flex",
            alignItems: "center",
            width: "300px",
            "& .MuiFormControl-root": { width: "100%" },
          }}
        >
          <TextField
            placeholder="Search bar"
            id="input-with-sx"
            variant="standard"
            name="search"

            {...register('search')}
          />
          <Button
            type="submit"
            variant="text"
            sx={{ "& .MuiInput-root": { p: "0" } }}
          >
            <SearchOutlinedIcon sx={{ color: "red" }} />
          </Button>
        </Box>
      </Grid>

      {/* vender render here-------------- */}
      {loader ? (
        <Loading />
      ) : (
        <Grid sx={{ mt: "40px" }}>
          <Grid container spacing={3} columns={{ sm: 12, md: 12, lg: 10 }}>
            {vendorList.length !== 0 ? (
              vendorList.map((item) => (
                <Grid
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(
                      `/shop/${RemoveSpace(item.Name)}/${item.AccountID}`
                    );
                  }}
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={2}
                >
                  <VendorCard data={item} />
                </Grid>
              ))
            ) : (
              <div className="Error">
                <img src={Images.notFound}></img>
                <h2>Oops No vendor found</h2>
              </div>
            )}
          </Grid>

          {/* Load More Button ------------------> */}
          {count > vendorList.length ? (
            <Box
              sx={{ textAlign: "center", my: 2 }}
              onClick={() => {
                getVendorList(dataa, num + 1, true);
              }}
            >
              <LoadingButton
                loading={loadchk}
                variant="contained"
                sx={{
                  bgcolor: colors.secondary,
                  "&:hover": { bgcolor: colors.secondaryDark },
                }}
              >
                Load More
              </LoadingButton>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      )}
    </Container>
  );
}

export default VendorFilter;
