import React, { useState, useEffect } from 'react'
import { Grid, Box, Typography, Container, Divider, TableContainer, Paper, Table, TableHead, TableRow, TableCell, tableCellClasses, TableBody, Chip, Toolbar } from '@mui/material'
import { styled } from '@mui/material/styles';

import colors from 'app/style/colors'

// *Import Component
import SideNav from 'app/components/SideNav/VendorSideNav'
import { ProductNotFound } from 'app/components/NotFound/NotFound'
import { Service } from 'app/config/service';
import { Loading } from 'app/components/UI/Loader';

// const sales = [
//   { customerName: "Muhammad Adeel", orderID: 1113, orderStatus: "Delivered", saleDate: "01/10/2022", paidAmount: "105120" },
//   { customerName: "Maaz", orderID: 1321, orderStatus: "Delivered", saleDate: "02/21/2022", paidAmount: "4500" },
//   { customerName: "Maaz", orderID: 1321, orderStatus: "Delivered", saleDate: "02/21/2022", paidAmount: "4500" },
// ]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: "bold"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    height: 40
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Sales() {

  // *For Sale Report
  let accountId = localStorage.getItem('vendorId')
  let token = localStorage.getItem('vendorToken')
  const [saleReport, setSaleReport] = useState([]);
  const [loading, setLoading] = useState(true)

  // *For Get Sale Report
  const getSaleReport = async () => {
    setLoading(true)
    try {
      let obj = {
        BusinessTypeID: 1,
        ReportID: 2, // *2 For Sale Report
        IsPagination: true,
        pageSize: 10,
        pageNumber: 1,
        VendorAccID: accountId,
      }
      const { Data } = await Service.getReports(obj, token);
      if (Data < 0) return
      setSaleReport(Data[0]?.ItemArray)
      setLoading(false)
    } catch (error) {
      console.log('file: Dashboard.js => line 24 => getTotalProduct => error', error)
    }
  };

  useEffect(() => {
    getSaleReport()
  }, [])

  return (
    <Container sx={{ my: 3 }}>
      <Grid container columnSpacing={4}>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "100%", height: 40, display: { xs: "block", md: "none" } }}
        >
          <Toolbar />
        </Box>
        <Grid item xs={12} md={3}>
          <SideNav />
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant={'h4'} sx={{ my: 2, fontWeight: "bold", color: colors.primary }}>My Sales</Typography>
          <Divider sx={{ mb: 2.5, mt: 1 }} />
          {loading ? (
            <Box sx={{ width: "100%" }}><Loading /></Box>
          ) : !saleReport.length ? (
            <ProductNotFound error="No Sales Found" />
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Order ID</StyledTableCell>
                    <StyledTableCell>Customer Name</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Sale Date</StyledTableCell>
                    <StyledTableCell>Price</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {saleReport.map((item, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell sx={{ py: 0.5 }} component="th" scope="row">
                        {item.OrderID}
                      </StyledTableCell>
                      <StyledTableCell sx={{ py: 0.5 }} >{item.CustomerName}</StyledTableCell>
                      <StyledTableCell sx={{ py: 0.5, width: 220 }} >
                        <Chip label={item.OrderStatus} sx={{
                          width: "50%",
                          bgcolor: item.OrderStatus === "Pending" ? colors.primary : item.OrderStatus === "Delivered" ? colors.darkGreen : "",
                          color: colors.white,
                          height: "22px"
                        }} />
                      </StyledTableCell>
                      <StyledTableCell sx={{ py: 0.5 }} >{item.SalesDate}</StyledTableCell>
                      <StyledTableCell sx={{ py: 0.5 }} >Rs.{item.TotalAmount}</StyledTableCell>

                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {/* <Grid container spacing={2}>
            {sales.map((orderData, index) => (
              <Grid key={index} item xs={4}>
                <Box sx={{ border: `1px solid ${colors.primary}`, color: colors.primary, p: 1, minHeight: "110px", borderRadius: "10px" }}>
                  <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    textAlign: "center",
                    borderBottom: `1px solid ${colors.primary}`

                  }}>
                    <Typography variant='body2' sx={{ fontWeight: "bold" }}>Order ID:<Typography variant='body2' component="span">{orderData.orderID}</Typography></Typography>
                    <Typography variant='body2' sx={{ fontWeight: "bold" }}>Order Status:&nbsp;<Typography variant='body2' color={colors.darkGreen} sx={{ fontWeight: "bold" }} component="span">{orderData.orderStatus}</Typography></Typography>
                  </Box>
                  <Box sx={{ my: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant='body2' sx={{ fontWeight: "bold" }}>Customer Name:</Typography>
                    <Typography variant='body2'>{orderData.customerName}</Typography>
                  </Box>
                  <Box sx={{ my: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant='body2' sx={{ fontWeight: "bold" }}>Sale Date:</Typography>
                    <Typography variant='body2'>{orderData.saleDate}</Typography>
                  </Box>
                  <Box sx={{ my: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant='body2' sx={{ fontWeight: "bold" }}>Paid Amount:</Typography>
                    <Typography variant='body2'>{orderData.paidAmount}</Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid> */}
          {/* {saleReport.length ? (null) : (<ProductNotFound error="No Sales Found" />)} */}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Sales