import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

import { AppRoutes } from './routes/routes';
import { CustomerRoutes } from './routes/CustomerRoutes';
import { VendorRoutes } from './routes/VendorRoutes';
import useAuth from './hooks/useAuth';
import Session from './views/Customer/Session/Session';

// *Import Components
import { Toaster } from './components/UI/Toaster';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { SalePersonRoute } from './routes/SalePersonRoute';
import SalesPersonLogin from './views/SalesPerson/Session/SalesPersonLogin';

const theme = createTheme({
  palette: {
    primary: {
      main: '#404F5E',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F14662',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "Open Sans",
      "HelveticaNeue-Light",
      "Helvetica Neue Light",
      "Helvetica Neue",
      " Helvetica, Arial",
      "Lucida Grande",
      " sans-serif",
    ].join(','),
  },
});

function App() {

  const { customer, vendor, salePerson, verifyToken } = useAuth();

  // const customer = localStorage.getItem('customerToken')
  // const vendor = localStorage.getItem('vendorToken')
  // const salePerson = localStorage.getItem('salePersonToken')

  useEffect(() => {
    verifyToken();
  })

  return (
    <BrowserRouter>

      {/* ========== Toaster ========== */}
      <Toaster />

      <ThemeProvider theme={theme}>

        {/* ========== Header ========== */}
        <Header />

        {/* ========== App Routes ========== */}
        <Routes>

          {AppRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.component}
            />
          ))}

          {/* ========== Customer Routes ========== */}
          <Route element={!customer ? <Outlet /> : <Navigate to="/" />} >
            <Route path="/session" element={<Session />} />
          </Route>

          <Route element={customer ? <Outlet /> : <Navigate to="/session" />}>
            {CustomerRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          {/* ========== Vendor Routes ========== */}
          <Route element={vendor ? <Outlet /> : <Navigate to="/" />}>
            {VendorRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          {/* ========== Sale Person Routes ========== */}
          <Route element={!salePerson ? <Outlet /> : <Navigate to="/salesperson/add-product" />} >
            <Route path="/uploader" element={<SalesPersonLogin />} />
          </Route>

          <Route element={salePerson ? <Outlet /> : <Navigate to="/uploader" />}>
            {SalePersonRoute.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

        </Routes>

        {/* ========== Footer ========== */}
        <Footer />

      </ThemeProvider>

    </BrowserRouter>
  );
}

export default App;
