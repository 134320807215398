import React, { useState } from 'react'
import { FormControl, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import useAuth from 'app/hooks/useAuth';
import { Service } from 'app/config/service';
import colors from 'app/style/colors';

// *Import Component
import { Error } from 'app/components/UI/Error';
import { SecondaryLoadButton } from 'app/components/UI/Button';
import { ErrorToaster, SuccessToaster } from 'app/components/UI/Toaster';
import { InputField } from 'app/components/UI/TextField';
// Import Change Password
import ResetPassword from "app/components/Dialog/ResetPassword"

function VendorLogin(props) {

  const { onChange, onClose } = props

  const { getVendorToken } = useAuth()

  const { register, handleSubmit, formState: { errors }, } = useForm();

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  let [open,setOpen]= useState(false)
  // *For Vendor Login
  const vendorLogin = async (data) => {
    setLoading(true)
    try {
      let obj = {
        username: data.phone + '-1', // *-1 For Vendor
        password: data.password,
        grant_type: 'password',
        BusinessTypeID: 1,
      }
      const { access_token, userName, AccountID } = await Service.login(obj);
      if (userName && access_token, AccountID) {
        SuccessToaster('Login Successfully!')
        localStorage.setItem('vendorToken', access_token)
        localStorage.setItem('vendorName', userName)
        localStorage.setItem('vendorId', AccountID)
        getVendorToken(access_token)
        onClose()
        navigate('/vendor')
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  };


// Open forget Password Model --------------------------------------------------------------------------------->
let openChangePassword =()=>
{
setOpen(!open)
}


  return (
    <>
   
    <Typography sx={{ mt: 2 }} component="form" onSubmit={handleSubmit(vendorLogin)}>
      <InputField
        size="medium"
        label="Phone Number"
        error={errors?.phone?.message}
        register={register("phone", {
          required: 'Please enter phone number.',
        })}
      />
      <InputField
        size="medium"
        label="Password"
        type="password"
        error={errors?.password?.message}
        register={register("password", {
          required: 'Please enter password.',
        })}
      />
      <Typography sx={{ color: colors.darkGray, textAlign: { xs: "center", sm: "right" }, mt: 2, fontSize: "14px" }}>
        Don't have an account?
        <Typography color="secondary" component="span" sx={{ cursor: "pointer", fontSize: "14px" }} onClick={() => onChange()} >&nbsp; Register</Typography>
      </Typography>

     


      <SecondaryLoadButton fullWidth loading={loading} type='submit' variant='contained'>Login</SecondaryLoadButton>
      <Typography onClick={()=>{openChangePassword()}} color="secondary" sx={{  textAlign: { xs: "center", sm: "right" }, mt: 2, fontSize: "14px" ,cursor:"pointer"}}>
        Forget your password
      </Typography>
    </Typography>


    <ResetPassword show={open} userType="-1" onClose={openChangePassword}/>
    </>
  )
}

export default VendorLogin