import { Apis, get, post } from ".";

// *Business Type ID
const businessTypeId = 1;

const qs = require("query-string");

export const Service = {
  login: async (obj) => {
    let result = await post(Apis.login, qs.stringify(obj));
    if (result.status === 200) return result.data;
    else throw result;
  },
  register: async (obj) => {
    let result = await post(Apis.register, obj);
    if (result.status === 200) return result.data;
    else throw result;
  },

  searchProducts: async (pageNo, itemName) => {
    let result = await post(
      Apis.searchProducts +
      "?BusinessTypeID=" +
      businessTypeId +
      "&pageSize=10&IsPagination=true&pageNumber=" +
      pageNo +
      "&ItemName=" +
      itemName
    );
    if (result.status === 200) return result.data;
    else throw result;
  },

  filterProducts: async (pageNo, itemName, catID, brandID) => {
    let result = await post(
      Apis.searchProducts +
      "?BusinessTypeID=" +
      businessTypeId +
      "&pageSize=10&IsPagination=true&pageNumber=" +
      pageNo +
      "&ItemName=" +
      itemName + catID + brandID
    );
    if (result.status === 200) return result.data;
    else throw result;
  },

  getBanner: async () => {
    let result = await get(Apis.banner + "?BusinessTypeID=" + businessTypeId);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getTopCategory: async () => {
    let result = await get(
      Apis.topCategory + "?BusinessTypeID=" + businessTypeId
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getVendorList: async (vendorName, pageNumber) => {
    let result = await get(
      Apis.vendorList +
      "?BusinessTypeID=" +
      businessTypeId +
      "&VendorName=" +
      vendorName +
      "&limit=" +
      20 +
      "&page=" +
      pageNumber
    );
    if (result.status === 200) return result.data;
    else throw result;
  },

  getvendorfilter: async (vendorname, pageno) => {
    let result = await get(
      Apis.vendorfilterdata +
      "?VendorName=" +
      vendorname +
      "&BusinessTypeID=" +
      businessTypeId +
      "&limit=" +
      20 +
      "&page=" +
      pageno
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getCategory: async () => {
    let result = await get(Apis.category + "?BusinessTypeID=" + businessTypeId);
    if (result.status === 200) return result.data;
    else throw result;
  },

  getFilterCategory: async (name, Pageno) => {
    let result = await get(Apis.categoryFilter + "?Search=" + name + "&BusinessTypeID=" + businessTypeId + "&limit=" + 20 + "&page=" + Pageno);
    if (result.status === 200) return result.data;
    else throw result;
  },


  // https://api.partzbuddy.com/api/Products/GetCategoryList?Search=B&BusinessTypeID=1&limit=10&page=1


  getSubCategory: async (id) => {
    let result = await get(
      Apis.getSubCategory +
      `?HeadCatID=${id}` +
      "&BusinessTypeID=" +
      businessTypeId
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getFeature: async () => {
    let result = await get(
      Apis.feature + "?BusinessTypeID=" + businessTypeId + "&limit=" + 20 + "&page=" + 1
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getNewArrival: async () => {
    let result = await get(
      Apis.newArrival + "?BusinessTypeID=" + businessTypeId
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getBestSeller: async () => {
    let result = await get(
      Apis.bestSeller + "?BusinessTypeID=" + businessTypeId
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getProduct: async () => {
    let result = await get(Apis.product + "?BusinessTypeID=" + businessTypeId);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getProductByProductId: async (productId, accountID) => {
    let result = await get(
      Apis.productByProductId +
      "?ItemID=" +
      productId +
      "&BusinessTypeID=" +
      businessTypeId + "&AccountID=" + accountID
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getBrands: async () => {
    let result = await get(
      Apis.getBrands + "?BusinessTypeID=" + businessTypeId
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getManufacturers: async () => {
    let result = await get(
      Apis.getManufacturers + "?BusinessTypeID=" + businessTypeId
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getVehicles: async () => {
    let result = await get(
      Apis.getVehicles + "?BusinessTypeID=" + businessTypeId
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getProfileDetail: async (accountId) => {
    let result = await get(Apis.getProfileDetail + "?AccountID=" + accountId);
    if (result.status === 200) return result.data;
    else throw result;
  },
  updateProfile: async (obj, token) => {
    let result = await post(Apis.updateProfile, qs.stringify(obj), token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  addRemoveFavourites: async (obj, token) => {
    let result = await post(Apis.addRemoveFavourites, obj, token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  changePassword: async (obj, token) => {
    let result = await post(Apis.changePassword, obj, token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getCustomerOrder: async (token) => {
    let result = await get(
      Apis.getCustomerOrder + "?BusinessTypeID=" + businessTypeId,
      token
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getVendorOrder: async (accountId) => {
    let result = await get(
      Apis.getVendorOrder +
      "?BusinessTypeID=" +
      businessTypeId +
      "&AccountID=" +
      accountId
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  addRemoveFavouriteItem: async (obj, token) => {
    let result = await post(Apis.addRemoveFavouriteItem, obj, token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getCustomerFavourite: async (accountId) => {
    let result = await get(
      Apis.getCustomerFavourite +
      "?AccountID=" +
      accountId +
      "&BusinessTypeID=" +
      businessTypeId
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getDeactivateProduct: async (accountId, pageNumber, itemName) => {
    let result = await get(
      Apis.getDeactivateProduct +
      "?BusinessTypeID=" +
      businessTypeId +
      "&AccountID=" +
      accountId +
      "&pageNumber=" +
      pageNumber +
      "&pageSize=10&IsPagination=true" +
      (itemName ? `&ItemName=${itemName}` : "")
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getVendorProduct: async (accountId, pageNumber, itemName) => {
    let result = await get(
      Apis.getVendorProduct +
      "?BusinessTypeID=" +
      businessTypeId +
      "&AccountID=" +
      accountId +
      "&pageNumber=" +
      pageNumber +
      "&pageSize=20&IsPagination=true" +
      (itemName ? `&ItemName=${itemName}` : "")
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  sendOtp: async (otp, number) => {
    let obj = {
      toNumber: number,
      apiKey: 'U2FsdGVkX1+XgpXOK6HgwdfUUVqmyFmZrCrCnueNqac=',
      message: `Your verification code is ${otp} from PartzBuddy.`
    }
    let result = await post(Apis.sendOtp, obj);
    if (result.status === 200) return result.data;
    else throw result;
  },
  placeOrder: async (obj, token) => {
    let result = await post(Apis.placeOrder, obj, token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getReports: async (obj, token) => {
    let result = await post(Apis.getReports, obj, token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  addProductImage: async (obj) => {
    let result = await post(Apis.addProductImage, obj);
    if (result.status === 200) return result.data;
    else throw result;
  },
  addProduct: async (obj, token) => {
    let result = await post(Apis.addProduct, qs.stringify(obj), token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  deleteProduct: async (obj, token) => {
    let result = await post(Apis.deleteProduct, qs.stringify(obj), token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  //*Out of Stock API
  outOfStockProduct: async (obj, token) => {
    let result = await post(Apis.outOfStockProduct, qs.stringify(obj), token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  //* In stock
  inStockProduct: async (obj, token) => {
    let result = await post(Apis.inStockProduct, qs.stringify(obj), token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  //*
  deactivateProduct: async (obj, token) => {
    let result = await post(Apis.deactivateProduct, qs.stringify(obj), token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  activateProduct: async (obj, token) => {
    let result = await post(Apis.activateProduct, qs.stringify(obj), token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  editProduct: async (obj, token) => {
    let result = await post(Apis.editProduct, qs.stringify(obj), token);
    if (result.status === 200) return result.data;
    else throw result;
  },

  getVendor: async () => {
    let result = await get(
      Apis.getVendor + "?BusinessTypeID=" + businessTypeId
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  orderStatus: async () => {
    let result = await get(Apis.orderStatus);
    if (result.status === 200) return result.data;
    else throw result;
  },
  addProductBySalePerson: async (obj, token) => {
    let result = await post(
      Apis.addProductBySalePerson,
      qs.stringify(obj),
      token
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  changeOrderStatus: async (obj, token) => {
    let result = await post(Apis.changeOrderStatus, obj, token);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getProductsFilterByPage: async (pageNumber) => {
    let result = await get(
      Apis.getProductsFilterByPage + "?BusinessTypeID=" + businessTypeId + "&limit=" + 20 + "&page=" + pageNumber
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  getProductsCategoryIDWise: async (categoryTypeId, pageNumber) => {
    let result = await get(
      Apis.getProductsCategoryIDWise +
      "?CategoryID=" +
      categoryTypeId +
      "&BusinessTypeID=" +
      businessTypeId +
      "&limit=" +
      20 +
      "&page=" +
      pageNumber
    );
    if (result.status === 200) return result.data;
    else throw result;
  },

  // Forget Your Password ----------------------------------->

  forgetPassword: async (obj) => {
    console.log("=========> obj from Api folder", obj)
    let result = await post(
      Apis.forgetPassword,
      qs.stringify(obj)

    );
    if (result.status === 200) return result.data;
    else throw result;
  },

  resetPassword: async (obj) => {

    let result = await post(
      Apis.resetPassword,
      qs.stringify(obj)

    );
    if (result.status === 200) return result.data;
    else throw result;
  },



  // delete Order from Vendor Side By ID -------------------------------------------------------->
  deleteOrderByID: async (OrderID, token) => {
    console.log("My Token is here ----------------------------->", token)
    let result = await post(
      Apis.deleteOrderByIDVendorPanel + "?OrderID=" + OrderID, {}, token
    );
    if (result.status === 200) return result.data
    else throw result
  }
};
