import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Container, Box, Typography, Toolbar } from '@mui/material';
import { ShoppingCartCheckoutOutlined, CampaignOutlined, Inventory2Outlined, StarBorderOutlined, LoyaltyOutlined, FactCheckOutlined, AddCircleOutline } from '@mui/icons-material';

import colors from 'app/style/colors';
import { Service } from 'app/config/service';

// *Import Component
import DashboardCard from 'app/components/Cards/DashboardCard';
import DashboardDetailCard from 'app/components/Cards/DashboardDetailcard';
import VendorSideNav from 'app/components/SideNav/VendorSideNav';

export default function Dashboard() {

  let accountId = parseInt(localStorage.getItem('vendorId'))
  let token = localStorage.getItem('vendorToken')

  // *For Total Product
  const [totalProduct, setTotalProduct] = useState(0);

  // *For Order
  const [order, setOrder] = useState(0);

  // *For Revenue
  const [revenue, setRevenue] = useState(0);

  // *For Sold Product
  const [soldProduct, setSoldProduct] = useState(0);

  // *For Get Total Product
  const getTotalProduct = async () => {
    try {
      const { Data } = await Service.getVendorProduct(accountId);
      if (Data === null) return
      setTotalProduct(Data[0]?.TotalItem)
    } catch (error) {
      console.log('file: Dashboard.js => line 37 => getTotalProduct => error', error);
    }
  };

  // *For Get Sale Report
  const getSaleReport = async () => {
    try {
      let obj = {
        BusinessTypeID: 1,
        ReportID: 2, // *2 For Sales Report
        IsPagination: true,
        pageSize: 10,
        pageNumber: 1,
        VendorAccID: accountId,
      }
      const { Data } = await Service.getReports(obj, token);
      if (Data === -5) return
      setOrder(Data[0]?.TotalItem)
      let TotalAmount = 0
      for (let index = 0; index < Data[0]?.ItemArray.length; index++) {
        const amount = Data[0]?.ItemArray[index]?.ItemTotalPrice;
        TotalAmount += amount
      }
      setRevenue(TotalAmount)
    } catch (error) {
      console.log('file: Dashboard.js => line 61 => getSaleReport => error', error);
    }
  };

  // *For Get Sold Product Report
  const getSoldProductReport = async () => {
    try {
      let obj = {
        BusinessTypeID: 1,
        ReportID: 3, // *3 For Sold Product
        IsPagination: true,
        pageSize: 10,
        pageNumber: 1,
        VendorAccID: accountId,
      }
      const { Data } = await Service.getReports(obj, token);
      if (Data === -5) return
      setSoldProduct(Data[0]?.TotalItem)
    } catch (error) {
      console.log('file: Dashboard.js => line 24 => getTotalProduct => error', error)
    }
  };

  useEffect(() => {
    getTotalProduct()
    getSaleReport()
    getSoldProductReport()
  }, [])

  return (
    <Container sx={{ my: 3 }}>
      <Grid container columnSpacing={4}>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "100%", height: 40, display: { xs: "block", md: "none" } }}
        >
          <Toolbar />
        </Box>
        <Grid item xs={12} md={3}>
          <VendorSideNav />
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container sx={{ mb: 10 }} spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCard name="Revenue" amount={revenue} color={colors.secondary} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCard name="Products Sold" amount={soldProduct} color={colors.darkGreen} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCard name="Products" amount={totalProduct} color={colors.textSecondary} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCard name="Orders" amount={order} color={colors.purple} />
            </Grid>
          </Grid>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              <Link to="/vendor/quick-add" style={{ textDecoration: "none", color: colors.primary }}>
                <Box sx={{
                  bgcolor: colors.secondary,
                  color: colors.white,
                  borderRadius: "10px",
                  p: 2,
                  boxShadow: 3,
                  cursor: "pointer",
                }}>
                  <Typography variant='h5'>Quick Add</Typography>
                  <Box sx={{ textAlign: "right", }}>
                    <AddCircleOutline sx={{ fontSize: "40px", color: colors.white }} />
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DashboardDetailCard name="Orders" icon={<ShoppingCartCheckoutOutlined sx={{ fontSize: "40px", color: colors.darkGreen }} />} path="/vendor/my-orders" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DashboardDetailCard name="Promotions" icon={<CampaignOutlined sx={{ fontSize: "40px", color: colors.darkGreen }} />} path="/vendor/promotions" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DashboardDetailCard name="Products" icon={<Inventory2Outlined sx={{ fontSize: "40px", color: colors.darkGreen }} />} path="/vendor/products" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DashboardDetailCard name="Reviews" icon={<StarBorderOutlined sx={{ fontSize: "40px", color: colors.darkGreen }} />} path="/vendor/reviews" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DashboardDetailCard name="Sales" icon={<LoyaltyOutlined sx={{ fontSize: "40px", color: colors.darkGreen }} />} path="/vendor/sales" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DashboardDetailCard name="Reports" icon={<FactCheckOutlined sx={{ fontSize: "40px", color: colors.darkGreen }} />} path="/vendor/reports" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
