import { SuccessToaster } from "app/components/UI/Toaster";
import { useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function useProvideAuth() {

  const [customer, setCustomer] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [salePerson, setSalePerson] = useState(null);

  // *Customer
  const getCustomerToken = (token) => {
    setCustomer(token)
  };

  const customerLogout = () => {
    SuccessToaster('Logout Successfully!')

    localStorage.removeItem('customerToken')
    localStorage.removeItem('customerId')
    setCustomer(null)
  };

  // *Vendor
  const getVendorToken = (token) => {
    setVendor(token)
  };

  const vendorLogout = () => {
    SuccessToaster('Logout Successfully!')
    localStorage.removeItem('vendorToken')
    localStorage.removeItem('vendorName')
    localStorage.removeItem('vendorId')
    setVendor(null)
  };

  // *Sale Person
  const getSalePersonToken = (token) => {
    setSalePerson(token)
  };

  const salePersonLogout = (token) => {
    localStorage.removeItem('salePersonToken')
    localStorage.removeItem('salePersonId')
    localStorage.removeItem('salePersonName')
    setSalePerson(null)
  };

  // *Verify Token
  const verifyToken = () => {
    try {
      const customer = localStorage.getItem('customerToken')
      const vendor = localStorage.getItem('vendorToken')
      const salePerson = localStorage.getItem('salePersonToken')
      setCustomer(customer)
      setVendor(vendor)
      setSalePerson(salePerson)
    } catch (error) {
      console.log('file: useProvideAuth.js => line 54 => verifyToken => error', error)
    }
  };

  return {
    customer,
    getCustomerToken,
    customerLogout,
    vendor,
    getVendorToken,
    vendorLogout,
    salePerson,
    getSalePersonToken,
    salePersonLogout,
    verifyToken
  };
}

export default useProvideAuth;