import { Box, Typography } from '@mui/material'
import React from 'react'

import colors from 'app/style/colors'

function DashboardCard(props) {

  const { name, amount, color } = props

  return (
    <Box sx={{ bgcolor: color, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
      <Box sx={{ textAlign: "center", py: 4, px: 2, color: colors.white, }}>
        <Typography variant='body1' sx={{ fontWeight: "bold" }} >{name}</Typography>
        <Typography variant='body1' sx={{ fontWeight: "500" }} >{amount}</Typography>
      </Box>
    </Box>
  )
}

export default DashboardCard