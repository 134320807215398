import React, { useEffect, useState } from 'react';
import { Grid, Typography, Container, Divider, Box, TableContainer, Table, TableHead, TableRow, TableCell, tableCellClasses, TableBody, Paper, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Service } from 'app/config/service';
import colors from 'app/style/colors';

// *Import Component
import VendorSideNav from 'app/components/SideNav/VendorSideNav';
import { Loading } from 'app/components/UI/Loader';
import { ProductNotFound } from 'app/components/NotFound/NotFound';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: "bold"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    height: 40
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function RevenueReport() {

  let accountId = localStorage.getItem('vendorId')
  let token = localStorage.getItem('vendorToken')
  const [loading, setLoading] = useState(true)

  // *For Revenue
  const [revenue, setRevenue] = useState([]);

  // *For Get Revenue Report
  const getRevenueReport = async () => {
    setLoading(true)
    try {
      let obj = {
        BusinessTypeID: 1,
        ReportID: 1, // *1 For Revenue Report
        IsPagination: true,
        pageSize: 10,
        pageNumber: 1,
        VendorAccID: accountId,
      }
      const { Data } = await Service.getReports(obj, token);
      if (Data < 0) return
      setRevenue(Data[0]?.ItemArray)
      setLoading(false)
    } catch (error) {
      console.log('file: Dashboard.js => line 24 => getTotalProduct => error', error)
    }
  };

  useEffect(() => {
    getRevenueReport()
  }, []);

  return (
    <Container sx={{ my: 3 }}>
      <Grid container columnSpacing={4}>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "100%", height: 40, display: { xs: "block", md: "none" } }}
        >
          <Toolbar />
        </Box>
        <Grid item xs={12} md={3}>
          <VendorSideNav />
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant={'h4'} sx={{ my: 2, fontWeight: "bold", color: colors.primary }}>Revenue Reports</Typography>
          <Divider sx={{ mb: 2.5, mt: 1 }} />
          {loading ? (
            <Box sx={{ width: "100%" }}><Loading /></Box>
          ) : !revenue.length ? (
            <ProductNotFound error="No Data Found" />
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S.No</StyledTableCell>
                    <StyledTableCell>Customer Name</StyledTableCell>
                    <StyledTableCell>Amount</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {revenue.map((item, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell sx={{ py: 0.5, width: 32, textAlign: 'center' }} component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell sx={{ py: 0.5 }} >{item.CustomerName}</StyledTableCell>
                      <StyledTableCell sx={{ py: 0.5 }} >Rs {item.TotalRevenue}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default RevenueReport