import { Button, IconButton } from "@mui/material"
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai"

import colors from "app/style/colors"
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";

export const CartButton = (props) => {

  const { addToCartHandler } = props;

  return (
    <IconButton className="cart-btn" sx={{ p: 1, bgcolor: colors.primary, '&:hover': { bgcolor: colors.primaryLight } }} onClick={() => addToCartHandler}>
      <AiOutlineShoppingCart style={{ color: colors.white, fontSize: 18, }} />
    </IconButton>
  )
}

export const WishButton = () => {
  return (
    <IconButton sx={{ mr: 0.5, p: 1, bgcolor: colors.gray, '&:hover': { bgcolor: colors.secondary, transition: '0.3s ease-in-out' }, }} >
      <AiOutlineHeart style={{ color: colors.white, fontSize: 18, }} />
    </IconButton>
  )
}


export const PrimaryLoadButton = (props) => {
  return (
    <LoadingButton {...props} sx={{ p: 1, my: 2, borderRadius: '20px', minWidth: 150 }} >
      {props.children}
    </LoadingButton>
  )
}

export const SecondaryLoadButton = (props) => {
  return (
    <LoadingButton {...props} sx={{ my: 2, py: 1 }} >
      {props.children}
    </LoadingButton>
  )
}


export const PrimaryBasicButton = (props) => {
  return (
    <Button
      {...props}
      sx={{
        fontSize: "14px",
        borderRadius: '20px',
        textTransform: 'capitalize',
        ml: 2,
      }}
    >
      {props.children}
    </Button>
  )
}