import React, { useEffect, useState } from 'react'
import { Grid, Typography, Container, Divider, Box, Toolbar, colors } from '@mui/material'

import Colors from 'app/style/colors'
import { Service } from 'app/config/service'

// *Import Components
import { ProductNotFound } from 'app/components/NotFound/NotFound'
import SideNav from 'app/components/SideNav/VendorSideNav'
import StoreCard from 'app/components/Cards/StoreCard'
import { Loading } from "app/components/UI/Loader";
import { LoadingButton } from '@mui/lab'

function Store() {


  const [productList, setProductList] = useState([]);
  const [MainLoading, setMainLoading] = useState(false)
  const [Loadingchk, setLoadingchk] = useState()
  const [subLoad, setSubLoad] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  // *Get product Api
  const getProduct = async (pageNum) => {
    try {
      setPageNo(prev => prev + 1)
      if (pageNum === 1) {
        setMainLoading(true)
      }
      else {
        setSubLoad(true)
      }

      const accountId = localStorage.getItem('vendorId')

      const { Data, Status, ResponseCode } = await Service.getVendorProduct(accountId, pageNo)
      setLoadingchk(Data[0].ItemArray.length)
      if (Status === true && ResponseCode === 200) {
        setProductList([...productList, ...Data[0]?.ItemArray])
      }
    } catch (error) {
      console.log('file: Products.js => line 23 => getProduct => error', error)
    }
    finally {
      setMainLoading(false)
      setSubLoad(false)
    }
  }

  useEffect(() => {
    getProduct(1)
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container sx={{ my: 3 }}>
      <Grid container columnSpacing={4}>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "100%", height: 40, display: { xs: "block", md: "none" } }}
        >
          <Toolbar />
        </Box>
        <Grid item xs={12} md={3}>
          <SideNav />
        </Grid>
        {
          MainLoading ?
            <Grid item md="9">
              <Loading />
            </Grid>
            :
            <Grid item xs={12} md={9}>
              <Typography variant={'h4'} sx={{ my: 2, fontWeight: "bold", color: Colors.primary }}>My Store</Typography>
              <Divider sx={{ mb: 2.5, mt: 1 }} />
              {productList.length ? (
                <Box sx={{ my: 2 }}>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    {productList.map((item, index) => (
                      <Grid key={index} item md={3}>
                        <StoreCard product={item} />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid
                    item
                    xs="12"
                    display={"flex"}
                    justifyContent="center"
                    mt="20px"
                  >
                    {
                      Loadingchk === 20 &&
                      <LoadingButton
                        loading={subLoad}
                        variant="contained"
                        sx={{
                          bgcolor: Colors.secondary,
                          "&:hover": { bgcolor: Colors.secondaryDark },
                        }}
                        onClick={() => {
                          getProduct(pageNo);
                        }}
                      >
                        Load More
                      </LoadingButton>
                    }
                  </Grid>
                </Box>
              ) : (
                <ProductNotFound error="No Product Found" />
              )}
            </Grid>
        }

      </Grid>
    </Container>
  )
}

export default Store