import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';

function CheckoutDialog(props) {

  const { open, onClose, guestCheckout, guestLoading } = props

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" >

      <DialogTitle sx={{ textAlign: 'center', width: 350, mb: 1 }} >
        How would you like to place order?
      </DialogTitle>

      <DialogContent sx={{ px: 4.5, textAlign: 'center' }} >
        <LoadingButton loading={guestLoading} variant='text' color='primary' onClick={() => guestCheckout()} sx={{ boxShadow: 2, mx: 1, width: 110 }} >As Guest</LoadingButton>
        <Button disabled={guestLoading} component={Link} to="/my-account" variant='contained' color='primary' sx={{ boxShadow: 2, mx: 1, width: 110 }} >login</Button>
      </DialogContent>

    </Dialog>
  );
}

export default CheckoutDialog; 