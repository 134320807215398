import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

function MapModal(props) {

  const { onClose, open, currentLocation, locationChange } = props

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBJ3IVlUfKfOT9ra-80Fex_t_hM5mfigro"
  })

  const center = {
    lat: currentLocation.latitude,
    lng: currentLocation.longitude
  };

  const containerStyle = {
    width: '400px',
    height: '400px'
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { borderRadius: "10px" } }}
      open={open}
      onClose={onClose} >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Location <span style={{ fontSize: "10px" }}>(Drag marker to change your location)</span>
      </DialogTitle>
      <DialogContent dividers>
        {isLoaded &&
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
          >
            <Marker
              position={center}
              draggable
              onDragEnd={(e) => locationChange(e.latLng)}
            />
          </GoogleMap>
        }
      </DialogContent>
      <DialogActions>
        <Button variant='contained' autoFocus onClick={onClose}>
          Save Location
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MapModal