import React, { useState } from 'react';
import { Box, Divider, FormControl, OutlinedInput, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import colors from 'app/style/colors';
import { Service } from 'app/config/service';
import useAuth from 'app/hooks/useAuth';

// *Import Components
import { Error } from 'app/components/UI/Error';
import ResetPassword from 'app/components/Dialog/ResetPassword';
import { PrimaryLoadButton } from 'app/components/UI/Button';
import { ErrorToaster, SuccessToaster } from 'app/components/UI/Toaster';

function CustomerLogin() {

  const { getCustomerToken } = useAuth()

  // *For Form Validation
  const { register, formState: { errors }, handleSubmit } = useForm();



  const [loading, setLoading] = useState(false);

  const [resetDialog, setResetDialog] = useState(false);

  // *For Customer Login
  const customerLogin = async (data) => {
   
    setLoading(true)
    try {
      let obj = {
        username: data.phone + '-2', // *-2 For Customer
        password: data.password,
        grant_type: 'password',
        BusinessTypeID: 1,
      }
      const { access_token, userName, AccountID } = await Service.login(obj);
      if (userName && access_token && AccountID) {
        SuccessToaster('Login Successfully!')
        localStorage.setItem('customerToken', access_token)
        localStorage.setItem('customerId', AccountID)
        getCustomerToken(access_token)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  };

  // *For Reset Dialog
  const handleResetDialog = () => {

    setResetDialog(!resetDialog)
  }

  return (
    <Box>
      {/* ====== Reset Password Dialog ====== */}
      <ResetPassword show={resetDialog} onClose={handleResetDialog} userType="-2" />

      <Typography variant="h5" color="initial">Login</Typography>
      <Divider sx={{ width: '25%', height: '2px', bgcolor: colors.primary, my: 1.5 }} />
      <Typography variant="body1" color="initial" sx={{ mt: 3 }} >Welcome back! Sign in to your account.</Typography>
      <Typography component="form" onSubmit={handleSubmit(customerLogin)} sx={{ my: 2.5, mb: 3 }} >
  
        <FormControl variant="standard" sx={{ width: '100%', my: 1 }} >
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>Phone</Typography>
          <OutlinedInput
            className="number-field"
            type="number"
            error={errors?.phone?.message && (true)}
            sx={{ width: '100%', borderRadius: '40px' }}
            {...register("phone", {
              required: 'Please enter phone number.',
            })}
          />
          {errors?.phone?.message && (
            <Error message={errors?.phone?.message} />
          )}
        </FormControl>
        <FormControl variant="standard" sx={{ width: '100%', my: 1 }} >
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>Password</Typography>
          <OutlinedInput
            type="password"
            error={errors?.password?.message && (true)}
            sx={{ width: '100%', borderRadius: '40px' }}
            {...register("password", {
              required: 'Please enter password.',
            })}
          />
          {errors?.password?.message && (
            <Error message={errors?.password?.message} />
          )}
        </FormControl>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <PrimaryLoadButton type="submit" variant="contained" loading={loading} >Login</PrimaryLoadButton>

          <Typography variant='body2' onClick={handleResetDialog} sx={{ cursor: "pointer", "&:hover": { color: colors.secondary } }}>Forgot Your Password?</Typography>
        </Box>
      </Typography>
    </Box>
  );
}

export default CustomerLogin;