
import { Service } from 'app/config/service';
import imageCompression from 'browser-image-compression';


const imageCompress = async (e) => {
  console.log("🚀 ~ file: imageCompress.js:7 ~ imageCompress ~ e", e)
  const imageFile = e.target.files[0];
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1600,
    useWebWorker: true
  }
  const compressedFile = await imageCompression(imageFile, options);
  let formData = new FormData();
  formData.append("file", compressedFile, compressedFile.name);
  // const formData = new FormData()
  // formData.append('file', e.target.files[0])
  const { Data } = await Service.addProductImage(formData)
  return Data;
}

export default imageCompress