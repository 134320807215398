import React from 'react'
import { Grid, Typography, Container, Divider, Box, Toolbar, Button } from '@mui/material'
import { useNavigate } from "react-router-dom"
import colors from 'app/style/colors'

// *Import Component
import SideNav from 'app/components/SideNav/VendorSideNav'

function Reports() {

  const navigate = useNavigate()

  return (
    <Container sx={{ my: 3 }}>
      <Grid container columnSpacing={4}>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "100%", height: 40, display: { xs: "block", md: "none" } }}
        >
          <Toolbar />
        </Box>
        <Grid item xs={12} md={3}>
          <SideNav />
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant={'h4'} sx={{ my: 2, fontWeight: "bold", color: colors.primary }}>My Reports</Typography>
          <Divider sx={{ mb: 2.5, mt: 1 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <Button onClick={() => navigate("/vendor/revenue-report")} fullWidth variant='contained' sx={{
                bgcolor: colors.darkGreen, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px", "&:hover": {
                  bgcolor: "#2abfab"
                }
              }}>
                <Box sx={{ textAlign: "center", py: 4, px: 2, color: colors.white, }}>
                  <Typography variant='body1' sx={{ fontWeight: "bold" }} >Revenue Report</Typography>
                </Box>
              </Button>
            </Grid>
            <Grid item xs={12} >
              <Button onClick={() => navigate("/vendor/sales-report")} fullWidth variant='contained' sx={{
                bgcolor: colors.secondary, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px", "&:hover": {
                  bgcolor: "#c63f3f"
                }
              }}>
                <Box sx={{ textAlign: "center", py: 4, px: 2, color: colors.white, }}>
                  <Typography variant='body1' sx={{ fontWeight: "bold" }} >Sales Report</Typography>
                </Box>
              </Button>
            </Grid>
            <Grid item xs={12} >
              <Button onClick={() => navigate("/vendor/product-sales-report")} fullWidth variant='contained' sx={{ bgcolor: colors.primary, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                <Box sx={{ textAlign: "center", py: 4, px: 2, color: colors.white, }}>
                  <Typography variant='body1' sx={{ fontWeight: "bold" }} >Product Sales Report</Typography>
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Reports