import React, { Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { List, Divider, ListItemIcon, ListItemText, Collapse, ListItemButton, Box } from '@mui/material';
import { ExpandLess, ExpandMore, AddCircleOutline, StoreMallDirectoryOutlined, ShoppingCartCheckoutOutlined, PersonOutlineOutlined, DashboardCustomizeOutlined, CampaignOutlined, Inventory2Outlined, StarBorderOutlined, LoyaltyOutlined, FactCheckOutlined, ExitToAppOutlined } from '@mui/icons-material';
import { AiOutlineMenu } from 'react-icons/ai';

import useAuth from 'app/hooks/useAuth';
import colors from 'app/style/colors';

const list = [
  { label: 'Dashboard', path: '/vendor', icon: <DashboardCustomizeOutlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} /> },
  { label: 'Profile', path: '/vendor/profile', icon: <PersonOutlineOutlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} /> },
  { label: 'Add Product', path: '/vendor/add-products', icon: <AddCircleOutline sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} /> },
  { label: 'Orders', path: '/vendor/my-orders', icon: <ShoppingCartCheckoutOutlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} /> },
  { label: 'Promotions', path: '/vendor/promotions', icon: <CampaignOutlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} /> },
  { label: 'Store', path: '/vendor/store', icon: <StoreMallDirectoryOutlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} /> },
  { label: 'Products', path: '/vendor/products', icon: <Inventory2Outlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} /> },
  { label: 'Deactivated Products', path: '/vendor/deactivate-products', icon: <Inventory2Outlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} /> },
  // { label: 'Reviews', path: '/vendor/reviews', icon: <StarBorderOutlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} /> },
  { label: 'Sales', path: '/vendor/sales', icon: <LoyaltyOutlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} /> },
];

const reports = [
  { reportName: "Revenue Report", path: "/vendor/revenue-report" },
  { reportName: "Sales Report", path: "/vendor/sales-report" },
  { reportName: "Product Sales Report", path: "/vendor/product-sales-report" },
];

function VendorSideNav() {

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { vendorLogout } = useAuth()

  const [reportOpen, setReportOpen] = useState(false);
  const [openSideNav, setOpenSideNav] = useState(false);
  const [selectedReport, setSelectedReport] = useState("Revenue Report")

  const handleClick = () => {
    setReportOpen(!reportOpen);
  };
  const handleSideNav = () => {
    setOpenSideNav(!openSideNav);
  };

  const handleSelectedReport = (val) => {
    setReportOpen(!reportOpen);
    setSelectedReport(val.reportName)
    navigate(val.path)
  }

  return (
    <Fragment>
      <Box
        variant="permanent"
        sx={{
          bgcolor: colors.primary,
          position: "relative",
          justifyContent: "space-around",
          width: "100%",
          borderRadius: "10px",
          display: { xs: "none", md: "block" }
        }}
      >

        <List sx={{ pl: "8px", mr: "8px" }}>
          {list.map((item, index) => (
            <ListItemButton
              key={index}
              onClick={() => { navigate(item.path) }}
              selected={pathname === item.path ? true : false}
              sx={{
                display: "flex",
                alignItems: "center",
                my: 0.4,
                py: 1.5,
                borderRadius: "10px",
                color: colors.lightGray,
                '.MuiSvgIcon-root': {
                  color: colors.lightGray,
                },
                '&:hover': {
                  bgcolor: colors.primaryLight,
                  '&>.MuiTypography-root': {
                    color: colors.white,
                  },
                  '.MuiSvgIcon-root': {
                    color: colors.secondary,
                  },
                  cursor: 'pointer',
                },
                '&.Mui-selected': {
                  bgcolor: colors.secondary
                },
              }}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          ))}


          {/* ========== Reports list ========== */}
          <ListItemButton
            onClick={handleClick}
            selected={pathname === "/vendor/reports" ? true : false}
            sx={{
              py: 1.5,
              color: colors.lightGray,
              borderRadius: "10px",
              '.MuiSvgIcon-root': {
                color: colors.lightGray,
              },
              '&:hover': {
                bgcolor: colors.primaryLight,
                color: colors.white,
                cursor: 'pointer',
                '.MuiSvgIcon-root': {
                  color: colors.secondary,
                },
              },
              '&.Mui-selected': {
                bgcolor: colors.secondary
              },
            }}>
            <ListItemIcon>
              <FactCheckOutlined />
            </ListItemIcon>
            <ListItemText primary="Reports" />
            {reportOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={reportOpen} timeout="auto" unmountOnExit>
            {reports.map((value, i) => (
              <List key={i} component="div" disablePadding>
                <ListItemButton
                  onClick={() => handleSelectedReport(value)}
                  sx={{
                    pl: 9,
                    color: pathname === value.path ? colors.secondary : colors.lightGray,
                    bgcolor: pathname === value.path ? colors.primaryLight : colors.primary,
                    borderRadius: "10px",
                    my: 0.5,
                    '&:hover': {
                      bgcolor: colors.primaryLight,
                      color: colors.white,
                      cursor: 'pointer',
                    },
                  }}

                >
                  <ListItemText primary={`${value.reportName}`} />
                </ListItemButton>
              </List>
            ))}

          </Collapse>
        </List>

        <Divider />

        <List sx={{ pl: "8px", mr: "8px" }}>
          <ListItemButton onClick={() => { vendorLogout() }}
            sx={{
              py: 1.5,
              color: colors.lightGray,
              borderRadius: "10px",
              '.MuiSvgIcon-root': {
                color: colors.lightGray,
              },
              '&:hover': {
                bgcolor: colors.primaryLight,
                color: colors.white,
                cursor: 'pointer',
                '.MuiSvgIcon-root': {
                  color: colors.secondary,
                },
              },
            }}>
            <ListItemIcon>
              <ExitToAppOutlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} />
            </ListItemIcon>
            <ListItemText primary='Log Out' />
          </ListItemButton>
        </List>

      </Box>

      {/* ====== Responsive SideNav ====== */}
      <Box
        variant="permanent"
        sx={{
          bgcolor: colors.primary,
          position: "relative",
          justifyContent: "space-around",
          width: "100%",
          borderRadius: "10px",
          mb: 2,
          py: 1,
          display: { xs: "block", md: "none" }
        }}
      >
        <ListItemButton onClick={handleSideNav}>
          <ListItemIcon>
            <AiOutlineMenu size={22} style={{ color: colors.white }} />
          </ListItemIcon>
          <ListItemText primary="Menu" sx={{ color: colors.white }} />
          {openSideNav ? <ExpandLess color='secondary' /> : <ExpandMore color='secondary' />}
        </ListItemButton>
        <Collapse in={openSideNav} timeout="auto" unmountOnExit>
          {list.map((item, index) => (
            <Fragment>
              <List sx={{ pl: "8px", mr: "8px" }}>
                <ListItemButton
                  key={index}
                  onClick={() => { navigate(item.path) }}
                  selected={pathname === item.path ? true : false}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    my: 0.4,
                    py: 1.5,
                    borderRadius: "10px",
                    color: colors.lightGray,
                    '.MuiSvgIcon-root': {
                      color: colors.lightGray,
                    },
                    '&:hover': {
                      bgcolor: colors.primaryLight,
                      '&>.MuiTypography-root': {
                        color: colors.white,
                      },
                      '.MuiSvgIcon-root': {
                        color: colors.secondary,
                      },
                      cursor: 'pointer',
                    },
                    '&.Mui-selected': {
                      bgcolor: colors.secondary
                    },
                  }}
                >
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </List>
            </Fragment>

          ))}
          <List sx={{ pl: "8px", mr: "8px" }}>

            {/* ========== Reports List ========== */}
            <ListItemButton
              onClick={handleClick} sx={{
                py: 1.5,
                color: colors.lightGray,
                borderRadius: "10px",
                '.MuiSvgIcon-root': {
                  color: colors.lightGray,
                },
                '&:hover': {
                  bgcolor: colors.primaryLight,
                  color: colors.white,
                  cursor: 'pointer',
                  '.MuiSvgIcon-root': {
                    color: colors.secondary,
                  },
                },
              }}>
              <ListItemIcon>
                <FactCheckOutlined />
              </ListItemIcon>
              <ListItemText primary="Reports" />
              {reportOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={reportOpen} timeout="auto" unmountOnExit>
              {reports.map((value, i) => (
                <List key={i} component="div" disablePadding>
                  <ListItemButton
                    onClick={() => handleSelectedReport(value)}
                    sx={{
                      pl: 9,
                      color: pathname === value.path ? colors.secondary : colors.lightGray,
                      bgcolor: pathname === value.path ? colors.primaryLight : colors.primary,
                      borderRadius: "10px",
                      my: 0.5,
                      '&:hover': {
                        bgcolor: colors.primaryLight,
                        color: colors.white,
                        cursor: 'pointer',
                      },
                    }}

                  >
                    <ListItemText primary={`${value.reportName}`} />
                  </ListItemButton>
                </List>
              ))}

            </Collapse>
          </List>
          <Divider />
          <List sx={{ pl: "8px", mr: "8px" }}>
            <ListItemButton onClick={() => { vendorLogout() }}
              sx={{
                py: 1.5,
                color: colors.lightGray,
                borderRadius: "10px",
                '.MuiSvgIcon-root': {
                  color: colors.lightGray,
                },
                '&:hover': {
                  bgcolor: colors.primaryLight,
                  color: colors.white,
                  cursor: 'pointer',
                  '.MuiSvgIcon-root': {
                    color: colors.secondary,
                  },
                },
              }}>
              <ListItemIcon>
                <ExitToAppOutlined sx={{ fontSize: 22, verticalAlign: 'bottom', mr: 1 }} />
              </ListItemIcon>
              <ListItemText primary='Log Out' />
            </ListItemButton>
          </List>
        </Collapse>

      </Box>
    </Fragment>
  );
}

export default VendorSideNav
