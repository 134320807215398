import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Container, Grid, Typography, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MdOutlineHeadsetMic, MdRssFeed } from "react-icons/md";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsWhatsapp,
  BsYoutube,
} from "react-icons/bs";

import colors from "app/style/colors";
import { CurrentYear } from "app/utils";
import Images from "assets/Images";

// *Import Components
import SalesPersonLogin from "../../components/Dialog/SalesPersonLogin";

const useStyles = makeStyles({
  link: {
    fontSize: "15px",
    color: colors.white,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: colors.secondary,
      transition: "0.3s ease-in-out",
    },
  },
  icon: {
    fontSize: "24px",
    color: colors.lightGray,
    marginLeft: "8px",
    marginRight: "16px",
    cursor: "pointer",
    "&:hover": {
      color: colors.secondary,
      transition: "0.2s ease-in-out",
    },
  },
});

function Footer() {
  const classes = useStyles();

  const navigate = useNavigate();
  const [resetDialog, setResetDialog] = useState(false);

  const Information = [
    { label: "About Us", path: "#" },
    { label: "Contact Us", path: "#" },
    { label: "Terms and Condition", path: "#" },
    { label: "Privacy Policy", path: "#" },
  ];

  const Service = [
    { label: "Shipping & Returns", path: "#" },
    { label: "Track Your Order", path: "#" },
    { label: "Customer Service", path: "#" },
    { label: "Product Support", path: "#" },
    { label: "FAQs", path: "#" },
  ];

  // *For Sales Person Dialog
  const handleSalesPersonDialog = () => {
    setResetDialog(!resetDialog);
  };

  return (
    <Fragment sx={{ "& .MuiTypography-root": { marginTop: "50px" } }}>
      <SalesPersonLogin show={resetDialog} onClose={handleSalesPersonDialog} />

      <Typography
        component="footer"
        sx={{ padding: "40px 0px 30px", bgcolor: colors.primary }}
      >
        <Container>
          <Grid container spacing={2} sx={{ m: 0 }}>
            <Grid item xs={12} sm={5}>
              <CardMedia
                component="img"
                image={Images.logo2}
                sx={{ width: "auto" }}
              />
              <Box sx={{ my: 3, display: "flex" }}>
                <MdOutlineHeadsetMic
                  style={{
                    color: colors.secondary,
                    fontSize: "60px",
                    marginRight: "5px",
                  }}
                />
                <Box>
                  <Typography variant="caption" color={colors.lightGray}>
                    Got Questions ? Call us 24/7!
                  </Typography>
                  <Typography variant="h6" color={colors.white}>
                    (+92) 315-2227698{" "}
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="body1"
                color={colors.lightGray}
                sx={{ mt: 3, mb: 1, fontWeight: 700 }}
              >
                Contact Info
              </Typography>
              <Typography variant="body2" color={colors.white} sx={{ mb: 1 }}>
                {" "}
                Karachi, Pakistan
              </Typography>
              <Box sx={{ my: 2 }}>
              <a
                  href="https://wa.me/03152227698
"
                >
                <BsWhatsapp className={classes.icon} />
                </a>
         
                <a
                  href="https://www.facebook.com/profile.php?id=100088982976702
"
                >
                <BsFacebook className={classes.icon} />
                </a>
               
                <a
                  href="https://www.instagram.com/partzbuddy608/
"
                >
                  <BsInstagram className={classes.icon} />
                </a>
              </Box>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Typography
                variant="h6"
                color={colors.lightGray}
                sx={{ mb: 3, fontWeight: 700 }}
              >
                Information
              </Typography>
              {Information.map((item, index) => (
                <Typography
                  key={index}
                  component="li"
                  sx={{ listStyle: "none", my: 1.5 }}
                >
                  <Link to={item.path} className={classes.link}>
                    {item.label}
                  </Link>
                </Typography>
              ))}
              <Typography
                variant="body1"
                onClick={() => navigate("/salesperson/add-product")}
                sx={{
                  cursor: "pointer",
                  color: colors.white,
                  "&:hover": { color: colors.secondary },
                }}
              >
                Login as Salesperson
              </Typography>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Typography
                variant="h6"
                color={colors.lightGray}
                sx={{ mb: 3, fontWeight: 700 }}
              >
                Customer Care
              </Typography>
              {Service.map((item, index) => (
                <Typography
                  key={index}
                  component="li"
                  sx={{ listStyle: "none", my: 1.5 }}
                >
                  <Link to={item.path} className={classes.link}>
                    {item.label}
                  </Link>
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Typography>

      {/* ========== Copyright ========== */}
      <Box sx={{ padding: "15px 0px" }}>
        <Grid container spacing={0}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              color={colors.textPrimary}
              sx={{ textAlign: "center" }}
            >
              © <CurrentYear /> All Rights Reserved. Developed by
              <Link
                to="https://www.mangotechsolutions.com/"
                target="_blank"
                className={classes.link}
                style={{ color: colors.secondary }}
              >
                {" "}
                MangotechSolutions
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
}

export default Footer;
