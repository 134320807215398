const colors = {
  primary: '#404F5E',
  primaryLight: 'rgba(64, 79, 94,0.6)',
  primaryMedium: ' rgba(3, 196, 235,0.5)',
  secondary: '#F14662',
  secondaryDark: '#db1e3a',
  // secondaryMedium: 'rgba(39, 174, 0,0.7)',
  // backgroundPrimary: '#DCE1F7',
  // backgroundInput: '#DBE3F9',
  ratingYellow: '#FDC500',
  textPrimary: '#333e48',
  textSecondary: '#A1989B',
  white: '#fff',
  black: '#000000de',
  gray: '#ddd',
  lightGray: '#f4f4f4ad',
  darkGray: '#aaa',
  // primaryLight: "#556b82",
  purple: '#333982',
  darkGreen: '#017F70'
};

export default colors;