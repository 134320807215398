
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Step, StepContent, StepLabel, Stepper, Typography, TextField, FormControl, Checkbox, FormControlLabel, FormGroup, InputAdornment, IconButton, OutlinedInput, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux';
import Geocode from "react-geocode";
import { FaMapMarkerAlt } from 'react-icons/fa';

import colors from 'app/style/colors';
import { Service } from 'app/config/service';
import { ClearCartData } from 'app/redux/Actions/ActionCreator';

// *Import Components
import { Error } from '../UI/Error';
import CheckoutDialog from '../Dialog/CheckoutDialog';
import MapModal from 'app/components/Dialog/MapDialogBox';
import CustomTextfield, { OutlineInputField } from '../UI/TextField';
import { InputField } from '../UI/TextField';

function CheckoutStepper(props) {

  const { items } = props
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clearCartData = () => dispatch(ClearCartData());
  let accountId = localStorage.getItem('customerId')

  // make the env later
  Geocode.setApiKey("AIzaSyBJ3IVlUfKfOT9ra-80Fex_t_hM5mfigro");

  // *For Form Validation
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, } = useForm();

  const [loading, setLoading] = useState(false);
  const [guestLoading, setGuestLoading] = useState(false);

  // *For Profile
  const [profile, setProfile] = useState();

  const [openDialog, setOpenDialog] = useState(false);

  const [currentLocation, setCurrentLocation] = useState({ latitude: 0, longitude: 0 })
  const [updatedAddress, setUpdatedAddress] = useState("")

  // *For Stepper
  const [activeStep, setActiveStep] = useState(0);

  // *For SignIn Dialog
  const [signInDialog, setSignInDialog] = useState(false);

  const [deliveryAddress, setDeliveryAddress] = useState();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // *For Personal Info
  const personalInfo = async (data) => {
    try {
      let obj = {
        name: data.name,
        Phone: data.phone,
      }
      handleNext()
    } catch (error) {
      console.log('file: CheckoutStepper.js => line 36 => personalInfo => error', error)
    }
  };

  // *For Delivery Address
  const getDeliveryAddress = async (data) => {
    try {
      setDeliveryAddress(data.address)
      handleNext()
    } catch (error) {
      console.log('file: CheckoutStepper.js => line 36 => deliveryAddress => error', error)
    }
  };

  // *For Get UserDate
  const getUserData = async () => {
    try {
      if (accountId) {
        const { Data } = await Service.getProfileDetail(accountId)
        setProfile(Data[0])
        setValue("name", Data[0]?.Name)
        setValue("phone", Data[0].EmailPh.slice(0, -2))
        const latitude = Data[0]?.Addresses[0]?.Latitude
        const longitude = Data[0]?.Addresses[0]?.Longitude
        setCurrentLocation({ latitude, longitude })
      }
    } catch (error) {
      console.log('file: CheckoutStepper.js => line 201 => getUserData => error', error)
    }
  };

  const changeLocation = (val) => {
    const latitude = val.lat()
    const longitude = val.lng()
    setCurrentLocation({ latitude, longitude })
  };

  const geoLocation = () => {
    Geocode.fromLatLng(currentLocation?.latitude, currentLocation?.longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setUpdatedAddress(address)
      },
      (error) => {
        console.error(error);
      }
    );
  };

  // *For Payment Detail
  const placeOrder = async (guestCheckout) => {
    setLoading(true)
    try {
      const token = localStorage.getItem('customerToken')
      let data = {};
      let itemsList = [];
      let deliveryCharges = 0
      let totalDiscount = 0
      let subTotal = 0

      for (const index in items) {
        const { id, price, qty, delivery, discount } = items[index];
        let newItem = {
          ItemID: id,
          Qty: qty,
          SalePrice: price,
          HasVariation: false,
          IsDeal: false,
          DiscountAmount: discount,
        };
        itemsList.push(newItem);
        // const discountAmount = discount * qty
        // const discount = value.discount === 0 ? price : value.discount * value.qty
        const discountAmount = discount === 0 ? price * qty : discount * qty
        totalDiscount += discountAmount
        deliveryCharges += delivery
        subTotal += price * qty
      }

      data = {
        AccountID: parseInt(localStorage.getItem('customerId')),
        DeliveryAddress: deliveryAddress,
        OrderTypeID: '',
        DeliveryCharges: deliveryCharges,
        TotalAmount: subTotal - (subTotal - totalDiscount) + deliveryCharges, //Sub total change for calculation
        Rating: '',
        CustomerFeedBack: '',
        BusinessTypeID: 1,
        PlatFormID: 2,
        DiscountAmount: totalDiscount,
        PaymentTypeID: 1,
        SaleDetails: itemsList,
      }

      console.log("🚀 ~ file: CheckoutStepper.js:154 ~ placeOrder ~ data:", data)

      const { ResponseCode, Status, Data } = await Service.placeOrder(data, token)
      if (Status === true && ResponseCode === 200) {
        clearCartData()
        Swal.fire({
          title: 'Order # ' + Data,
          text: 'Your order has been placed successfully!',
          icon: 'success',
        }).then(() => {
          navigate('/')
        });
      }

      // if (customer) {
      //   console.log('customer');
      //   const customerId = localStorage.getItem('customerId')
      //   // data = {
      //   //   ...data,
      //   //   AccountID: customerId,
      //   // }
      // } else if (guestCheckout) {
      //   setGuestLoading(true)
      //   console.log('guest');
      // } else {
      //   setSignInDialog(true)
      //   return
      // }
    } catch (error) {
      console.log('file: CheckoutStepper.js => line 51 => payment => error', error)
    } finally {
      setLoading(false)
    }
  };

  const openMapDialog = () => {
    setOpenDialog(true)
    navigator.geolocation.getCurrentPosition((location) => {
      const latitude = location.coords.latitude
      const longitude = location.coords.longitude
      setCurrentLocation({ latitude, longitude })
    })
  };

  useEffect(() => {
    getUserData()
  }, []);

  useEffect(() => {
    geoLocation()
  }, [currentLocation]);

  return (
    <Fragment>

      {/* ========== Checkout Dialog ========== */}
      <CheckoutDialog open={signInDialog} guestLoading={guestLoading} onClose={() => setSignInDialog(false)} guestCheckout={() => placeOrder('guest')} />

      {/* ===== Map Modal ===== */}
      <MapModal currentLocation={currentLocation} open={openDialog} locationChange={changeLocation} onClose={() => setOpenDialog(!openDialog)} />

      <Stepper activeStep={activeStep} orientation="vertical">

        {/* ========== Personal Information ========== */}
        <Step>
          <StepLabel>
            Personal Info
          </StepLabel>
          <StepContent sx={{ pt: 2 }}>
            <Typography component="form" onSubmit={handleSubmit(personalInfo)}>
              <InputField
                label={'Name'}
                error={errors?.name?.message}
                register={register("name", {
                  required: 'Name is required',
                })}
                defaultValue={`${accountId ? profile?.Name : ''}`}
              />
              <InputField
                label={'Phone'}
                error={errors?.phone?.message}
                register={register("phone", {
                  required: 'Phone Number is required',
                })}
                defaultValue={`${accountId ? profile?.EmailPh.slice(0, -2) : ''}`}
              />
              <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }} >
                Next
              </Button>
            </Typography>
          </StepContent>
        </Step>

        {/* ========== Delivery Address ========== */}
        <Step>
          <StepLabel>
            Delivery Address
          </StepLabel>
          <StepContent sx={{ pt: 2 }}>
            <Typography component="form" onSubmit={handleSubmit2(getDeliveryAddress)}>
              <OutlineInputField
                label={"Address"}
                updatedValue={updatedAddress}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                    >
                      <FaMapMarkerAlt onClick={() => openMapDialog()} />
                    </IconButton>
                  </InputAdornment>
                }
                error={errors2?.address?.message && (true)}
                register={register2("address", {
                  onChange: (e) => setUpdatedAddress(e.target.value),
                  required: 'Please enter address.',
                })
                }
              />
              <Box sx={{ mb: 2 }}>
                <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }} >
                  Next
                </Button>
                <Button variant="outlined" onClick={handleBack} sx={{ mt: 1, mr: 1 }} >
                  Back
                </Button>
              </Box>
            </Typography>
          </StepContent>
        </Step>

        {/* ========== Payment Detail ========== */}
        <Step>
          <StepLabel>
            Payment Detail
          </StepLabel>
          <StepContent>
            <Box sx={{ border: `2px solid ${colors.textPrimary}`, py: 0.5, px: 2, my: 2 }}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked="false" sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }} />}
                  label="Cash on Delivery"
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: 20,
                      color: colors.textPrimary
                    }
                  }}
                />
              </FormGroup>
            </Box>
            <Box sx={{ mb: 2 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                onClick={() => placeOrder()}
                sx={{ mt: 1, mr: 1 }}
              >
                Place Order
              </LoadingButton>
              <Button variant="outlined" onClick={handleBack} sx={{ mt: 1, mr: 1 }} >
                Back
              </Button>
            </Box>
          </StepContent>
        </Step>

      </Stepper>

    </Fragment >
  );
}

export default CheckoutStepper;