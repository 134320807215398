import React, { Fragment, useState } from 'react';
import { Divider, IconButton, Typography, Table, TableBody, tableCellClasses, TableCell, TableRow, Paper, TableHead, TableContainer, Box, } from '@mui/material';
import { Add, Delete, Remove } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { DeleteCartData, updateCartData } from 'app/redux/Actions/ActionCreator';
import Images from 'assets/Images';

import colors from 'app/style/colors';
import { SuccessToaster } from 'app/components/UI/Toaster';

const Row = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.primary,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Cart(props) {

  const { items } = props

  const dispatch = useDispatch();
  const addToCart = (_data) => dispatch(updateCartData(_data));
  const deleteCartData = (data) => dispatch(DeleteCartData(data));

  const incrementHandler = (item) => {
    if (item.qty < 50) {
      let obj = {
        type: 'increment',
        id: item.id,
        name: item.name,
      };
      addToCart(obj);
    }
    else {
      SuccessToaster("Max Quantity Limit exceeded")
    }

  };

  const decrementHandler = (item) => {

    if (item.qty >= 1) {
      let obj = {
        type: 'decrement',
        id: item.id,
        name: item.name,
      };
      addToCart(obj);
    }
    else {
      deleteHandler(item.id)
    }
  };

  const deleteHandler = (productId) => {
    let obj = {
      id: productId,
    }
    deleteCartData(obj);
  };

  return (
    <Fragment>
      <Typography variant="h5" color={colors.textPrimary}>My Cart </Typography>
      <Divider sx={{ marginY: 2 }} />
      <TableContainer component={Paper} sx={{ maxHeight: 400 }} >
        <Table>
          <TableHead>
            <TableRow>
              <Cell>S.No</Cell>
              <Cell>Image</Cell>
              <Cell>Product</Cell>
              <Cell align="right">Quantity</Cell>
              <Cell align="center">Price</Cell>
              <Cell align="right">Action</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <Row key={index}>
                <Cell align="center">{index + 1}</Cell>
                <Cell component="th" scope="row">
                  <Typography
                    component="img"
                    src={item.image ? item.image : Images.imagePlaceholder}
                    sx={{
                      width: 'auto',
                      height: 40,
                      mr: 1,
                    }}
                  />
                </Cell>
                <Cell component="th" scope="row">
                  <Box sx={{ display: 'flex' }}>

                    <Typography variant='body2' component="span">{item.name}</Typography>
                  </Box>
                </Cell>
                <Cell align="right">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: '3px 5px',
                      borderRadius: 2,
                      border: `1px solid ${colors.primary}`,
                      mb: 1.3,
                    }}
                  >
                    <Remove
                      onClick={() => { decrementHandler(item) }}
                      sx={{
                        width: 20,
                        verticalAlign: 'bottom',
                        cursor: 'pointer',
                        '&:hover': {
                          color: colors.primary,
                          transition: '0.3s ease-in-out',
                        },
                      }}
                    />
                    <Typography variant='body1' component="span" sx={{ minWidth: 20, textAlign: 'center' }}>
                      {item.qty}
                    </Typography>
                    <Add
                      onClick={() => { incrementHandler(item) }}
                      sx={{
                        width: 20,
                        verticalAlign: 'bottom',
                        cursor: 'pointer',
                        '&:hover': {
                          color: colors.primary,
                          transition: '0.3s ease-in-out',
                        },
                      }}
                    />
                  </Box>
                </Cell>
                <Cell align="right" sx={{ width: 120, textAlign: 'center' }}>Rs {item.price}</Cell>
                <Cell align="right">
                  <IconButton onClick={() => deleteHandler(item.id)}>
                    <Delete color='error' />
                  </IconButton>
                </Cell>
              </Row>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
}

export default Cart;