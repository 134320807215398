import React from 'react';
import { Box, CardMedia } from '@mui/material';

import Images from 'assets/Images';

export const Loading = () => {
  return (
    <Box sx={{ my: 2 }}>
      <CardMedia component="img" image={Images.loader} sx={{ width: 250, height: 50, m: 'auto' }} />
    </Box>
  );
}

export const CarLoading = () => {
  return (
    <Box sx={{ my: 2 }}>
      <CardMedia component="img" image={Images.carLoader} />
    </Box>
  );
}
