import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import colors from "app/style/colors";

function CustomerOrderDetail(props) {
  const { orderDetail, show, handleClose, } = props;
  console.log("🚀 ============ CustomerOrderDetail orderDetail ===============", orderDetail)

  const [discount, setDiscount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);

  const getReceipt = () => {
    if (orderDetail?.saleDetail) {
      let Discount = orderDetail?.discountAmount;
      let SubTotal = 0;
      for (let index = 0; index < orderDetail?.saleDetail.length; index++) {
        const element = orderDetail?.saleDetail[index];
        const price = element.SalePrice * element.Qty;
        // const discountAmount = element.DiscountAmount * element.Qty;
        // Discount += discountAmount;
        SubTotal += price;
      }

      setDiscount(SubTotal - Discount);
      setSubTotal(SubTotal);
    }

  };

  useEffect(() => {
    getReceipt();
  }, [show]);

  return (
    <Fragment>
      <Dialog
        open={show}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle sx={{ textAlign: "center" }} id="alert-dialog-title">
          Order Detail
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <Typography variant='h5' sx={{ fontWeight: "bold", color: colors.secondary }}>{orderDetail?.name}</Typography> */}
            {/* <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2, pt: 2, borderTop: `1px solid ${colors.primary}` }} >
              <Typography variant='body1' sx={{ fontWeight: "bold", color: colors.primary }}>Quantity</Typography>
              <Typography variant='body1' sx={{ color: colors.primary }}>{orderDetail?.qty}</Typography>
            </Box> */}
            <Box
              sx={{ mt: 2, py: 1, borderBottom: `1px solid ${colors.primary}` }}
            >
              {orderDetail?.saleDetail ? (
                <Fragment>
                  {orderDetail?.saleDetail.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        py: 0.8,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: colors.primary }}
                      >
                        {item.Qty}x
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: colors.primary,
                          flex: 1 / 2,
                          textAlign: "center",
                        }}
                      >
                        {item.ItemName}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: colors.primary }}
                      >
                        Rs: {item.SalePrice}
                      </Typography>
                    </Box>
                  ))}
                </Fragment>
              ) : null}
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: colors.primary }}
              >
                Sub Total
              </Typography>
              <Typography variant="body1" sx={{ color: colors.primary }}>
                {subTotal}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: colors.primary }}
              >
                Discount Amount
              </Typography>
              <Typography variant="body1" sx={{ color: colors.primary }}>
                {discount}{" "}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: colors.primary }}
              >
                Delivery Charges
              </Typography>
              <Typography variant="body1" sx={{ color: colors.primary }}>
                {orderDetail?.delivery}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: colors.primary }}
              >
                Total Amount
              </Typography>
              <Typography variant="body1" sx={{ color: colors.primary }}>
                {orderDetail?.total}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button variant='contained' onClick={() => handleClose()}>Save</Button> */}
          {/* <Button variant='contained' onClick={() => handleClose()}>Share</Button> */}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default CustomerOrderDetail;
