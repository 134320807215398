import React, { useEffect, useState } from "react";
import {
  Box,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { AiOutlineShop, AiOutlineMobile } from "react-icons/ai";
import { useParams } from "react-router-dom";

import { Service } from "app/config/service";
import colors from "app/style/colors";
import Images from "assets/Images";

// *Import Components
import { Loading } from "app/components/UI/Loader";
import SimpleCard from "app/components/Cards/SimpleCard";
import { ProductNotFound } from "app/components/NotFound/NotFound";
import { LoadingButton } from "@mui/lab";


function Shop() {
  const { id } = useParams();

  // loading States
  let [load, setLoad] = useState(false)
  let [count, setCount] = useState(0);
  let [pageNum, setPageNum] = useState(1);
  // *For Product
  const [product, setProduct] = useState([]);
  let [productLength, setProductLength] = useState(0)
  // *For Profile
  const [profile, setProfile] = useState({});

  // *For Get Productq
  const getProduct = async (pageNum) => {
    try {
      setCount(pageNum);
      if (pageNum) {
        setLoad(true)
      }
      const { Data } = await Service.getVendorProduct(id, pageNum);

      setProductLength(Data[0]?.ItemArray.length)
      if (Data === null) return;

      setProduct([...product, ...Data[0]?.ItemArray]);
    } catch (error) {
      console.log("file: Shop.js => line 26 => getProduct => error", error);
    }
    finally {
      setLoad(false)
    }
  };

  // *For Get Shop Profile
  const getProfile = async () => {
    try {
      const { Data } = await Service.getProfileDetail(id);
      setProfile(Data[0]);
    } catch (error) {
      console.log("file: Shop.js => line 26 => getProduct => error", error);
    }
  };

  useEffect(() => {
    getProfile();
    getProduct(1);
  }, [id]);

  return (
    <Container sx={{ my: 3 }}>
      <CardMedia
        image={Images.shopBanner}
        sx={{
          height: "150px",
          my: 2,
          p: { xs: 0, sm: 2 },
          pl: 0,
          pb: 0,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Box
          sx={{
            bgcolor: colors.lightGray,
            width: { xs: "100%", sm: "fit-content" },
            px: 2,
            py: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AiOutlineShop size={22} color={colors.primary} />
            &nbsp;
            <Typography variant="body1" color={colors.primary}>
              {profile.Name}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
            <AiOutlineMobile size={22} color={colors.primary} />
            &nbsp;
            <Typography variant="body1" color={colors.primary}>
              {profile.Cell}
            </Typography>
          </Box>
        </Box>
      </CardMedia>
      <Typography
        variant="h6"
        color={colors.primary}
        sx={{ fontWeight: "bold" }}
      >
        All Products
      </Typography>
      <Divider sx={{ mb: 2 }} />
      {product.length ? (
        <Grid container spacing={3} columns={{ sm: 12, md: 12, lg: 10 }}>
          {product.map((item, index) => (
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <SimpleCard key={index} product={item} />
            </Grid>
          ))}

          {productLength === 20 &&
            <Box
              sx={{ textAlign: "center", my: 2, width: "100%" }}
              onClick={() => {
                getProduct(count + 1);
              }}
            >
              <LoadingButton
                loading={load}
                variant="contained"
                sx={{
                  bgcolor: colors.secondary,
                  "&:hover": { bgcolor: colors.secondaryDark },
                }}
              >
                Load More
              </LoadingButton>
            </Box>
          }
        </Grid>
      ) : (
        <Loading />
      )}

      {/* Loading Button--------------------> */}
    </Container>
  );
}

export default Shop;
