import { React, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { Loading } from "app/components/UI/Loader";
import { Service } from "app/config/service";
import { Box, Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import SimpleCard from "../../components/Cards/SimpleCard";
import colors from "../../style/colors";
import { AiOutlineRight } from "react-icons/ai";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import Images from 'assets/Images.js'

const useStyles = makeStyles({
  link: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.black,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: colors.secondary,
      transition: "0.3s ease-in-out",
    },
  },
});


function FilterProducts() {
  const classes = useStyles();
  // const { CatID } = useParams();
  const { state } = useLocation();

  const [loader, setLoader] = useState(false);
  const [searchData, setSearchData] = useState([]);
  let [num, setNum] = useState(1);
  let [load, setLoad] = useState(false);

  //* Filter Products API

  const filterProducts = async (a, newList) => {
    setNum(a);
    setLoad(true);
    if (a == 1) {
      setLoader(true);
    }

    try {
      const { Data } = await Service.filterProducts(a, state.ItemName, state?.CatID.length ? state?.CatID : "", state?.BrandID.length ? state?.BrandID : "");

      setLoad(true);
      if (Data === null) setSearchData([]);

      if (newList) {
        setSearchData(Data[0]?.ItemArray);
      } else {
        setSearchData([...searchData, ...Data[0]?.ItemArray]);

      }

    } catch (error) {
      console.log("🚀 ~ file: FilterProducts.js:62 ~ filterProducts ~ error", error)

    } finally {
      setLoader(false);
      setLoad(false);
    }
  };

  // Search Products
  useEffect(() => {
    filterProducts(1, true);
    window.scrollTo({ top: 0 });
    setSearchData([])

  }, [state.CatID]);


  return (
    <Container>
      <Grid item xs={12}>
        <Box sx={{ py: 1.5 }}>
          <Typography sx={{ color: colors.primary }}>Filtered Products</Typography>
        </Box>
        {/* <Breadcrumbs
          separator={<AiOutlineRight fontSize="small" sx={{ mx: 1 }} />}
          aria-label="breadcrumb"
          sx={{ mt: 3, mb: 2 }}
        >
          <Link to="/" className={classes.link}>
            Home
          </Link>
          <Typography sx={{ fontWeight: 500 }}>{CatID}</Typography>
        </Breadcrumbs> */}
      </Grid>

      {loader ? (
        <Loading />
      ) : (
        // <></>
        <Grid>
          <Grid container spacing={3} columns={{ sm: 12, md: 12, lg: 10 }}>
            {searchData.length !== 0 ?

              searchData.map((item, index) => (
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <SimpleCard key={index} product={item} />
                </Grid>
              )) : (
                <div className="Error">
                  <img src={Images.notFound}></img>
                  <h2>Oops No Product found</h2>
                </div>


              )

            }
          </Grid>

          {searchData.length >= 10 ? (
            <Box
              sx={{ textAlign: "center", my: 2 }}
              onClick={() => {
                filterProducts(num + 1);
              }}
            >
              <LoadingButton
                loading={load}
                variant="contained"
                sx={{
                  bgcolor: colors.secondary,
                  "&:hover": { bgcolor: colors.secondaryDark },
                }}
              >
                Load More
              </LoadingButton>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      )}
    </Container>
  );
}

export default FilterProducts