import React, { Fragment } from 'react';
import { Container, Divider, Grid, Typography } from '@mui/material';

import colors from 'app/style/colors';

// *Import Components
import CustomerLogin from './Login/Login';
import CustomerRegister from './Register/Register';

function Session() {
  return (
    <Fragment>
      <Container>
        <Grid container spacing={2} sx={{ mt: 4, mb: 5, pb: 4, pt: 2 }} columns={13} justifyContent="space-between" alignItems="flex-start" >
          <Grid item md={6}>
            {/* ========== Login ========== */}
            <CustomerLogin />
          </Grid>
          <Divider orientation="vertical" flexItem>
            <Typography variant="subtitle1" sx={{ border: `1px solid ${colors.gray}`, borderRadius: '50%', px: 2, py: 1.5 }} >or</Typography>
          </Divider>
          <Grid item md={6}>
            {/* ========== Register ========== */}
            
            <CustomerRegister />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

export default Session;