import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import { InputField } from "../UI/TextField";
import { SecondaryLoadButton } from "../UI/Button";
import { Service } from "app/config/service";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { useState } from "react";

export default function FormDialog(props) {
  const { open, token, number,onClose,ClosepassDiagloag } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  let [loading, setLoading] = useState(false);

  //   Close and open Model --------------------------------------------->

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  // Api hit----------->
  let ChangePassword = async (data) => {
    setLoading(true);
    try {
      let obj = {
        Email: number,
        Password: data.password,
        Code: token,
      };
      // if response Succes then show this Toaster------------------------------------>
      try {
        let { Status, ResponseCode, Message, } = await Service.resetPassword(
          obj
        );
        if (ResponseCode === 200) {
          SuccessToaster(Message);
          onClose()
          ClosepassDiagloag()
        } else {
          ErrorToaster(Message);
        }
      } catch {
        ErrorToaster("Oops Error Occur");
      } finally {
        setLoading(false);
      }

      // Send OTP To NUmber

      // Open OTP MODEL
    } catch (error) {
      console.log(
        "file: ResetPassword.js => line 20 => resetPassword => error",
        error
      );

      // OtpModel()
    } finally {
    }
  };

  return (
    <div>
      <Dialog open={open} sx={{ padding: "20px" }} >
        <DialogTitle variant="p">Change Password</DialogTitle>

        <Typography
          sx={{ mt: 0, padding: "30px" }}
          component="form"
          onSubmit={handleSubmit(ChangePassword)}
        >
          <InputField
            size="medium"
            label="Password"
            type="password"
            error={errors?.password?.message}
            register={register("password", {
              required: "Please enter password.",
              minLength: {
                value: 6,
                message: "Password must have at least 6 characters",
              },
            })}
          />

          <InputField
            size="medium"
            label="Confirm Password"
            type="password"
            error={errors?.confirmPassword?.message}
            register={register("confirmPassword", {
              required: "Confirm Password is required",
              validate: (value) =>
                value === password.current || "Confirm password does not match",
            })}
          />

          <SecondaryLoadButton
            fullWidth
            loading={loading}
            type="submit"
            variant="contained"
            sx={{ width: "fit-content" }}
          >
            Change password
          </SecondaryLoadButton>
        </Typography>
      </Dialog>
    </div>
  );
}
