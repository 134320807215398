import React, { useEffect, useState } from 'react';
import { Container, Grid, Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Service } from 'app/config/service';
// *Import Components
import Breadcrumb from 'app/components/Breadcrumb/Breadcrumb';
import SimpleCard from 'app/components/Cards/SimpleCard';
import { Loading } from 'app/components/UI/Loader';
import Filter from 'app/components/Filter/Filter';
import colors from 'app/style/colors';
import { LoadingButton } from '@mui/lab';
import { ProductNotFound } from 'app/components/NotFound/NotFound';

function ProductCategory() {

  const { name, catId } = useParams()
  const [load, setLoad] = useState(false);
  const [loader, setLoader] = useState(false);
  // *For Product
  const [product, setProduct] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);

  // *Get New Arrival
  // const getNewArrival = async () => {
  //   try {
  //     const { Data } = await Service.getNewArrival();
  //     if (Data === null) return
  //     setProduct(Data)
  //   } catch (error) {
  //     console.log('file: ProductCategory.js => line 25 => getNewArrival => error', error);
  //   }
  // };

  // *Get New Arrival
  const getProductsCategoryIDWise = async (pageNo) => {
    setLoad(true)
    try {
      const { Data } = await Service.getProductsCategoryIDWise(catId, pageNo);
      if (Data === null) {
        setProduct([])
        return
      }
      if (pageNo > 1) {
        setProduct(
          [...product, ...Data?.Products]
        );
      }
      else {
        setProduct(Data?.Products);
      }
      setPageCount(Data?.PageCount);
      setTotalCount(Data?.TotalCount);
    } catch (error) {
      console.log('file: ProductCategory.js => line 25 => getNewArrival => error', error);
    }
    finally {
      setLoader(false);
      setLoad(false);
    }
  };

  useEffect(() => {
    // getNewArrival()
    setLoader(true)
    getProductsCategoryIDWise(1)
    setPageNumber(1)
    window.scrollTo({ top: 0 })
  }, [catId])


  return (
    <Container>
      {/* ========== Breadcrumb ========== */}
      <Grid item xs={12}>
        <Breadcrumb />
      </Grid>
      {loader ? (
        <Loading />
      ) : (
        <Grid container spacing={3} sx={{ width: "100%" }}>

          {/* ========== Filter ========== */}
          {/* <Filter category={product} name={name} /> */}


          {product.length > 0 ? (


            // change the value of xs to 9.5  when adding filter ================================================================================================================> 

            <Grid container item xs={12} spacing={2} sx={{ mb: 4 }}>
              {product.map((item, index) => (
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <SimpleCard key={index} product={item} />
                </Grid>
              ))}
              <Grid item xs={12} md={12}>
                {totalCount !== product.length &&

                  // <Box sx={{ textAlign: "center", my: 2 }} onClick={() => { setPageNumber(pageNumber + 1); getProductsCategoryIDWise(pageNumber + 1) }}>
                  <Box sx={{ textAlign: "center", my: 2 }} onClick={() => { setPageNumber(pageNumber + 1); getProductsCategoryIDWise(pageNumber + 1) }}>
                    <LoadingButton loading={load} variant='contained' sx={{ bgcolor: colors.secondary, '&:hover': { bgcolor: colors.secondaryDark } }}>
                      Load More
                    </LoadingButton>
                  </Box>
                }
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={9}>
              <ProductNotFound error="No Product Found" />
            </Grid>
          )}
        </Grid>
      )}
    </Container>
  );
}

export default ProductCategory;