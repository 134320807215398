import React, { Fragment, useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CardMedia, CircularProgress, Container, Divider, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Toolbar, Typography } from '@mui/material'
import { Percent } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Controller } from "react-hook-form";
import { makeStyles } from '@mui/styles';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';

import colors from 'app/style/colors'
import { Service } from 'app/config/service';
import Images from "../../../../assets/Images"

// *Import Components
import VendorSideNav from 'app/components/SideNav/VendorSideNav'
import imageCompress from 'app/hooks/imageCompress';
import { LoadingButton } from '@mui/lab';
import { AiFillDelete } from 'react-icons/ai';
import { Loading } from 'app/components/UI/Loader';
import { ErrorToaster } from 'app/components/UI/Toaster';

const steps = ['Category', 'Sub-Category', 'Add Product Detail', "Upload Your Products", "Post Information"];


const useStyles = makeStyles({
  image: {
    borderRadius: 2,
    objectFit: "contain"
  }
});


function AddProduct() {

  let token = localStorage.getItem('vendorToken')

  const classes = useStyles()

  const [loading, setLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false)
  const [mainLoading, setMainLoading] = useState(false)

  const [activeStep, setActiveStep] = useState(0);
  // const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [vehicles, setVehicles] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [brand, setBrand] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [selectedCoverImage, setSelectedCoverImage] = useState("");
  const [selectedImage1, setSelectedImage1] = useState("");
  const [selectedImage2, setSelectedImage2] = useState("");
  const [selectedImage3, setSelectedImage3] = useState("");
  const [selectedImage4, setSelectedImage4] = useState("");
  const [selectedImage5, setSelectedImage5] = useState("");
  const [productData, setProductData] = useState('');
  const [discount, setDiscount] = useState('');
  const [discountedPrice, setDiscountPrice] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [brandList, setBrandList] = useState([]);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [vehiclesList, setVehiclesList] = useState([]);
  const [productFormData, setProductFormData] = useState([]);
  const [imgLoader, setImgLoader] = useState(false);
  const [imgLoader1, setImgLoader1] = useState(false);

  const [finalData, setFinalData] = useState({
    categoryName: "",
    productName: "",
    productPrice: "",
    discountPercent: "",
    discountPrice: "",
    productDescription: "",
    vehicle: "",
    brand: "",
    manufacturer: "",
  });

  const navigate = useNavigate()

  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();

  // *For Set Vehicle
  const handleVehicle = (event) => {
    setVehicles(event.target.value);
  };

  // *For Set Brand
  const handleBrand = (event) => {
    setBrand(event.target.value);
  };

  // *For Set Manufacturer
  const handleManufacturer = (event) => {
    setManufacturer(event.target.value);
  };

  // *For Calculate Discount
  const calculateDiscount = (val) => {
    setDiscount(val)
    const discounted_price = productPrice - (productPrice * val / 100)
    // const discounted_price = (productPrice * val / 100) Changes After 27 feb
    setDiscountPrice(Math.round(discounted_price))
    if (val === "") {
      setDiscountPrice("")
    }
  }

  // *For Stepper Next
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // *For Stepper Back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  // *Get Sub Categories
  const getSubCategory = async (id) => {
    setCardLoading(true)
    try {
      const { Data } = await Service.getSubCategory(id)
      setSubCategoryList(Data)
    }
    catch (error) {
      console.log("🚀 ~ file: AddProduct.js:126 ~ getSubCategory ~ error", error)
    }
    finally {
      setCardLoading(false)
    }
  }

  // *For Set Category
  const setCategory = (val) => {
    // setSelectedCategory(val)
    getSubCategory(val.value)
    handleNext()
  }

  // *For Set Sub-Category
  const setSubCategory = (val) => {
    setSelectedSubCategory(val)
    handleNext()
  }

  // *For Product Detail
  const productDetail = async (form) => {
    setFinalData({
      ...finalData,
      productName: form.productName,
      productDescription: form.description,
      discountPercent: Number(discount),
      discountPrice: Number(discountedPrice),
      productPrice: Number(productPrice)
    })
    try {
      let data = [
        { QID: 1, Value: form.productName },
        { QID: 2, Value: manufacturer },
        { QID: 3, Value: Number(productPrice) },
        { QID: 4, Value: Number(discount) },
        { QID: 5, Value: Number(discountedPrice) },
        { QID: 6, Value: form.description },
        { QID: 8, Value: vehicles },
        { QID: 12, Value: brand },
        { QID: 11, Value: selectedSubCategory },
      ]
      setProductData(data)
      handleNext()
    } catch (error) {
      console.log('file: AddProduct.js => line 112 => productDetail => error', error);
    }
  };


  // *For Cover Image
  const imageChange = async (e) => {
    console.log("🚀 ~ file: AddProduct.js:181 ~ imageChange ~ e", e)
    setImgLoader(true)
    try {
      if (e.target.files && e.target.files.length > 0) {
        const Data = await imageCompress(e)
        setSelectedCoverImage(Data);
      }
    } catch (error) {
      console.log('file: AddProduct.js => line 152 => imageChange => error', error);
    }
    finally {
      setImgLoader(false)
    }
  };

  // *For multiple Image

  const imageChange1 = async (e) => {
    setImgLoader1(true)
    try {
      if (e.target.files && e.target.files.length > 0) {
        const Data = await imageCompress(e)
        setSelectedImage1(Data);
      }
    } catch (error) {
      console.log('file: AddProduct.js => line 166 => imageChange1 => error', error);
    }
    finally {
      setImgLoader1(false)
    }
  };

  const imageChange2 = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const Data = await imageCompress(e)
        setSelectedImage2(Data);
      }
    } catch (error) {
      console.log('file: AddProduct.js => line 183 => imageChange2 => error', error);

    }
  };

  const imageChange3 = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const Data = await imageCompress(e)
        setSelectedImage3(Data);
      }
    } catch (error) {
      console.log('file: AddProduct.js => line 198 => imageChange3 => error', error);
    }
  };

  const imageChange4 = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const Data = await imageCompress(e)
        setSelectedImage4(Data);
      }
    } catch (error) {
      console.log('file: AddProduct.js => line 212 => imageChange4 => error', error);
    }
  };

  const imageChange5 = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const Data = await imageCompress(e)
        setSelectedImage5(Data);
      }
    } catch (error) {
      console.log('file: AddProduct.js => line 224 => imageChange5 => error', error);

    }
  };

  // *For Product Images
  const productImages = async (data1) => {
    console.log("🚀 ~ file: AddProduct.js:259 ~ productImages ~ data1", data1)
    if (selectedCoverImage) {
      try {
        let obj = [
          { QID: 14, Value: selectedCoverImage },
          { QID: 14, Value: selectedImage1 },
          { QID: 14, Value: selectedImage2 },
          { QID: 14, Value: selectedImage3 },
          { QID: 14, Value: selectedImage4 },
          { QID: 14, Value: selectedImage5 },
        ]
        let finalData = [...productData, ...obj];

        let updatedQuestionIndex = 0
        var questionDetail = {};
        for (const questionIndex in finalData) {
          const { Value, QID } = finalData[questionIndex];
          if (Value) {
            questionDetail['data[' + updatedQuestionIndex + '].QID'] = QID;
            questionDetail['data[' + updatedQuestionIndex + '].Value'] = Value;
            updatedQuestionIndex++;
          }
        }

        const formData = {
          ...questionDetail,
          BusinessTypeID: 1
        }

        setProductFormData(formData)
        handleNext()
      } catch (error) {
        console.log('file: AddProduct.js => line 134 => productImages => error', error);
      }
    }
    else {
      ErrorToaster("Product picture is required ")
    }
  };


  // *Post Product
  const postProduct = async () => {
    setLoading(true)
    try {

      const { Status, ResponseCode } = await Service.addProduct(productFormData, token)
      if (Status === true && ResponseCode === 200) {
        setLoading(false)
        handleNext()
      }


    } catch (error) {
      console.log('file: AddProduct.js => line 311 => postProduct => error', error);
    }
  };
  // *Get Category
  const getCategory = async () => {
    setMainLoading(true)
    try {
      const { Data } = await Service.getCategory()
      setCategoryList(Data)
    }
    catch (error) {
      console.log("🚀 ~ file: AddProduct.js:301 ~ getCategory ~ error", error)
    }
    finally {
      setMainLoading(false)
    }
  }

  // *Get Brands Api
  const getBrandsAP = async () => {
    const { Data } = await Service.getBrands()
    setBrandList(Data)
  }

  // *Get Manufacturer Api
  const getManufacturer = async () => {
    const { Data } = await Service.getManufacturers()
    setManufacturerList(Data)
  }

  // *Get Vehicles Api
  const getVehicles = async () => {
    const { Data } = await Service.getVehicles()
    setVehiclesList(Data)
  }

  //*Clear states for upload another product
  const clearStates = () => {
    setActiveStep(0);
    reset();
    setFinalData('');
    setLoading('');
    setVehicles('');
    setProductFormData('');
    setProductData('');
    setDiscount('');
    setDiscountPrice('');
    setProductPrice('');
    setSelectedCoverImage('');
    setSelectedImage1('');
    setSelectedImage2('');
    setSelectedImage3('');
    setSelectedImage4('');
    setSelectedImage5('');
    setSelectedSubCategory('');
    setManufacturer('');
    setBrand('');
  }

  useEffect(() => {

    getBrandsAP()
    getManufacturer()
    getCategory()
    getVehicles()

    window.scrollTo({ top: 0 })
  }, [])

  return (
    <Container sx={{ my: 3 }}>
      <Grid container columnSpacing={4}>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "100%", height: 40, display: { xs: "block", md: "none" } }}
        >
          <Toolbar />
        </Box>
        <Grid item xs={12} md={3}>
          <VendorSideNav />
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant={'h4'} sx={{ my: 2, fontWeight: "bold", color: colors.primary }}>Add Product</Typography>
          <Divider sx={{ mb: 2.5, mt: 1 }} />
          {/* =============Stepper============= */}
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={index} >
                    <StepLabel>
                      {window.screen.width > 830 && label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minHeight: "400px" }}>
                <img src={Images.success} width="10%" alt='' />
                <Typography variant={'h5'} sx={{ mt: 5, fontWeight: "bold", color: colors.primary, textAlign: 'center' }}>
                  Your product is successfully posted!
                </Typography>
                <Button onClick={() => { clearStates() }} sx={{
                  mt: 2,
                  ml: 2,
                  bgcolor: colors.lightGray,
                  color: colors.primary,
                  '&:hover': {
                    bgcolor: colors.primary,
                    color: colors.white
                  }
                }} >
                  Add Another Product
                </Button>
                <Button onClick={() => { navigate('/vendor/store') }} sx={{
                  mt: 2,
                  ml: 2,
                  bgcolor: colors.lightGray,
                  '&:hover': {
                    bgcolor: colors.primary,
                    color: colors.white
                  }
                }} >
                  Check Product in Store
                </Button>
              </Box>
            ) : (
              <Fragment>
                {/* category list */}
                {activeStep === 0 &&
                  <Fragment>
                    <Typography component='div' variant='h4' sx={{ mt: 2, textAlign: "center", color: colors.primary }} >What are you offering</Typography>
                    {mainLoading ? <Loading /> :
                      <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 10 }}>
                        {categoryList.map((item) => (
                          <Grid item key={item.CategoryID} xs={6} sm={3} md={3} lg={2}>
                            <Card
                              onClick={() => {
                                setCategory({ QID: 11, value: item.CategoryID })
                                setFinalData({ ...finalData, categoryName: item.CategoryName })
                              }}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: "100%",
                                background: "none",
                                boxShadow: 3,
                                cursor: "pointer",
                                border: `1px solid ${colors.darkGreen}`,
                                py: 0.8,
                                borderRadius: "20px",
                                mt: 2,
                                mr: 2,
                                '&:hover': {
                                  border: `1px solid ${colors.secondary}`,
                                }
                              }}>
                              <CardMedia
                                component="img"
                                sx={{ width: "100px", height: "80px", borderRadius: "10px 10px 0px 0px", objectFit: "contain" }}
                                image={item.ImageUrl}
                                alt=""
                              />
                              <CardContent sx={{
                                p: "10px !important",
                                textAlign: "center"
                              }}>
                                <Typography variant="body2" >
                                  {item.CategoryName}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    }

                  </Fragment>
                }
                {activeStep === 1 && (
                  <Fragment>
                    {cardLoading ? <Loading /> :
                      <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 10 }}>
                        {subCategoryList.length ? (
                          <Fragment>
                            {subCategoryList.map((item) => (
                              <Grid item key={item.CategoryID} xs={6} sm={3} md={3} lg={2}>
                                <Card
                                  onClick={() => setSubCategory(item.CategoryID)}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: "100%",
                                    background: "none",
                                    boxShadow: 3,
                                    cursor: "pointer",
                                    border: `1px solid ${colors.darkGreen}`,
                                    py: 0.8,
                                    borderRadius: "20px",
                                    mt: 2,
                                    mr: 2,
                                    '&:hover': {
                                      border: `1px solid ${colors.secondary}`,
                                    }
                                  }}>
                                  <CardMedia
                                    component="img"
                                    sx={{ width: "100px", height: "80px", borderRadius: "10px 10px 0px 0px", objectFit: "contain" }}
                                    image={item.ImageUrl}
                                    alt=""
                                  />
                                  <CardContent sx={{
                                    p: "10px !important",
                                    textAlign: "center"
                                  }}>
                                    <Typography variant="body2" >
                                      {item.CategoryName}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            ))}
                          </Fragment>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 6 }}>

                            <Typography variant={'h5'} sx={{ mx: 7, fontWeight: "bold", color: colors.primary, textAlign: 'center' }}>
                              There is no Sub-Category to show
                            </Typography>
                          </Box>
                        )}

                      </Grid>
                    }

                    <Button onClick={handleBack}
                      sx={{
                        mt: 2, color: colors.primary, backgroundColor: colors.lightGray,
                        '&:hover': {
                          bgcolor: colors.primary,
                          color: colors.white
                        }
                      }}
                    >
                      Back
                    </Button>
                  </Fragment>
                )
                }
                {activeStep === 2 && (
                  <Typography sx={{ mt: 2 }} component="form" onSubmit={handleSubmit(productDetail)}>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="standard" sx={{ marginY: '10px' }} >
                          <TextField
                            variant="outlined"
                            label="Product Name"
                            error={errors?.productName?.message && (true)}
                            sx={{ width: '100%' }}
                            {...register("productName", {
                              required: 'Please enter product name.',
                            })}
                          />
                          {errors?.productName?.message && (
                            <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.productName?.message}</Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant='standard' sx={{ marginY: '10px' }} >
                          <TextField
                            value={productPrice}
                            inputProps={{ min: "1" }}
                            type='number'
                            variant="outlined"
                            label="Product Price"
                            error={errors?.productPrice?.message && (true)}
                            sx={{ width: '100%' }}
                            {...register("productPrice", {
                              onChange: (e) => setProductPrice(e.target.value),
                              required: 'Please enter product price.',
                            })}
                          />
                          {errors?.productPrice?.message && (
                            <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.productPrice?.message}</Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ marginY: '10px' }}>
                          <InputLabel >Discount Percent</InputLabel>
                          <OutlinedInput
                            inputProps={{ min: "1" }}
                            startAdornment={<Percent position="start" sx={{ mr: 1 }}></Percent>}
                            label="Discount Percent"
                            error={errors?.discountVal?.message && (true)}
                            sx={{ width: '100%' }}
                            {...register("discountVal", {
                              onChange: (e) => calculateDiscount(e.target.value),
                              validate: {
                                maxDiscount: value => (value >= 0 && value <= 100) || 'Discount price must be between 0 to 100',
                              }
                            })}
                            type='number'
                            value={discount}
                          />
                          {errors?.discountVal?.message && (
                            <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.discountVal?.message}</Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="standard" sx={{ marginY: '10px' }} >
                          <TextField
                            disabled
                            variant="outlined"
                            label="Discount Amount"
                            sx={{ width: '100%' }}
                            value={discountedPrice}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ marginY: '10px' }}>
                          <InputLabel>Vehicles</InputLabel>
                          <Controller
                            render={({ field }) => (
                              <Select
                                {...field}
                                label="Vehicles"
                                value={vehicles}
                                error={errors?.vehicleName?.message && (true)}
                                onChange={handleVehicle}
                              >
                                {vehiclesList.map((val, index) => (
                                  <MenuItem key={index} onClick={() => setFinalData({ ...finalData, vehicle: val.Value })} value={val.ID}>{val.Value}</MenuItem>
                                ))}
                              </Select>
                            )}
                            name="vehicleName"
                            control={control}
                            error={errors?.vehicleName?.message && (true)}
                          // {...register("vehicleName", {
                          //   required: 'Please select vehicle.',
                          // })}
                          />
                          {errors?.vehicleName?.message && (
                            <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.vehicleName?.message}</Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ marginY: '10px' }}>
                          <InputLabel>Brand</InputLabel>
                          <Controller
                            render={({ field }) => (
                              <Select
                                {...field}
                                label="Brand"
                                value={brand}
                                error={errors?.vehicleBrand?.message && (true)}
                                onChange={handleBrand}
                              >
                                {brandList.map((val, index) => (
                                  <MenuItem key={index} onClick={() => setFinalData({ ...finalData, brand: val.Value })} value={val.ID}>{val.Value}</MenuItem>
                                ))}
                              </Select>
                            )}
                            name="vehicleBrand"
                            control={control}
                            error={errors?.vehicleBrand?.message && (true)}
                          // {...register("vehicleBrand", {
                          //   required: 'Please select brand.',
                          // })}
                          />
                          {errors?.vehicleBrand?.message && (
                            <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.vehicleBrand?.message}</Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ marginY: '10px' }}>
                          <InputLabel>Manufacturer</InputLabel>
                          <Controller
                            render={({ field }) => (
                              <Select
                                {...field}
                                onChange={handleManufacturer}
                                label="Manufacturer"
                                value={manufacturer}
                                error={errors?.manufacturer?.message && (true)}
                              >
                                {manufacturerList.map((val, index) => (
                                  <MenuItem key={index} onClick={() => setFinalData({ ...finalData, manufacturer: val.Value })} value={val.ID}>{val.Value}</MenuItem>
                                ))}
                              </Select>
                            )}
                            name="manufacturer"
                            control={control}
                            error={errors?.manufacturer?.message && (true)}
                          // {...register("manufacturer", {
                          //   required: 'Please select manufacturer.',
                          // })}

                          />
                          {errors?.manufacturer?.message && (
                            <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.manufacturer?.message}</Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="standard" sx={{ marginY: '10px' }} >
                          <TextField
                            multiline
                            rows={4}
                            variant="outlined"
                            label="Description"
                            sx={{ width: '100%' }}
                            error={errors?.description?.message && (true)}
                            {...register("description", {
                              required: 'Please enter product description.',
                            })}
                          />
                          {errors?.description?.message && (
                            <Typography color="error" sx={{ margin: '0px', fontSize: '12px', }}>{errors?.description?.message}</Typography>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Box sx={{ display: "flex" }}>
                      <Button onClick={handleBack}
                        sx={{
                          mt: 2, color: colors.primary, backgroundColor: colors.lightGray,
                          '&:hover': {
                            bgcolor: colors.primary,
                            color: colors.white
                          }
                        }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button type="submit" variant='contained' sx={{ mt: 2, ml: 2, }}>
                        Next
                      </Button>
                    </Box>
                  </Typography>
                )
                }
                {activeStep === 3 && (
                  <Fragment>
                    <Typography component='div' variant='h4' sx={{ mt: 2, textAlign: "center", color: colors.primary }} >Add Prodcut Images</Typography>
                    <Typography sx={{ mt: 2 }} component="form" onSubmit={handleSubmit(productImages)}>
                      <Grid container spacing={2} >
                        <Grid item xs={6} sm={4} md={2} sx={{ mt: 2 }}>
                          <Box sx={{ position: "relative" }}>
                            <label style={{ overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`, height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}
                              htmlFor='file-input'
                            >
                              {imgLoader ?
                                <CircularProgress /> :
                                <Box>
                                  {selectedCoverImage ? (
                                    <Box sx={{ position: "relative" }} >
                                      <img
                                        src={selectedCoverImage}
                                        alt=""
                                        width="300px"
                                        height="200px"
                                        className={classes.image}
                                        onClick={imageChange}
                                      />
                                      <Typography component='div' sx={{ position: "absolute", top: 120, left: 100, color: colors.white, bgcolor: colors.secondary, width: "100px", textAlign: "center" }}>Cover</Typography>
                                    </Box>
                                  ) : (
                                    <FaPlus
                                      size={40}
                                      style={{ color: colors.primary, cursor: "pointer" }}
                                      onClick={imageChange}
                                    />
                                  )}
                                </Box>
                              }
                            </label>

                            <input
                              style={{ display: "none" }}
                              id="file-input"
                              type="file"
                              error={errors?.selectedCoverImage?.message && (true)}
                              {...register("selectedCoverImage", {
                                required: 'Cover Image is required.',
                              })}
                              onChange={imageChange}
                              accept="image/x-png,image/jpeg"
                            />
                            {selectedCoverImage &&
                              <AiFillDelete onClick={() => setSelectedCoverImage('')} style={{ position: "absolute", top: -5, right: -5, fontSize: "20px", cursor: "pointer", backgroundColor: colors.primaryLight, color: "red", padding: "3px", borderRadius: "20px" }} />
                            }
                            {errors?.selectedCoverImage?.message && (
                              <Typography color="error" sx={{ margin: '0px', fontSize: '12px' }} >{errors?.selectedCoverImage?.message}</Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2} sx={{ mt: 2 }}>
                          <Box onDragOver={(event) => event.preventDefault()} sx={{ position: "relative" }}>
                            <label style={{ overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`, height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}
                              htmlFor='file-input1'>
                              {imgLoader1 ?
                                <CircularProgress /> :
                                <Box>
                                  {selectedImage1 ? (
                                    <img
                                      src={selectedImage1}
                                      alt=""
                                      width="300px"
                                      height="200px"
                                      className={classes.image}
                                      onClick={imageChange1}
                                      onDragStart={() => console.log(selectedImage1, "Dragging")}
                                      draggable={true}
                                    />
                                  ) : (
                                    <FaPlus
                                      size={40}
                                      style={{ color: colors.primary, cursor: "pointer" }}
                                      onClick={imageChange1}
                                    />
                                  )}
                                </Box>}
                            </label>
                            <input
                              style={{ display: "none" }}
                              id="file-input1"
                              type="file"
                              accept="image/x-png,image/jpeg"
                              onChange={imageChange1}
                            />
                            {selectedImage1 &&
                              <AiFillDelete onClick={() => setSelectedImage1('')} style={{ position: "absolute", top: -5, right: -5, fontSize: "20px", cursor: "pointer", backgroundColor: colors.primaryLight, color: "red", padding: "3px", borderRadius: "20px" }} />
                            }
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2} sx={{ mt: 2 }}>
                          <Box onDragOver={(event) => event.preventDefault()} sx={{ position: "relative" }}>
                            <label style={{ overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`, height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}
                              htmlFor='file-input2'>
                              {selectedImage2 ? (
                                <img
                                  src={selectedImage2}
                                  alt=""
                                  width="300px"
                                  height="200px"
                                  className={classes.image}
                                  onClick={imageChange2}
                                  onDragStart={() => console.log(selectedImage2, "Dragging")}
                                  draggable={true}
                                />
                              ) : (
                                <FaPlus
                                  size={40}
                                  style={{ color: colors.primary, cursor: "pointer" }}
                                  onClick={imageChange2}
                                />
                              )}
                            </label>
                            <input
                              style={{ display: "none" }}
                              id="file-input2"
                              type="file"
                              accept="image/x-png,image/jpeg"
                              onChange={imageChange2}
                            />
                            {selectedImage2 &&
                              <AiFillDelete onClick={() => setSelectedImage2('')} style={{ position: "absolute", top: -5, right: -5, fontSize: "20px", cursor: "pointer", backgroundColor: colors.primaryLight, color: "red", padding: "3px", borderRadius: "20px" }} />
                            }
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2} sx={{ mt: 2 }}>
                          <Box onDragOver={(event) => event.preventDefault()} sx={{ position: "relative" }}>
                            <label style={{ overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`, height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}
                              htmlFor='file-input3'
                            >
                              {selectedImage3 ? (
                                <img
                                  src={selectedImage3}
                                  alt=""
                                  width="300px"
                                  height="200px"
                                  className={classes.image}
                                  onClick={imageChange3}
                                  onDragStart={() => console.log(selectedImage3, "Dragging")}
                                  draggable={true}
                                />
                              ) : (
                                <FaPlus
                                  size={40}
                                  style={{ color: colors.primary, cursor: "pointer" }}
                                  onClick={imageChange3}
                                />
                              )}
                            </label>
                            <input
                              style={{ display: "none" }}
                              id="file-input3"
                              type="file"
                              accept="image/x-png,image/jpeg"
                              onChange={imageChange3}
                            />
                            {selectedImage3 &&
                              <AiFillDelete onClick={() => setSelectedImage3('')} style={{ position: "absolute", top: -5, right: -5, fontSize: "20px", cursor: "pointer", backgroundColor: colors.primaryLight, color: "red", padding: "3px", borderRadius: "20px" }} />
                            }
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2} sx={{ mt: 2 }}>
                          <Box onDragOver={(event) => event.preventDefault()} sx={{ position: "relative" }}>
                            <label style={{ overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`, height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}
                              htmlFor='file-input4'>
                              {selectedImage4 ? (
                                <img
                                  src={selectedImage4}
                                  alt=""
                                  width="300px"
                                  height="200px"
                                  className={classes.image}
                                  onClick={imageChange4}
                                  onDragStart={() => console.log(selectedImage4, "Dragging")}
                                  draggable={true}
                                />
                              ) : (
                                <FaPlus
                                  size={40}
                                  style={{ color: colors.primary, cursor: "pointer" }}
                                  onClick={imageChange4}
                                />
                              )}
                            </label>
                            <input
                              style={{ display: "none" }}
                              id="file-input4"
                              type="file"
                              accept="image/x-png,image/jpeg"
                              onChange={imageChange4}
                            />
                            {selectedImage4 &&
                              <AiFillDelete onClick={() => setSelectedImage4('')} style={{ position: "absolute", top: -5, right: -5, fontSize: "20px", cursor: "pointer", backgroundColor: colors.primaryLight, color: "red", padding: "3px", borderRadius: "20px" }} />
                            }
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2} sx={{ mt: 2 }}>
                          <Box onDragOver={(event) => event.preventDefault()} sx={{ position: "relative" }}>
                            <label style={{ overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`, height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}
                              htmlFor='file-input5'>
                              {selectedImage5 ? (
                                <img
                                  src={selectedImage5}
                                  alt=""
                                  width="300px"
                                  height="200px"
                                  className={classes.image}
                                  onClick={imageChange5}
                                  onDragStart={() => console.log(selectedImage5, "Dragging")}
                                  draggable={true}
                                />
                              ) : (
                                <FaPlus
                                  size={40}
                                  style={{ color: colors.purple, cursor: "pointer" }}
                                  onClick={imageChange5}
                                />
                              )}
                            </label>
                            <input
                              style={{ display: "none" }}
                              id="file-input5"
                              type="file"
                              accept="image/x-png,image/jpeg"
                              onChange={imageChange5}
                            />
                            {selectedImage5 &&
                              <AiFillDelete onClick={() => setSelectedImage5('')} style={{ position: "absolute", top: -5, right: -5, fontSize: "20px", cursor: "pointer", backgroundColor: colors.primaryLight, color: "red", padding: "3px", borderRadius: "20px" }} />
                            }
                          </Box>
                        </Grid>
                      </Grid>
                      <Box sx={{ display: "flex" }}>
                        <Button onClick={handleBack}
                          sx={{
                            mt: 2, color: colors.primary, backgroundColor: colors.lightGray,
                            '&:hover': {
                              bgcolor: colors.primary,
                              color: colors.white
                            }
                          }}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button type="submit" variant='contained' sx={{ mt: 2, ml: 2, }}>
                          Next
                        </Button>
                      </Box>
                    </Typography>
                  </Fragment>
                )}
                {activeStep === 4 && (
                  <Fragment>
                    <TableContainer sx={{ mt: 2 }} component={Paper}>
                      <Table sx={{ width: "100%" }}>
                        <TableBody>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: "50%", bgcolor: colors.lightGray, color: colors.primary, fontSize: 16 }} align='left'>Category Name</TableCell>
                            <TableCell align="left">{finalData.categoryName}</TableCell>
                          </TableRow>
                          {/* <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: "50%", bgcolor: colors.lightGray, color: colors.primary, fontSize: 16 }} align='left'>Sub-Category Name</TableCell>
                            <TableCell align="left">{productData.subCategory}</TableCell>
                          </TableRow> */}
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: "50%", bgcolor: colors.lightGray, color: colors.primary, fontSize: 16 }} align='left'>Product Name</TableCell>
                            <TableCell align="left">{finalData.productName}</TableCell>
                          </TableRow>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: "50%", bgcolor: colors.lightGray, color: colors.primary, fontSize: 16 }} align='left'>Product Price</TableCell>
                            <TableCell align="left">{finalData.productPrice}</TableCell>
                          </TableRow>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: "50%", bgcolor: colors.lightGray, color: colors.primary, fontSize: 16 }} align='left'>Discount Percent %</TableCell>
                            <TableCell align="left">{finalData.discountPercent === '' ? "-----" : finalData.discountPercent}</TableCell>
                          </TableRow>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: "50%", bgcolor: colors.lightGray, color: colors.primary, fontSize: 16 }} align='left'>Discount Price</TableCell>
                            <TableCell align="left">{finalData?.discountPrice === undefined ? '-----' : finalData?.discountPrice}</TableCell>
                          </TableRow>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: "50%", bgcolor: colors.lightGray, color: colors.primary, fontSize: 16, }} align='left'>Product Description</TableCell>
                            <TableCell sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", maxWidth: "50px" }} align="left">{finalData.productDescription}</TableCell>
                          </TableRow>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: "50%", bgcolor: colors.lightGray, color: colors.primary, fontSize: 16 }} align='left'>Vehicle</TableCell>
                            <TableCell align="left">{finalData.vehicle === undefined ? '-----' : finalData.vehicle}</TableCell>
                          </TableRow>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: "50%", bgcolor: colors.lightGray, color: colors.primary, fontSize: 16 }} align='left'>Brand</TableCell>
                            <TableCell align="left">{finalData.brand === undefined ? '-----' : finalData.brand}</TableCell>
                          </TableRow>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: "50%", bgcolor: colors.lightGray, color: colors.primary, fontSize: 16 }} align='left'>Manufacturer</TableCell>
                            <TableCell align="left">{finalData.manufacturer === undefined ? '-----' : finalData.manufacturer}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box sx={{ display: "flex" }}>
                      <Button onClick={handleBack}
                        sx={{
                          mt: 2, color: colors.primary, backgroundColor: colors.lightGray,
                          '&:hover': {
                            bgcolor: colors.primary,
                            color: colors.white
                          }
                        }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <LoadingButton
                        onClick={postProduct}
                        variant="contained"
                        loading={loading}
                        sx={{
                          mt: 2,
                        }}
                      >
                        Post Product
                      </LoadingButton>
                    </Box>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Box>
        </Grid>
      </Grid>

    </Container>
  )
}

export default AddProduct